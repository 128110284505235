import React, { Fragment, useState, useEffect } from 'react';
import { final } from '../finalSurvey';
import { Link } from 'react-router-dom';
import './editSurvey.css';

// const final = [
//     {
//         option: {
//             option1: "Type Option onef",
//             option2: " Type Option two",
//             option3: "type Option Three"
//         },
//         que: "Type Your Question Hereoff 1",
//         type: 1
//     },
//     {
//         option: {
//             option1: "Type Option two",
//             option2: " Type Option two2",
//             option3: "type Option Three2"
//         },
//         que: "Type Your Question Hereoff 2",
//         type: 1
//     },
//     {
//         option: {
//             option1: "Type Option 3",
//             option2: " Type Option 3",
//             option3: "type Option 3"
//         },
//         que: "Type Your Question Hereoff 3",
//         type: 1
//     }
// ]

const EditSurvey = (props) => {
    let img1 = "CreateSurvey/icons/2.png"
    const [editQue, setEditQue] = useState([])
    const [name, setName] = useState("hello")
    const [edit, setEdit] = useState(false)
    const [editIndex, setEditIndex] = useState()



    const getAll = () => {
        setEditQue(final)
    }
    useEffect(()=> {
        getAll()
    },[])


    const forDelete = (indexVal) => {

        let filterVal = [...editQue]
        if (filterVal.length > 0) {
            filterVal.splice(indexVal, 1)
            final.splice(indexVal, 1)
            console.log(filterVal, 'filterVal-1');
            setEditQue(filterVal);
        } else {
            //     console.log(filterVal,'filterVal-2');
            setEditQue([]);
        }
        console.log(editQue)
        console.log("final value is", final)
    }
    const forEdit = (indexVal) => {
        setEditIndex(indexVal)
        setEdit(!edit)
    }
    const editQuestion = (e) => {

        let op = e.target.name
        let EditQuest = [...editQue]

        EditQuest[editIndex].option[op] = e.target.value;
        final[editIndex].option[op] = e.target.value;

        setEditQue(EditQuest);
        console.log(editQue)
        console.log(final)
       

        //  setEditQue((prevState)=> ({
        //     ...prevState, [editQue[editIndex].option[op]] : valueis
        // }));

    }

    const editQuestionQue = (e) => {
        let EditQuestQue = [...editQue]
        EditQuestQue[editIndex].que = e.target.value;
        final[editIndex].que = e.target.value;
        setEditQue(EditQuestQue)
    }

    return (

        <Fragment>
            <div className="container">
            <div className="card">
                    <div className="card card-header">
                    <h4>Edit Survey Panel</h4>
                    </div>
                    <div className="card card-body">
            
                <div>
                    {editQue.length > 0 ? <> 
                    
                        {editQue.map((val, index) => {


return (
    <div key={index} style={{ margin: "5px", padding: "10px" }}>
        <div className="card">
            <div style={{backgroundColor:"gray"}} className="card card-header">

                {edit == false && <h4 className="list">{val.que}</h4>}
                {edit == true && <input onChange={editQuestionQue} style={{ width: "50%" }} className="form-control list-group-item input-sm" value={val.que} />}

            </div>

            {/* <div className="card card-body"> */}
            <ul class="list-group">
                {Object.keys(val.option).map((op, indexChild) => (
                    <div key={indexChild}>


                        {edit == false && <li class="list-group-item"><span style={{ color: "red" }}>{op}</span> {val.option[op]}</li>}

                        {edit == true && index == editIndex ? <input onChange={editQuestion} style={{ width: "50%" }} name={op} className="form-control list-group-item input-sm" value={val.option[op]} />
                            : <>
                                {edit == true && index !== editIndex && <li class="list-group-item"><span style={{ color: "red" }}>{op}</span> {val.option[op]}</li>}
                            </>
                        }

                    </div>
                ))}
            </ul>

            {/* </div>  */}
            
        </div>
        {/* <button className="btn btn-primary btn-small">Clear</button> */}
        <button className="btn btn-primary btn-small" onClick={() => forDelete(index)}>Delete</button>
        <button className="btn btn-primary btn-small" onClick={() => forEdit(index)}>{edit == true && index == editIndex ? "Submit Edit" : "Edit"}</button>
        <button className="btn btn-primary btn-small" onClick={() => setEdit(false)}>Cancel</button>




        <hr />
    </div>

)
})}
                    </> 
                        
                    
                    :<>
                    <div>
            <h2 align="center">Survey Not Found</h2>
            <h5 align="center">Please Create a Survey clicking on <Link to="/createssurvey"> create survey</Link> link</h5>    
            {/* <FinalQuestionThree/> */}
        </div>
                    </>}
                </div>
                </div>
                </div>
             
                {/* <h2>{name}</h2> */}
            </div>
        </Fragment>
    )
}
export default EditSurvey