import React from 'react';
import moment from 'moment'

export const dateFilter = (type) => {
    let date = new Date()
    let currentYear = date.getFullYear() 
    let currentMonth = null
    let currentDate = '1'
    if(type==='start_date'){
        currentMonth = date.getMonth()
    } else{
        currentMonth = date.getMonth()+1
        currentDate = date.getDate()
    }
    if(currentMonth<10){
        currentMonth = `0${currentMonth}`
    }
    if(currentDate<10){
        currentDate = `0${currentDate}`
    }


    let finalDate = `${currentYear}-${currentMonth}-${currentDate}`

    return finalDate
}


export const Yesterday = (type) => {
    let yesterdayDate = moment(moment().subtract(1, 'days').toString()).format('YYYY-MM-DD')
    let finalDate = yesterdayDate 
    return finalDate
}

export const currentDay = (type) => {
    let date = new Date()
    var currentDate = String(date.getDate()).padStart(2, '0');
    var currentMonth = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var currentYear = date.getFullYear();

    if(type==='end_date'){
        currentDate = date.getDate()
        if(currentDate<10){
        currentDate = `0${currentDate}`
    }
    }
  
    let finalDate = `${currentYear}-${currentMonth}-${currentDate}`

    return finalDate
}


export const currentMonth = (type) => {
    if(type==='end_date'){
        return moment().format('YYYY-MM-DD');
    }else{
      return  moment().startOf('month').format('YYYY-MM-DD')
    }
    // let date = new Date()
    // let currentYear = date.getFullYear() 
    // let currentMonth = null
    // let currentDate = '1'
    // currentMonth = date.getMonth()+1
    // if(type==='end_date'){
    //     currentDate = date.getDate()
    // }
    // if(currentMonth<10){
    //     currentMonth = `0${currentMonth}`
    // }
    // if(currentDate<10){
    //     currentDate = `0${currentDate}`
    // }


    // let finalDate = `${currentYear}-${currentMonth}-${currentDate}`

    // return finalDate
}

export const lastMonth = (type) => {
    let lastStartMonth = moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD')
    let LastEndMonth = moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')

    if(type==='end_date'){
       return LastEndMonth
    }else{
        return lastStartMonth
    }
    
  
}
