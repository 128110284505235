import React, { Fragment, useState, useEffect } from 'react';
import './feedbackAndSuggestionfinal.css';


const FeedbackAndSuggestionFinal = (props) => {
  const { headingByProps, feedbackAndSuggestionMsgByProps } = props;

  const [heading, setHeading] = useState("Awesome, we are happy to know that you liked our services, what you like the most in the app ?")
  const [feedbackAndSuggestionMsg, setFeedbackAndSuggestionMsg] = useState("Any Feedback Or Suggestion")

   useEffect(()=>{
    setHeading(headingByProps)
    setFeedbackAndSuggestionMsg(feedbackAndSuggestionMsgByProps) 
   },[])
   return (
        
        <Fragment>

<header className="fixed-top">
  <div className="header-wrap d-block text-center">
    <img src="CreateSurveyimages/logo.png" alt="" className="" style={{height: "50px", paddingTop: "5px"}}/>    
  </div>
  <div className="line-bottom"></div>
  </header>

  <div className="min-height-body">
    <div className="">
    <div className="position-relative card-header custom-header equal-spacing mt-3 mb-3">       
     <h6 style={{color:"black"}}  className="f-16 mb-0 d-block mx-auto"> {heading}</h6> 
    
    </div>
  

      <div className="container">
        <div className="text-center">
       
         
          <img src="CreateSurvey/images/feedback.svg" alt="" className="mx-auto mb-4 mt-4 icon-img"/>
      
    <h6 style={{color:"black"}} > {feedbackAndSuggestionMsg}</h6> 
 
        </div>
        <div className="form-group">
          <textarea className="form-control mb-2 extra-margin" placeholder="Free Text..." rows="4" data-toggle="modal" data-target="#myModal">

          </textarea>
        </div>
      </div>
      
 

</div>
  </div>
<div  className="monument ">
  <div className="d-flex pb-4 px-3" style={{justifyContent: "spaceBetween"}}>
      <a className="text-secondary extra-margin">
        Back 
        </a>
        <a className="text-secondary extra-margin"> 
        4/8 </a>
        </div>

</div>

      
        </Fragment>
    )
}
export default FeedbackAndSuggestionFinal












