import React, { Component, Fragment, useEffect, useState } from 'react';
import ReportCommon from './common/reportCommon';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { get } from 'lodash';
import ReportTable from '../../common/reportTable';
import AllPartnerWiseListTable from '../../common/allPartnerWiseListTable';
import DemoChart from '../../components/charts/demoChart';
import ProductWiseChat from '../charts/productWiseChat';
import { dateFilter, lastMonth, currentMonth, currentDay } from '../../utils/common';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import DateRangePicker from '../../common/dateRangePicker';
import MtdCommentChart from '../charts/MtdCommentChart';
import CustomCalendar from '../../common/customCalender';

// import './common/datePickerJquery'

const Report = (props) => {

  const api = new API()

  const [mtdResultData, setMtdResultData] = useState([])
  const [upiMtd, setUpiMtd] = useState([])
  const [fastagMtd, setFastagMtd] = useState([])
  const [rupayMtd, setRupayMtd] = useState([])
  const [netcMtd, setNetcMtd] = useState([])
  const [bhimMtd, setBhimMtd] = useState([])

  const [upiDailyMtd, setUpiDailyMtd] = useState([])
  const [fastagDailyMtd, setFastagDailyMtd] = useState([])
  const [rupayDailyMtd, setRupayDailyMtd] = useState([])
  const [bhimDailyMtd, setBhimDailyMtd] = useState([])

  const [loader, setLoader] = useState(true)
  const [startDate, setStartDate] = useState(currentMonth('start_date'))
  const [endDate, setEndDate] = useState(currentMonth('end_date'))

  const [startDateOther, setStartDateOther] = useState(currentMonth('start_date'))
  const [endDateOther, setEndDateOther] = useState(currentMonth('end_date'))
  const [partnerList, setPartnerList] = useState([])
  const [upiPartnerList, setUpiPartnerList] = useState('')
  const [partnerListRupay, setPartnerListRupay] = useState([])
  const [rupayPartnerList, setRupayPartnerList] = useState('')
  const [partnerListFastag, setPartnerFastag] = useState([])
  const [fastagPartnerList, setFastagPartnerList] = useState('')
  const [partnerListBhim, setPartnerListBhim] = useState([])
  const [bhimPartnerList, setBhimPartnerList] = useState('')

  useEffect(() => {
    ReportPartnerListCommonResponse()
    ReportPartnerListRupayCommonResponse()
    ReportPartnerListFastagCommonResponse()
    ReportPartnerListBhimCommonResponse()
    MtdUpiDailyResponse()
    MtdRupayDailyResponse()
    MtdFastagDailyResponse()
    MtdBhimDailyResponse()
    MtdResponse()
    MtdUpiResponse()
    MtdRupayResponse()
    MtdFastagResponse()
    MtdBhimResponse()
  }, [])


  useEffect(() => {
    MtdUpiResponse()
    MtdResponse()
    MtdRupayResponse()
    MtdFastagResponse()
    MtdBhimResponse()
    MtdUpiDailyResponse()
    MtdRupayDailyResponse()
    MtdFastagDailyResponse()
    MtdBhimDailyResponse()

  }, [startDate, endDate, startDateOther, endDateOther, upiPartnerList, rupayPartnerList, bhimPartnerList, fastagPartnerList])

  const MtdResponse = async () => {

    let mtdResult = await api.post(config.DashboardProductMtd, { start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther })
    let mtdResultData = get(mtdResult, "data", [])
    setLoader(false)
    setMtdResultData(mtdResultData)
  }

  // separate MTD For All product List //
  const MtdUpiResponse = async () => {
    let dataObj = {
      products: '[1]', start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate,
      partner_id: upiPartnerList
    }
    if (dataObj.partner_id === "") {
      delete dataObj.partner_id
    }
    let mtdUpiResult = await api.post(config.DashboardProductMtd, dataObj)
    let mtdUpiResultData = get(mtdUpiResult, "data", [])
    setLoader(false)
    setUpiMtd(mtdUpiResultData)
  }
  const MtdRupayResponse = async () => {
    let dataRupayObj = {
      products: '[2]', start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate,
      partner_id: rupayPartnerList
    }
    if (dataRupayObj.partner_id === "") {
      delete dataRupayObj.partner_id
    }
    let mtdRupayResult = await api.post(config.DashboardProductMtd, dataRupayObj)
    let mtdRupayResultData = get(mtdRupayResult, "data", [])
    console.log(mtdRupayResultData, "mtdRupayResultData");
    setLoader(false)
    setRupayMtd(mtdRupayResultData)
  }
  const MtdFastagResponse = async () => {
    let dataFastagObj = {
      products: '[3]', start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate,
      partner_id: fastagPartnerList
    }
    if (dataFastagObj.partner_id === "") {
      delete dataFastagObj.partner_id
    }
    let mtdFastagResult = await api.post(config.DashboardProductMtd, dataFastagObj)
    let mtdFastagResultData = get(mtdFastagResult, "data", [])
    console.log(mtdFastagResultData, "mtdFastagResultData");
    setLoader(false)
    setFastagMtd(mtdFastagResultData)
  }
  const MtdBhimResponse = async () => {
    let dataBhimObj = {
      products: '[4]', start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate,
      partner_id: bhimPartnerList
    }
    if (dataBhimObj.partner_id === "") {
      delete dataBhimObj.partner_id
    }
    let mtdBhimResult = await api.post(config.DashboardProductMtd, dataBhimObj)
    let mtdBhimResultData = get(mtdBhimResult, "data", [])
    console.log(mtdBhimResultData, "mtdBhimResultData");
    setLoader(false)
    setBhimMtd(mtdBhimResultData)
  }
  // separate partner List //
  const ReportPartnerListCommonResponse = async () => {

    let ReportPartnerListResult = await api.post(config.ReportPartnerListCommon, { product: "PRODUCT NAME 1", start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate })
    let mtdResultData = get(ReportPartnerListResult, "data", [])
    setLoader(false)
    setPartnerList(mtdResultData)
  }
  const ReportPartnerListRupayCommonResponse = async () => {

    let ReportPartnerListRupayResult = await api.post(config.ReportPartnerListCommon, { product: "PRODUCT NAME 2", start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate })
    let mtdResultRupayData = get(ReportPartnerListRupayResult, "data", [])
    setLoader(false)
    setPartnerListRupay(mtdResultRupayData)
  }
  const ReportPartnerListFastagCommonResponse = async () => {

    let ReportPartnerListFastagResult = await api.post(config.ReportPartnerListCommon, { product: "JOURNEY NAME 1", start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate })
    let mtdResultFastagData = get(ReportPartnerListFastagResult, "data", [])
    setLoader(false)
    setPartnerFastag(mtdResultFastagData)
  }
  const ReportPartnerListBhimCommonResponse = async () => {

    let ReportPartnerListBhimResult = await api.post(config.ReportPartnerListCommon, { product: "BHIM", start_date: startDate === "" ? currentMonth('start_date') : startDate, end_date: endDate === "" ? currentMonth('end_date') : endDate })
    let mtdResultBhimData = get(ReportPartnerListBhimResult, "data", [])
    setLoader(false)
    setPartnerListBhim(mtdResultBhimData)
  }

  // separate MTD For Daily All product List //
  const MtdUpiDailyResponse = async () => {
    let dataObj = {
      products: '[1]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: upiPartnerList
    }
    if (dataObj.partner_id === "") {
      delete dataObj.partner_id
    }
    let mtdUpiDailyResult = await api.post(config.ReportPartnerDailyMtdCommon, dataObj)
    let mtdUpiResultDailyData = get(mtdUpiDailyResult, "data", [])
    setLoader(false)
    setUpiDailyMtd(mtdUpiResultDailyData)
  }
  const MtdRupayDailyResponse = async () => {
    let dataRupayObj = {
      products: '[2]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: rupayPartnerList
    }
    if (dataRupayObj.partner_id === "") {
      delete dataRupayObj.partner_id
    }
    let mtdRupayDailyResult = await api.post(config.ReportPartnerDailyMtdCommon, dataRupayObj)
    let mtdRupayResultDailyData = get(mtdRupayDailyResult, "data", [])
    console.log(mtdRupayResultDailyData, "mtdRupayResultData");
    setLoader(false)
    setRupayDailyMtd(mtdRupayResultDailyData)
  }
  const MtdFastagDailyResponse = async () => {
    let dataFastagObj = {
      products: '[3]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: fastagPartnerList
    }
    if (dataFastagObj.partner_id === "") {
      delete dataFastagObj.partner_id
    }
    let mtdFastagDailyResult = await api.post(config.ReportPartnerDailyMtdCommon, dataFastagObj)
    let mtdFastagResultDailyData = get(mtdFastagDailyResult, "data", [])
    console.log(mtdFastagResultDailyData, "mtdFastagResultData");
    setLoader(false)
    setFastagDailyMtd(mtdFastagResultDailyData)
  }
  const MtdBhimDailyResponse = async () => {
    let dataBhimObj = {
      products: '[4]', start_date: startDateOther === "" ? currentMonth('start_date') : startDateOther, end_date: endDateOther === "" ? currentMonth('end_date') : endDateOther,
      partner_id: bhimPartnerList
    }
    if (dataBhimObj.partner_id === "") {
      delete dataBhimObj.partner_id
    }
    let mtdBhimDailyResult = await api.post(config.ReportPartnerDailyMtdCommon, dataBhimObj)
    let mtdBhimResultDailyData = get(mtdBhimDailyResult, "data", [])
    console.log(mtdBhimResultDailyData, "mtdBhimResultData");
    setLoader(false)
    setBhimDailyMtd(mtdBhimResultDailyData)
  }


  return (
    <Fragment>
      <div className="card container">


        <div className="card-body">
          {loader == true ?
            <Skeleton count={15} /> :
            <>
              <div className="mb-3">
                <DateRangePicker DateStart={setStartDate} DateEnd={setEndDate} />
              </div>

              {/* upi mtd */}
              {upiMtd.map((mtdData, mtdIndex) => (
                <div className="row">

                  <div key={mtdIndex} className="col-md-12">
                    <div className="card-header  mb-4 mt-2">
                      <h5 className="upiQuesAndRes2 mtd-img d-flex  justify-content-between mb-0">
                      <img className="productUpiBasicDetails3" style={{marginTop:'auto'}} src="img/ph-logo-2.png"/>
                       <h5  style={{marginTop:'auto'}} >{mtdData.name}</h5>
                        <div  style={{marginTop:'auto'}} >
                          <select onChange={(e) => setUpiPartnerList(e.target.value)} className="form-control" value={upiPartnerList} name="upiPartnerList">
                            <option value="">All</option>
                            {partnerList.map((partnerData, partnerIdx) => (<option key={partnerIdx} value={partnerData.partner_id} >{partnerData.partner_name}</option>))}
                          </select>
                        </div>
                      </h5>

                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <ProductWiseChat DailyCount={get(mtdData, 'dailyCount', [])} partnerName={get(mtdData, 'partner_name', 'All')} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'dateAndCount', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                          Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                      </div>


                    </div>
                  </div>
                </div>
              ))}

              {/* rupay mtd */}
              {rupayMtd.map((mtdData, mtdIndex) => (

                <div key={mtdIndex} className="col-md-12">
                  <div className="card-header  mb-4 mt-2">
                    <h5 className="upiQuesAndRes2 mtd-img d-flex align-items-center justify-content-between mb-0 ">
                    <img className="productUpiBasicDetails3" style={{marginTop:'auto'}} src="img/ph-logo-2.png"/>
                       <h5  style={{marginTop:'auto'}} >{mtdData.name}</h5>
                        <div  style={{marginTop:'auto'}} >
                        <select onChange={(e) => setRupayPartnerList(e.target.value)} className="form-control" value={rupayPartnerList} name="upipartnerList">
                          <option value="">All</option>
                          {partnerListRupay.map((partnerData, partnerIdx) => (<option key={partnerIdx} value={partnerData.partner_id} >{partnerData.partner_name}</option>))}
                        </select>
                      </div>
                    </h5>

                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <ProductWiseChat DailyCount={get(mtdData, 'dailyCount', [])} partnerName={get(mtdData, 'partner_name', 'All')} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'dateAndCount', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                        Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                    </div>


                  </div>
                </div>
              ))}

              {/* fastag mtd */}
              {fastagMtd.map((mtdData, mtdIndex) => (

                <div key={mtdIndex} className="col-md-12">
                  <div className="card-header  mb-4 mt-2">
                    <h5 className="upiQuesAndRes2  mtd-img d-flex align-items-center justify-content-between mb-0 ">
                    <img className="productUpiBasicDetails3" style={{marginTop:'auto'}} src="img/ph-logo-2.png"/>
                       <h5  style={{marginTop:'auto'}} >{mtdData.name}</h5>
                        <div  style={{marginTop:'auto'}} >
                        <select onChange={(e) => setFastagPartnerList(e.target.value)} className="form-control" value={fastagPartnerList} name="upipartnerList">
                          <option value="">All</option>
                          {partnerListFastag.map((partnerData, partnerIdx) => (<option key={partnerIdx} value={partnerData.partner_id} >{partnerData.partner_name}</option>))}
                        </select>
                      </div>
                    </h5>

                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <ProductWiseChat DailyCount={get(mtdData, 'dailyCount', [])} partnerName={get(mtdData, 'partner_name', 'All')} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'dateAndCount', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                        Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                    </div>


                  </div>
                </div>
              ))}
              {/* bhim mtd */}
              {bhimMtd.map((mtdData, mtdIndex) => (

                <div key={mtdIndex} className="col-md-12">
                  <div className="card-header   mb-4 mt-2">
                    <h5 className="upiQuesAndRes2 mtd-img d-flex align-items-center justify-content-between mb-0 ">
                    <img className="productUpiBasicDetails3" style={{marginTop:'auto'}} src="img/ph-logo-2.png"/>
                       <h5  style={{marginTop:'auto'}} >{mtdData.name}</h5>
                        <div  style={{marginTop:'auto'}} >
                        <select onChange={(e) => setBhimPartnerList(e.target.value)} className="form-control" value={bhimPartnerList} name="bhimPartnerList">
                          <option value="">All</option>
                          {partnerListBhim.map((partnerData, partnerIdx) => (<option key={partnerIdx} value={partnerData.partner_id} >{partnerData.partner_name}</option>))}
                        </select>
                      </div>
                    </h5>

                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <ProductWiseChat DailyCount={get(mtdData, 'dailyCount', [])} partnerName={get(mtdData, 'partner_name', 'All')} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'dateAndCount', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                        Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                    </div>


                  </div>
                </div>
              ))}

            </>
          }
        </div>
      </div>
      <div className="card container">
        <div className="card-body">
          {loader == true ?
            <Skeleton count={15} /> :
            <>
              {/* another one */}
              <div className="row">
                <div className="container">
                  <DateRangePicker DateStart={setStartDateOther} DateEnd={setEndDateOther} />
                </div>

              </div>
              {upiDailyMtd.map((mtdData, mtdIndex) => (
                <div className="row">

                  <div key={mtdIndex} className="col-md-12">
                    <div className="card-header  mb-4 mt-2">
                      <h5 className="upiQuesAndRes2 d-flex align-items-center justify-content-between mb-0">
                        <h5 className="d-block mx-auto">{mtdData.name}</h5>

                      </h5>

                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <MtdCommentChart DailyCount={get(mtdData, 'dailyCount', [])} Logo={'img/upi.png'} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'monthDate', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                          Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                      </div>


                    </div>
                  </div>
                </div>
              ))}
              {rupayDailyMtd.map((mtdData, mtdIndex) => (
                <div className="row">

                  <div key={mtdIndex} className="col-md-12">
                    <div className="card-header  mb-4 mt-2">
                      <h5 className="upiQuesAndRes2 d-flex align-items-center justify-content-between mb-0">

                        <h5 className="d-block mx-auto">{mtdData.name}</h5>
                      </h5>

                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <MtdCommentChart DailyCount={get(mtdData, 'dailyCount', [])} Logo={"img/rupay.png"} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'monthDate', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                          Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                      </div>


                    </div>
                  </div>
                </div>
              ))}
              {fastagDailyMtd.map((mtdData, mtdIndex) => (
                <div className="row">

                  <div key={mtdIndex} className="col-md-12">
                    <div className="card-header  mb-4 mt-2">
                      <h5 className="upiQuesAndRes2 d-flex align-items-center justify-content-between mb-0">
                        <h5 className="d-block mx-auto">{mtdData.name}</h5>
                      </h5>

                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <MtdCommentChart DailyCount={get(mtdData, 'dailyCount', [])} Logo={"img/fastag.png"} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'monthDate', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                          Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                      </div>


                    </div>
                  </div>
                </div>
              ))}

              {bhimDailyMtd.map((mtdData, mtdIndex) => (
                <div className="row">

                  <div key={mtdIndex} className="col-md-12">
                    <div className="card-header  mb-4 mt-2">
                      <h5 className="upiQuesAndRes2 d-flex align-items-center justify-content-between mb-0">
                        <h5 className="d-block mx-auto">{mtdData.name}</h5>
                      </h5>

                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <MtdCommentChart DailyCount={get(mtdData, 'dailyCount', [])} Logo={"img/bhim.png"} DateAndCount={get(mtdData, 'dateAndCount', [])} MonthDate={get(mtdData, 'monthDate', [])} MtdNpsScore={get(mtdData, 'mtdNpsScore', [])}
                          Name={get(mtdData, 'name', '')} Count={get(mtdData, 'count', 0)} NpsScore={get(mtdData, 'npsScore', [])} />

                      </div>


                    </div>
                  </div>
                </div>
              ))}



            </>
          }



        </div>
      </div>
      {/* <CustomCalendar /> */}
      <AllPartnerWiseListTable />
      <div className="App site-wrap">
        <ReportTable />
      </div>

      <div className="mb-4 mt-4"></div>
    </Fragment>

  )
}

export default Report 
