import React, { Fragment, useState } from 'react';
import './q3final.css';

const FinalQuestionThree = (props) => {
    const { question, option } = props;
    return (
        
        <Fragment>
            <header className="fixed-top">
                <div className="header-wrap d-block text-center">
                    <img src="CreateSurvey/images/logo.png" alt="" className="" style={{height: "50px", paddingTop: "5px"}} />
                </div>
                <div className="line-bottom"></div>
            </header>



 
  <div className="min-height-body">
                <div className="">
                    <div className="position-relative card-header custom-header equal-spacing extra-margin">
                      <h6 style={{color:"black"}} className="f-16 mb-0 d-block mx-auto" > {question} ?</h6> 
                       </div>



                    <div className="container">
                        <ul className="row px-2 mb-0 text-center">
                            <li className="col-lg-6 col-6 px-2 py-3">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="1" name="A"/>
                                         <div>
                                         
                                             <img src="CreateSurvey/images/UPI_1.svg" alt="" className="mx-auto  icon-img" />
                                           
                                         <hr className="mt-1 mb-1" />
                          <h6  className="f-14" style={{color:"black"}}>{option.option1}</h6>
              </div>
                                 
                                          </label>
                            </li>
                            <li className="col-lg-6 col-6 px-2 py-3">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="2" name="A"/> 
                                    <div>
                                  
                                        <img src="CreateSurvey/images/UPI_2.svg" alt="" className="mx-auto  icon-img" />
                                 
                                        <hr className="mt-1 mb-1" /> 
     <h6 className="f-14" style={{color:"black"}}>{option.option2}</h6> 
                </div>
                                        {/* </input> */}
                                        </label>
                            </li>
                            <li className="col-lg-6 col-6 px-2 ">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="3" name="A"/> 
                                    <div>
                                    <label for="op1" className="labelClass">
                                    <input id="op1" type="file" hidden/>
                                        <img src="CreateSurvey/images/UPI_3.svg" alt="" className="mx-auto  icon-img" /> 
                                    </label>
                                        <hr className="mt-1 mb-1" />
                            <h6  className="f-14" style={{color:"black"}}>{option.option3}</h6> 
                             </div>
                                       
                                        </label>
                            </li>
                            <li className="col-lg-6 col-6 px-2 ">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="4" name="A"/> 
                                    <div>
                                 
                                        <img src="CreateSurvey/images/UPI_5.svg" alt="" className="mx-auto  icon-img" />
                                  
                                        <hr className="mt-1 mb-1" />
                          <h6  className="f-14" style={{color:"black"}}>{option.option4}</h6> 
                      
                                        </div>
                                        {/* </input> */}
                                        </label>
                            </li>
                            <div className="col-lg-12 col-12 px-2 py-3 text-left">
                                <textarea className="form-control mb-2 extra-margin" placeholder="Free Text..." rows="1" data-toggle="modal" data-target="#myModal">

                                </textarea>


                            </div>
                        </ul>
                    </div>



                </div>
  </div>
<div  className="monument d-xl-none">
  <div className="d-flex mb-2 px-3" style={{justifyContent: "space-between"}}>
      <a className="text-secondary extra-margin">
        Back </a>
       <a className="text-secondary extra-margin"> 
       Next </a> 
       </div>

</div>
<div className="modal fade" id="myModal">
  <div className="modal-dialog">
    <div className="modal-content">
    
      <div className="modal-body">
          <textarea className="form-control mb-2" placeholder="Free Text..." rows="5"></textarea>
          <h6 className="text-danger">Minimum 10 words required*</h6>

      </div>
      
    </div>
  </div>

</div>

      
        </Fragment>
    )
}
export default FinalQuestionThree