import React, { useState, Fragment, useEffect } from 'react';
import SubNavBar from './subNavBar';
import { Link, useRouteMatch } from 'react-router-dom';
import { get } from 'lodash'
import Dashboard from '../components/dashboard/dashboard';
import Breadcrumbs from './breadcrumbs';
import { clearLocalData } from '../utils/CoustomStorage';
import { withCookies } from 'react-cookie';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import { toast } from 'react-toastify';

const DefaultHeader = ({cookies}) => {
    
  const api = new API()
    const pathname = window.location.hash

    const logout = () => {
        clearLocalData()
         cookies.remove("token")
      }
      

      const refreshButton =async()=>{
        
          let data = {
            id_token: "utnnRRTjYT$522@@gh5S&&dsD#d"
          }
        let result = await api.post(config.RefreshButton, data)
        
        if (result.code === 200) {
        //   await cookies.set('token', result.data.token)
        await window.location.reload();
          toast.info(result.message)
        } else {
          toast.error(result.message)
        }
      }
    return (
        <Fragment>
            <div id="sidebar-wrapper">
                <div className="sidebar-heading white-font">LOGO</div>
                <div className="list-group list-group-flush">
                    <Link to="/dashboard" className={(pathname == "#/dashboard") || (pathname == "#/") ? "list-group-item" : "list-group-item"}><img src="img/002-home.png" style={{ width: '20px' }} /></Link>
                    {/* <Link to="/reportAnalysis" className={pathname == "#/reportAnalysis" ? "list-group-item active" : "list-group-item"}><img src="img/npci-logo/report.png" style={{ width: '30px' }} /></Link> */}
                    <Link to="/userManagement" className={pathname == "#/userManagement" ? "list-group-item " : "list-group-item"}><img src="img/003-customer.png" style={{ width: '20px' }} /></Link>
                    {/* <Link to="/sentimentAnalysis" className={pathname == "#/sentimentAnalysis" ? "list-group-item active" : "list-group-item"}><img src="img/npci-logo/eye.png" style={{ width: '30px' }} /></Link> */}
                   <Link to="/report" className={pathname == "#/report" ? "list-group-item " : "list-group-item"}><img src="img/004-bar-chart.png" style={{ width: '20px' }} /></Link>
                   <Link to="/dump-data" className={pathname == "#/dump-data" ? "list-group-item " : "list-group-item"}><img src="img/005-report.png" style={{ width: '20px' }} /></Link>
                    {/* <Link to="/newtask3" className={pathname == "#/newtask3" ? "list-group-item active" : "list-group-item"}><img src="img/npci-logo/block.png" style={{ width: '30px' }} /></Link> */}
                    {/* <Link to="#" className="list-group-item "><i className="fas fa-eye" /></Link> */}

                </div>
            </div>
            <div id="page-content-wrapper">
                <nav className="mb-1 navbar navbar-expand-lg head justify-content-between">

                    <div className="col-md-6 col-6 text-left">
                        <div className="row align-items-center">
                            <div className="col-md-3">
                                <Link to="/dashboard"  > <img className="left" src="img/qdegree.png" /> </ Link>
                            </div>
                            {/* <Breadcrumbs breadcrumb={props.breadcrumb}/> */}
                        </div>
                    </div>
                    <div className="col-md-6 col-6 d-md-block d-lg-block d-xl-block d-none text-right custom-nav">
                        <ul className="navbar-nav ">
                        <li onClick={refreshButton} className="nav-item dropdown">
                            {/* <div className="col-md-2 refresh mr-4"> */}
                        <a  className="nav-link mt-1"><img src="img/button.png"  /></a>
                      {/* </div> */}
                            </li>
                            <li className="nav-item dropdown">
                            {/* <div className="col-md-2 refresh mr-4"> */}
                        <a  className="nav-link mt-1"><img src="img/009-pdf.png"  /></a>
                      {/* </div> */}
                            </li>
                            <li className="nav-item dropdown">
                                <a className="nav-link" data-toggle="dropdown" href="#" style={{ paddingBottom: '0px' }}>
                                    
                                    <img  src="img/007-bell.png" />
                                </a>
                                <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right" style={{ left: 'inherit', marginTop: '19px', right: '0px' }}>
                                    <ul className="navbar-nav dropdown-item dropdown-header">
                                        <li><img src="img/fire.png" /><span>1,50,000 Survey Triggered</span></li>
                                        <li><img src="img/block.png" /><span>20,000 Undelivered Survey</span></li>
                                        <li><img src="img/delivery-truck.png" /><span>1,33,000 Survey delivered </span></li>
                                        <li><img src="img/001-email.png" /><span>1,500 Responses Received</span></li>
                                        <li><img src="img/001-volume.png" /><span>1% Response Percentage</span></li>
                                    </ul>
                                    <div className="dropdown-divider" />
                                    <a href="#" className="dropdown-item dropdown-footer"><strong>Today :-</strong> </a>
                                    <a href="#" className="dropdown-item">
                                        Dashboard Login
                                     <span className="float-right text-muted text-sm">| 01 : 14 | </span>
                                    </a>
                                    <a href="#" className="dropdown-item">
                                        Target Achieved for Product UPI 15100 / 15000 for this month.
                                    <span className="float-right text-muted text-sm">| 01 : 30 | </span>
                                    </a>
                                    <div className="dropdown-divider" />
                                    <a href="#" className="dropdown-item dropdown-footer"><strong>Yesterday :-</strong> </a>
                                    <a href="#" className="dropdown-item">
                                        Dashboard Login
                                      <span className="float-right text-muted text-sm">| 01 : 14 | </span>
                                    </a>
                                    <a href="#" className="dropdown-item">
                                        Target Achieved for Product UPI 15100 / 15000 for this month.
                                 <span className="float-right text-muted text-sm">| 01 : 30 | </span>
                                    </a>
                                    <div className="dropdown-divider" />
                                    <a href="#" className="dropdown-item dropdown-footer"><strong>Last Week  :-</strong> </a>
                                    <a href="#" className="dropdown-item">
                                        Dashboard Login
                             <span className="float-right text-muted text-sm">| 01 : 14 | </span>
                                    </a>
                                    <a href="#" className="dropdown-item">
                                        Target Achieved for Product UPI 15100 / 15000 for this month.
                            <span className="float-right text-muted text-sm">| 01 : 30 | </span>
                                    </a>
                                    <div className="dropdown-divider" />
                                </div>
                            </li>
                            <li className="nav-item dropdown mr-0 mt-1">
                                <a className="nav-link btn">
                                <img onClick={logout} src="img/006-lock.png" />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className="col-6 d-block d-md-none d-lg-none d-xl-none text-right"
                    // onClick="openMobileTopbar()"
                    >
                        <i className="fas fa-bars" />
                    </div>
                </nav>

                <div style={{ height: "60px" }}></div>


            </div>
        </Fragment>
    );
}

export default withCookies(DefaultHeader);