import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './commentModel.css';
import { get } from 'lodash'
const CommentModel = (props) => {
  const {
    handleModel,
    open,
    buttonLabel,
    commentDetailResultData,
  } = props;


  const [showDetailIndex, setShowDetailIndex] = useState(0)


  const toggle = (index) => {
    setShowDetailIndex(index)
  }


  return (
    <div>
      <Button color="danger" onClick={handleModel}>{buttonLabel}</Button>
      <Modal isOpen={open} toggle={handleModel} className="modal-xl">
        <div  className="modal-header card-header">
          <h5 className="modal-title" ><b>Customer Inputs - Detailed Information </b></h5>
          <button type="button" onClick={handleModel}  className="close" data-dismiss="modal"><b>X</b></button>
        </div>
        <ModalBody>
          {/* {JSON.stringify(commentDetailResultData)} */}
          <div className="modal-body">
            <div className="row">
              <div className="col-md-4">
                <div className="card card-height">

                  {commentDetailResultData && commentDetailResultData.map((item, index) => (
                    <div key={index} onClick={() => toggle(index)}  >
                      <ol className="todo-list">
                        <li className="list-view list-body card-body selectedSection">

                          <b>Keyword : </b> {item.keyword}
                          <p className="mb-2"><b>Rating : </b> <strong><span className="green">{item.rating}</span>/10</strong></p>
                          <p className="badge badge-primary mb-2">{item.username} </p>
                          <p className="mb-2"><b>Response Date : </b> {item.response_date}</p>
                          <p className="mb-2"><b>Touchpoint : </b></p>

                          <div className="alert alert-light mb-0">
                            <p>{item.touchpoint}</p>
                          </div>

                        </li>
                      </ol>
                    </div>
                  ))}
                </div>
              </div>
              <div className="col-md-8">
                <div className="card card-height">

                  <div className="card-body">
                    <h5 className="mb-0"> <b>Rating Given</b>  <span className="text-success">{commentDetailResultData !== undefined && get(commentDetailResultData[showDetailIndex], 'rating', '')}/10</span></h5>
                    <span className="badge badge-primary"><b>{commentDetailResultData !== undefined && get(commentDetailResultData[showDetailIndex], 'username', '')} </b></span>
                    <p><small className="mb-0"><b>Response Date : </b> {commentDetailResultData !== undefined && get(commentDetailResultData[showDetailIndex], 'response_date', '')}</small></p>
                    {/*<hr/>*/}
                    <div className="custom-modal">
                      <ul className="nav nav-tabs" role="tablist">
                        <li className="nav-item">
                          <a className="nav-link active" data-toggle="tab" href="#response">Response</a>
                        </li>
                        {/* <li className="nav-item">
                <a className="nav-link" data-toggle="tab" href="#Winback">Webpack</a>
              </li> */}
                      </ul>
                      {/* Tab panes */}
                      <div className="tab-content">
                        <div id="response" className=" tab-pane active"><br />
                          <div className="table-responsive">
                            <table className="table modal-table table-striped table-bordered">
                              <tbody>
                                <tr>
                                  <th style={{ backgroundColor: "#009dff" }} className="text-center text-white">TouchPoint</th>
                                  <td>{commentDetailResultData !== undefined && get(commentDetailResultData[showDetailIndex], 'touchpoint', '')}</td>
                                </tr>
                                {/* <tr>
                                  <th style={{ backgroundColor: "#009dff" }} className="text-center text-white">Sub-Product</th>
                                  <td><strong>{commentDetailResultData !== undefined && get(commentDetailResultData[showDetailIndex], 'response_date', '')}</strong></td>
                                </tr> */}
                                <tr>
                                  <th style={{ backgroundColor: "#009dff" }} className="text-center text-white">Category</th>
                                  <td >{commentDetailResultData !== undefined && get(commentDetailResultData[showDetailIndex], 'cat', '')}</td>
                                </tr>
                                <tr>
                                  <th style={{ backgroundColor: "#009dff" }} className="text-center text-white">Sub-Category</th>
                                   <td >{commentDetailResultData !== undefined && get(commentDetailResultData[showDetailIndex], 'sub_category', '')}</td>
                                </tr>
                                <tr>
                                  <th style={{ backgroundColor: "#009dff" }} className="text-center text-white">Sentiment</th>
                                  <td >{commentDetailResultData !== undefined && get(commentDetailResultData[showDetailIndex], 'sentiment', '')}</td>                             
                                </tr>

                                {/* <tr>
                        <th style={{backgroundColor:"#009dff"}} className="text-center text-white">Sub-Category</th>
                        <td />
                      </tr> */}
                                <tr>
                                  <th style={{ backgroundColor: "#009dff" }} className="text-center  text-white">Suggestion</th>
                                  <td>{commentDetailResultData !== undefined && get(commentDetailResultData[showDetailIndex], 'suggestion', '')}</td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>

                        {/* <div id="Winback" className=" tab-pane fade"><br />
                <table className="table table-striped">
                  <tbody>
                    <tr>
                      <th>Service Recovery Status</th>
                      <td>WIP</td>
                    </tr>
                    <tr>
                      <th>RCA</th>
                      <td />
                    </tr>
                    <tr>
                      <th>level 1</th>
                      <td />
                    </tr>
                  </tbody>
                </table>
              </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>

            </div>

          </div>

        </ModalBody>
        <ModalFooter>
          {/* <button type="button" className="btn btn-danger" onClick={handleModel} data-dismiss="modal">Close</button> */}
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default CommentModel;