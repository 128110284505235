import React, { useEffect, useState } from 'react';
import OverallNpsScore from './common/OverallNpsScore';
import ProductUpiBasicDetail from './common/productUpiBasicDetail';
// import UpiQuestionAndResponse from './common/UpiQuestionAndResponse';
// import RupayQuestionAndResponse from './common/RupayQuestionAndResponse';
// import FastTageQuestionAndResponse from './common/FastTagQuestionAndResponse';
import RecentFeedbacksAndSuggestions from './common/RecentFeedbacksAndSuggestions';
import CategoriesDepenciesAndWordCloud from './common/CategoriesDependenciesAndWordCloud';
import ApplicationUsageComparision from './common/ApplicationUsageComparision';
import StateWiseImpactOnNps from './common/StateWiseImpactOnNps';
import SubNavBar from '../../common/subNavBar';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { get } from 'lodash'
import { dateFilter, lastMonth, currentMonth, currentDay } from '../../utils/common';
import { getLocalDataAsObject } from '../../utils/CoustomStorage';
import moment from 'moment'


// console.log(moment(moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD')).endOf("month").format('DD'),'moment().endOf("month")');

const Dashboard = (props) => {

  const api = new API()

  // nps score //
  const [npci, setNpci] = useState([])
  // const [filterObj, setFilterObj] = useState({})
  const [filterObj, setFilterObj] = useState(getLocalDataAsObject('filter') || {})
  const [filterApply, setFilterApply] = useState(false)
  const [upi, setUpi] = useState([])
  const [fastag, setFastag] = useState([])
  const [rupay, setRupay] = useState([])
  const [bhim, setBhim] = useState([])
  const [netc, setNetc] = useState([])
  const [loader, setLoader] = useState(true);
  const [skeletonLoader1, setSkeletonLoader1] = useState(true)
  
  const [upiShow,setUpiShow] = useState(false)
  const [rupayShow,setRupayShow] = useState(false)
  const [fastagShow,setFastagShow] = useState(false)
  const [bhimShow,setBhimShow] = useState(false)
  const [netcShow,setNetcShow] = useState(false)

  // basic Details //
  const [upiBasic, setUpiBasic] = useState({})
  const [fastagBasic, setFastagBasic] = useState({})
  const [rupayBasic, setRupayBasic] = useState({})
  const [bhimBasic, setBhimBasic] = useState({})
  const [netcBasic, setNetcBasic] = useState({})

  // Application usages //
  const [applicationUsageResultData, setApplicationUsageResultData] = useState({})

console.log(filterObj,'filter');

useEffect(() => {

  let start_date = currentDay('end_date')
  let end_date = currentDay('end_date')
  npsScore({ start_date, end_date })
},[])

  useEffect(() => {
    let start_date = currentDay('end_date')
    let end_date = currentDay('end_date')
    basicDetailUpi({ product: 1, start_date, end_date })
    basicDetailRupay({ product: 2, start_date, end_date })
    basicDetailFastag({ product: 3, start_date, end_date })
    basicDetailBhim({ product: 4, start_date, end_date })
    basicDetailNetc({ product: 5, start_date, end_date })
   
   
    applicationUsage()
  }, [])

  
  // nps score //

  const npsScore = async (dateFilter) => {
    setSkeletonLoader1(true)
    let npsScoreResult = await api.get(config.dashboardScore, true, dateFilter)
    let npci =  npsScoreResult && npsScoreResult.data && npsScoreResult.data.npci
    let upi =  npsScoreResult && npsScoreResult.data && npsScoreResult.data.product_name_1
    let fastag =  npsScoreResult && npsScoreResult.data && npsScoreResult.data.journey_name_1
    let rupay =  npsScoreResult && npsScoreResult.data && npsScoreResult.data.product_name_2
    let bhim =  npsScoreResult && npsScoreResult.data && npsScoreResult.data.journey_name_2
    setNpci(npci)
    setUpi(upi)
    setFastag(fastag)
    setRupay(rupay)
    setBhim(bhim)
    setSkeletonLoader1(false)
  }

const appliedFilterCallingApi=(filterObject)=>{
  setFilterApply(prev =>!prev)
  let start_date = filterObject.start_date
  let end_date = filterObject.end_date
  start_date = start_date===undefined ?   currentDay('end_date') : start_date
  end_date = end_date===undefined ? currentDay('end_date') : end_date
  npsScore({ start_date, end_date })
  basicDetailUpi({ product: 1, start_date, end_date })
  basicDetailRupay({ product: 2, start_date, end_date })
  basicDetailFastag({ product: 3, start_date, end_date })
  basicDetailBhim({ product: 4, start_date, end_date })
  basicDetailNetc({ product: 5, start_date, end_date })

}


  // basic Details //
  const basicDetailUpi = async (dateFilter) => {
    let basicDetailUpiResult = await api.get(config.dashboardBasicDetails, true, dateFilter);
    let upiBasic = basicDetailUpiResult && basicDetailUpiResult.data && basicDetailUpiResult.data.product_name_1
    setUpiBasic(upiBasic)
  }

  const basicDetailRupay = async (dateFilter) => {
    let basicDetailRupayResult = await api.get(config.dashboardBasicDetails, true, dateFilter);
    let rupayBasic = basicDetailRupayResult && basicDetailRupayResult.data && basicDetailRupayResult.data.product_name_2
    setRupayBasic(rupayBasic)
  }
  const basicDetailFastag = async (dateFilter) => {
    let basicDetailFastagResult = await api.get(config.dashboardBasicDetails, true, dateFilter);
    let fastagBasic = basicDetailFastagResult && basicDetailFastagResult.data && basicDetailFastagResult.data.journey_name_1
    setFastagBasic(fastagBasic)
  }
  const basicDetailBhim = async (dateFilter) => {
    let basicDetailBhimResult = await api.get(config.dashboardBasicDetails, true, dateFilter);
    let bhimBasic = basicDetailBhimResult && basicDetailBhimResult.data && basicDetailBhimResult.data.journey_name_2
    setBhimBasic(bhimBasic)
  }
  const basicDetailNetc = async (dateFilter) => {
    let basicDetailNetcResult = await api.get(config.dashboardBasicDetails, true, dateFilter);
    let NetcBasic = basicDetailNetcResult && basicDetailNetcResult.data && basicDetailNetcResult.data.netc_webform
    setNetcBasic(NetcBasic)
  }
  // Application usages //
  const applicationUsage = async () => {
    let applicationUsageResult = await api.get(config.dashboardGetDeviceAndTransaction);
    let applicationUsageResultData = applicationUsageResult.data
    setApplicationUsageResultData(applicationUsageResultData)
  }



  return (
    <div className="container-fluid" style={{ marginLeft: "2%" }}>
      
      <SubNavBar  upiShow={upiShow} setUpiShow={setUpiShow} setRupayShow={setRupayShow} setFastagShow={setFastagShow} rupayShow={rupayShow} 
      fastagShow={fastagShow} filterApply={filterApply} setFilterApply={setFilterApply}  appliedFilterCallingApi={appliedFilterCallingApi} setFilterObj={setFilterObj} filterObj={filterObj}/>

      <OverallNpsScore loader={loader} npsScore={npsScore} Bhim={bhim} skeletonLoader1={skeletonLoader1} setSkeletonLoader1={setSkeletonLoader1}  Npci={npci} Upi={upi} Rupay={rupay} Fastag={fastag} />


      <ProductUpiBasicDetail upiShow={upiShow} setUpiShow={setUpiShow} setRupayShow={setRupayShow} setFastagShow={setFastagShow}
       rupayShow={rupayShow} fastagShow={fastagShow} bhimShow={bhimShow} setBhimShow={setBhimShow} netcShow={netcShow} setNetcShow={setNetcShow} 
       filterObj={filterObj} filterApply={filterApply} basicDetailUpi={basicDetailUpi} basicDetailRupay={basicDetailRupay} basicDetailFastag={basicDetailFastag} 
       basicDetailBhim={basicDetailBhim} basicDetailNetc={basicDetailNetc}  upiBasic={upiBasic} rupayBasic={rupayBasic} fastagBasic={fastagBasic} 
        bhimBasic={bhimBasic}  />
     
      <RecentFeedbacksAndSuggestions />
      <CategoriesDepenciesAndWordCloud />
      <ApplicationUsageComparision applicationUsage={applicationUsageResultData} />
      <StateWiseImpactOnNps />
    </div>
  );
}

export default Dashboard;