import React , { Component, Fragment } from 'react';
import ReportAnalysisCommon from './common/reportAnalysisCommon';


const ReportAnalysis =()=> {
 return (
     <div className="App site-wrap">

{/* <h1> report analysis panel</h1> */}
<ReportAnalysisCommon/>

     </div>
 )   
}

export default ReportAnalysis;