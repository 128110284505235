
import React , { Component, Fragment } from 'react'
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import ModalDiv from '../../../common/model'

class userManagementCommon extends React.Component {
    userForDelete = []
    userForEdit = []
    currDate
    constructor() {
        super()
        this.getCurrDate()
 
        var NewUserItem
   
    this.state = {
        message : "hello",

        userId: '',
        userName: '',
        userEmailId : '',
        roleType: '',
        productHandling: '',
        zone: '',
        partner: '',
        date: this.currDate,
        status: '',
        showHide: false,
        edited: false,
        createUser : false,

        userManagement : [
            {"userId" : '1', "userName" : "Amit Kumar", "userEmailId" : "amit@qdegrees.com", "roleType": "Admin Access",  "productHandling" : "Product Name 1", "zone" : "North", "partner" : "Partner For Product Name 1", "date" : "02:01:2021", "status": "active"},
            {"userId" : '2', "userName" : "Sushant Banga", "userEmailId" : "sb@qdegrees.com", "roleType": "User Access",  "productHandling" : "Product Name 2", "zone": "All Zone", "partner" : "Partner For Product Name 2", "date" : "22:01:2021", "status": "inactive" },
            {"userId" : '3', "userName" : "Rahul Kumhar", "userEmailId" : "rahul@qdegrees.com", "roleType": "Admin Access",  "productHandling" : "Journey Name 1", "zone" : "North", "partner" : "Partner For Journey Name 1", "date" : "24:01:2021", "status": "active"},
            {"userId" : '4', "userName" : "Vishnu Jangir", "userEmailId" : "vishnu@qdegrees.com", "roleType": "User Access",  "productHandling" : "Journey Name 2", "zone": "All Zone", "partner" : "Partner For Journey Name 2", "date" : "04:02:2021", "status": "inactive" }
        ]
       
    }
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.editUserManagement = this.editUserManagement.bind(this);
    this.deleteUser = this.deleteUser.bind(this)
    this.editUser = this.editUser.bind(this)
}


    handleChange(event){
        // alert("handlechange work"+ event.target.value)
       
        var val = event.target
        this.setState({
            [val.name] :  val.value
            
        });
        // alert(event.target.name)
        
    }
    AddingItem(){
        // alert("this.addingitem")
        let NewUser = {}
        NewUser.userName = this.state.userName
        NewUser.userEmailId = this.state.userEmailId
        NewUser.roleType = this.state.roleType
        NewUser.productHandling = this.state.productHandling
        NewUser.zone = this.state.zone
        NewUser.partner = this.state.partner
        NewUser.date = this.state.date
return NewUser
    //     // console.log("adduser>>>>",NewUser)
    //     // console.log("adduser>&&&&&&&&&&&&&&>>",this.state)

    }
    handleSubmit(event){
        
    //    alert("handleSubmit work")
        // alert("submited : " +JSON.stringify(this.state));
        // confirmAlert({
        //     header: "hello",
        //     title: <h5> You are requesting to add a new user into the system. Password creation link will be send to the requested user Email id {this.state.userEmailId} </h5>,
        //     // message: "hllo",
           
        //     buttons: [
        //         {
        //             label: 'Cancel',
        //         },
        //         {
        //             label: "Okay, create user",
        //             onClick: () => {
        //                 // debugger
        //                 this.state.userManagement.push(this.AddingItem());
        //                 this.setState(previousState => ({
        //                 userManagement: [...previousState.userManagement],
            
        //                 userId : '',
        //                 userName :  '',
        //                 userEmailId : '',
        //                 roleType :'',
        //                 productHandling : '',
        //                 zone : '',
        //                 partner : '',
        //                 date : '',
        //                 showHide:false,
                 
        // }));
       
        // console.log(">>>>>>>>>>>>>>>>",this.state.userManagement, ">>>>>>>>>")
        // event.preventDefault();
        //             }
        //         }
        //     ]
        // })

            this.state.userManagement.push(this.AddingItem());
            this.setState(previousState => ({
            userManagement: [...previousState.userManagement],

            userId : '',
            userName :  '',
            userEmailId : '',
            roleType :'',
            productHandling : '',
            zone : '',
            partner : '',
            date : '',
            showHide:false,
            // createUser:!this.state.createUser
            createUser: false
    
    

}));

// this.state.userManagement
console.log(">>>>>>>>>>>>>>>>",this.state.userManagement, ">>>>>>>>>")
// event.preventDefault();
        
    }

    editUserManagement(user){
        // alert("user is" + JSON.stringify(user))
        this.userForDelete = user
        this.userForEdit = user
        this.setState({
            userId : user.userId,
            userName :  user.userName,
            userEmailId : user.userEmailId,
            roleType : user.roleType,
            productHandling : user.productHandling,
            zone : user.zone,
            partner : user.partner,
            date : user.date,
            showHide:!false,
            edited: true
            
            
        });
    }
    deleteUser(userDelete){
        var deleteUserFromUser = [...this.state.userManagement];
        var index = deleteUserFromUser.indexOf(this.userForDelete)
        // confirmAlert({
        //     title: <h5>Are you sure, you want to delete this user entry?</h5>,
        //     message: 'User Name : ' +this.state.userName,
        //     buttons: [
        //         {
        //             label: 'Cancel',
        //             // onClick: ()=> alert ('click No')
        //         },
        //         {
        //             label: 'Okay, Proceed',

        //             onClick: () => {
                        if(index !== -1){
                            deleteUserFromUser.splice(index, 1);
                            this.setState({
                                userManagement: deleteUserFromUser,
                                userId : '',
                                userName :  '',
                                userEmailId : '',
                                roleType : '',
                                productHandling : '',
                                zone : '',
                                partner : '',
                                date : this.currDate,
                                showHide : false
                                
                            });
                        }
                    // }

                    // onClick: () => alert("click yes")
                // }
            // ]
        // })
        
    }
    editUser(){
        // alert("edit user work")
        // debugger
       
        // confirmAlert({
            
        //     title: <h5> You have modified {this.state.userName} information, click proceed to save.</h5>,
        //     // message: 'User Name : ' +this.state.userName,
        //     buttons: [
        //         {
        //             label: 'Cancel',
        //             // onClick: ()=> alert ('click No')
        //         },
        //         {
        //             label: 'Okay, Proceed',
                
        //             // onClick: () => alert("click yes")
        //             onClick: () => {
                    console.log(this.state)
                        var editUserFromUser = [...this.state.userManagement];
                        var index = editUserFromUser.indexOf(this.userForEdit)
                        if(index !== -1){                                      
                            editUserFromUser[index] = this.AddingItem();       
                            this.setState({                                    
                                userManagement: editUserFromUser,
                                       
                                userId : '',
                                userName :  '',
                                userEmailId : '',
                                roleType : '',
                                productHandling : '',
                                zone : '',
                                partner : '',
                                date : this.currDate,
                                showHide : false
                            });                    
                         
                        }
                       
                    // }
                // }
            // ]
        // })
        
        // debugger
       
    }
    getCurrDate=()=>{
        var today = new Date()
        var day =  today.getDate();
        var month = today.getMonth()+1;
        var year = today.getFullYear();

        this.currDate = day+ '-' + month + '-' + year 
    }
    cancelIcon = ()=>{
        this.setState({
            showHide : false,
            userEmailId: '',
        })
        
    }
    ActiveInactive = (user)=>{
        // confirmAlert({
        //     title: <h5>This action will mark this user as Inactive. Requested user will not able to login into the system</h5>,
        //     // message: ''
        //     buttons: [
        //         {
        //         label: 'Cancel',
        //         },
        //         {
        //             label: <h5>Mark as, Inactive</h5>,
        //             onClick: () => {
                        var userInactive = [...this.state.userManagement];
                        var index = userInactive.indexOf(user)
                        if(index !== -1){
                            userInactive[index].status = "inactive";
                        
                        this.setState({
                            userManagement : userInactive,
                            showHide : false,
                            userEmailId: '',
                        })
                    }
        //          }
        //         }

        //     ]
        // })
    } 
    inactiveActive = (user)=>{
        
        // confirmAlert({
        //     title: <h5>This action will mark this user as active. Requested user able to login into the system</h5>,
        //     // message: ''
        //     buttons: [
        //         {
        //         label: 'Cancel',
        //         },
        //         {
        //             label: <h5>Mark as, active</h5>,
        //             onClick: () => {
                        var userInactive = [...this.state.userManagement];
                        var index = userInactive.indexOf(user)
                        if(index !== -1){
                            userInactive[index].status = "active";
                        
                        this.setState({
                            userManagement : userInactive,
                            showHide : false,
                            userEmailId: '',
                        })
                    }
        //             }
        //         }

        //     ]
        // })
    }
 
    handleCreateUser=()=>{
        this.setState({
            createUser : !this.state.createUser
        })
    }
    render() {

      
    
    return (
<div className="userManagement1" >
    <div  className="container-fluid">
    <div className="row">
        <div className="col-md-12 mt-3">
            <div className="row">
            <div className="col-md-12">
                <div  className="card">
                    <div className="card-header">
                        <h5 className="userManagement2" >From here you can Manage User and assign them dashboard access according to product, region, partner wise etc. </h5>
                        {/* <input type="button" onClick={() => this.display()} value="button"/> */}
                        {/* <h2>{this.state.message}</h2> */}
                    </div>
                    <div className="card-body">
                        <div className="table-responsive center-align">
                           {/* <form onSubmit={this.handleSubmit}> */}
                           <form>
                            <table className="table table-bordered">
                                <thead>
                                
                                <tr>
                                <th className="userManagement3"><input type="text" required value={this.state.userName} onChange={this.handleChange} name="userName" className="form-control" placeholder="Enter User Name .. " /></th>
                                <th className="userManagement3"><input type="text" required value={this.state.userEmailId} onChange={this.handleChange} name="userEmailId" className="form-control" placeholder="Enter User Email ID ...  "/></th>
                               
                                <th className="userManagement3"><select required className="form-control" name="roleType" value={this.state.roleType} placeholder="" onChange={this.handleChange}>
                                    <option  selected hidden>Select Role Type...</option>
                                    <option  value="Admin Access"  >Admin Access</option>
                                    <option  value="User Access"  >User Access</option>
                                    {/* <option  value={this.state.roleType} onChange={this.handleChange} name="roleType" disabled selected hidden>Select Role Type...</option> */}
                                </select></th>
                               
                                <th className="userManagement3"><select className="form-control" onChange={this.handleChange} name="productHandling" placeholder=" Select Product Handling">
                                    <option name="productHandling" disabled selected hidden>Select Product Handling</option>
                                    <option value="Product Name 1">Product Name 1</option>
                                    <option value="Product Name 2">Product Name 2</option>
                                    <option value="Journey Name 1">Journey Name 1</option>
                                    <option value="Journey Name 2">Journey Name 2</option>
                                    <option value="Product">All </option>
                                </select></th>
                               
                                <th className="userManagement3"><select className="form-control" name="zone" onChange={this.handleChange} placeholder="   ">
                                    <option disabled selected hidden>Select Zones </option>
                                    <option value="East">East</option>
                                    <option value="West">West</option>
                                    <option value="North">North</option>
                                    <option value="South">South</option>
                                    <option value="All Region">All Region</option>
                                </select></th>
                                <th className="userManagement3"><select className="form-control" onChange={this.handleChange} name="partner" placeholder="      ">
                                    <option value=""disabled selected hidden>Select Partners</option>
                                    <option value="Partner For Product Name 1">Partner For Product Name 1</option>
                                    <option value="Partner For Product Name 2">Partner For Product Name 2</option>
                                    <option value="Partner For Journey Name 1">Partner For Journey Name 1</option>
                                    <option value="Partner For Journey Name 2">Partner For Journey Name 2</option>
                                    <option value="All Partner">All Partners</option>
                                

                                </select></th>
                                <th className="userManagement3"><input value={this.state.date} disabled={true} onChange={this.handleChange} name="date"  type="text" className="form-control" placeholder=" 16 – 09 - 2020   "/></th>
                                {/* <th ><a href="#" className="btn btn-primary" data-toggle="modal" data-target="#myModal">Create User</a></th> */}
                                {/* <th><button onClick={this.handleCreateUser} className="btn btn-primary" type="button">Create User</button></th> */}
                                <th>{this.state.userEmailId ?<><ModalDiv title={"CREATES NEW USER"} content={"You are requesting to add a new user into the system. Password creation link will be send to the requested user Email id"} handleSubmit={this.handleSubmit} buttonSubmit={"Okay, Create User & Send Link"}
                                buttonLabel={"create user"} /> </> : <><button className="btn btn-primary btn-small" value="create user">create user</button></>}</th>
                                {/* <input type="submit" onClick={this.getCurrDate.bind(this)} value="date"/> */}
                                </tr>
                                </thead>
                                {/* *********************************************** */}
                                <tbody>
                                {/* <tr> */}


                                    

                                    {this.state.userManagement.map((user,userIndex) => (
                                        <tr key={userIndex}>
                                            {user.userEmailId !== this.state.userEmailId  ? <>
                                                {user.status == "inactive" ? <>
                                                <td className="userManagement4"> {user.userName}</td>
                                                <td className="userManagement4"> {user.userEmailId}</td>
                                                <td className="userManagement4"> {user.roleType}</td>
                                                <td className="userManagement4"> { user.productHandling}</td>
                                                <td className="userManagement4"> {user.zone}</td>
                                                <td className="userManagement4"> {user.partner}</td>
                                                <td className="userManagement4"> {user.date}</td>    
                                                </> 
                                                    
                                                    :<> 
                                            <td> {user.userName}</td>
                                            <td> {user.userEmailId}</td>
                                            <td> {user.roleType}</td>
                                            <td> { user.productHandling}</td>
                                            <td> {user.zone}</td>
                                            <td> {user.partner}</td>
                                            <td> {user.date}</td>
                                        </>}
                                            </>  : <>

                                <Fragment> 
                                        
                                <th className="userManagement15" ><  input type="text" value={this.state.userName} onChange={this.handleChange} name="userName" className="form-control" placeholder="Enter User Name .. "/></th>
                                <th className="userManagement15"><input type="text" value={this.state.userEmailId} onChange={this.handleChange} disabled={true} name="userEmailId" className="form-control" placeholder="Enter User Email ID ...  "/></th>
                               
                                <th className="userManagement15"><select className="form-control" name="roleType" value={this.state.roleType} placeholder="" onChange={this.handleChange}>
                                    <option  selected hidden>Select Role Type...</option>
                                    <option  value="Admin Access"  >Admin Access</option>
                                    <option  value="User Access"  >User Access</option>
                                    {/* <option  value={this.state.roleType} onChange={this.handleChange} name="roleType" disabled selected hidden>Select Role Type...</option> */}
                                </select></th>
                               
                                <th className="userManagement15"><select className="form-control" onChange={this.handleChange} name="productHandling" placeholder=" Select Product Handling">
                                    <option name="productHandling" disabled selected hidden>Select Product Handling</option>
                                    <option value="Product Name 1">Product Name 1</option>
                                    <option value="Product Name 2">Product Name 2</option>
                                    <option value="Journey Name 1">Journey Name 1</option>
                                    <option value="Journey Name 2">Journey Name 2</option>
                                    <option value="All Product">All</option>
                                </select></th>
                               
                                <th className="userManagement15"><select className="form-control" name="zone" onChange={this.handleChange} placeholder="   ">
                                    <option disabled selected hidden>Select Zones </option>
                                    <option value="East">East</option>
                                    <option value="West">West</option>
                                    <option value="North">North</option>
                                    <option value="South">South</option>
                                    <option value="All Region">All Region</option>
                                </select></th>
                                <th className="userManagement15"><select className="form-control" onChange={this.handleChange} name="partner" placeholder="      ">
                                    <option value=""disabled selected hidden>Select Partners</option>
                                    <option value="Partner For Product Name 1">Partner For Product Name 1</option>
                                    <option value="Partner For Product Name 2">Partner For Product Name 2</option>
                                    <option value="Partner For Journey Name 1">Partner For Journey Name 1</option>
                                    <option value="Partner For Journey Name 2">Partner For Journey Name 2</option>
                                    <option value="All ">All</option>
                                

                                </select></th>
                                <th className="userManagement15"><input value={this.state.date} disabled={true} onChange={this.handleChange} name="date"  type="text" className="form-control" placeholder=" 16 – 09 - 2020   "/></th>
                                
                                </Fragment>
                                            </> }     
                                        <td><ul className="navbar-nav flex-row justify-content-center userManagement6">

                                { user.status == "inactive" ? 
                                    <> 
                                    {/* <button type="button" onClick={ ()=>{this.inactiveActive(user)}} className="btn btn-danger btn-small">Inactive</button> */}
                                    <ModalDiv title={"USER ACTIVE"} content={`This action will mark this user as Active. Requested user will able to login into the system`} 
                                    handleSubmit={this.inactiveActive.bind(this, user)} classname={"btn btn-danger"} buttonLabel={"Inactive"} user={user} buttonSubmit={"Mark, as Active"}/>
                                        
                                    </>
                                    
                                    :   
                                    <>
                                      { this.state.showHide === true ? <>
                                     
                                        <li  className="nav-item p-3">
                                            {/* <i className="" onClick={this.deleteUser.bind(this)} ></i> */}
                                            {/* <i className="fas fa-trash-alt"> */}
                                                <ModalDiv title={"DELETE USER"} content={"Are you sure, you want to delete this user entry?"} 
                                                handleSubmit={this.deleteUser} classname={"fas fa-trash-alt"} action={"edit"} user={user} buttonSubmit={"Okay, proceed"}/>
                                             {/* </i> */}
                                        </li>
                                        {/* <li className="nav-item p-3"><i className="fas fa-print" onClick={this.editUser.bind(this)} ></i></li> */}
                                        <li className="nav-item p-3">
                                        <ModalDiv title={"EDIT USER"} content={`You have modified ${this.state.userEmailId} information, click proceed to save.?`} 
                                        handleSubmit={this.editUser} classname={"fas fa-print"} action={"edit"} user={user} buttonSubmit={"Okay, proceed"}/>
                                        </li>
                                        <li className="nav-item p-3"><i className="fas fa-times" onClick={ () => { this.cancelIcon() } }></i></li>
                                        {/* <li className="nav-item p-3"><i className="fas fa-eye-slash" onClick={ () => { this.ActiveInactive(user)}} ></i></li> */}
                                        
                                        { user.status == "active" ?<><li className="nav-item p-3">
                                        <ModalDiv title={"USER INACTIVE"} content={`This action will mark this user as Inactive. Requested user will not able to login into the system`} 
                                        handleSubmit={this.ActiveInactive.bind(this, user)} classname={"fas fa-eye-slash"} action={"edit"} user={user} buttonSubmit={"Mark, as Inactive"}/>
                                        </li> 
                                        </> : <> <li className="nav-item p-3">
                                        <ModalDiv title={"USER ACTIVE"} content={`This action will mark this user as Active. Requested user will able to login into the system`} 
                                        handleSubmit={this.inactiveActive.bind(this, user)} classname={"fas fa-print"} action={"edit"} user={user} buttonSubmit={"Mark, as Active"}/>
                                        </li>  </> }
                                        
                                        
                                        </> 
                                        : <li className="nav-item p-3"><i className="fas fa-pencil-alt" onClick={this.editUserManagement.bind(this,user)}></i></li>
                                        
                                    }
                                    </>
                                }
                                </ul></td>

                                </tr>

                                    ))}
                                </tbody>
                                {/* ***************************************** */}
                            </table>
                            </form>
                        </div>
                    </div>
                </div>
                
                {/* <div className="modal" id="myModal">
                    <div className="modal-dialog">
                    <div className="modal-content">
                
                    
                        <div className="modal-header">
                        <h4 className="modal-title">Requesting to create new user. </h4>
                        <button type="button" className="close" data-dismiss="modal">&times;</button>
                        </div>
                
                    
                        <div className="modal-body">
                            This will create new user and a password set link will be send to the user email id. Please confirm to continue 
                        </div>
                
                        </div>
                        <div className="modal-footer justify-content-center">
                        <button type="button" className="btn btn-danger" data-dismiss="modal">Cancel</button>
                        <button type="submit" className="btn btn-primary" data-dismiss="modal">Create User & Send Link</button>
                        </div>
                
                    </div>
                    </div> */}
                </div>
            </div>
            </div>
        </div>

        </div>








<div className="modal fade" id="editUserModal" tabIndex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
  <div className="modal-dialog" role="document">
    <div className="modal-content">
      <div className="modal-header">
        <h5 className="modal-title" id="exampleModalLabel">Edit User Record</h5>
        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
          <span aria-hidden="true">&times;</span>
        </button>
      </div>
      <div className="modal-body">
            
            <div className="form-group">
            <input type="text" value={this.state.userName} onChange={this.handleChange} name="userName" className="form-control" placeholder="Enter User Name .. "/>
            </div>

            <div className="form-group">
            <input type="text" value={this.state.userEmailId} onChange={this.handleChange} name="userEmailId" className="form-control" placeholder="Enter User Email ID ...  "/>
            </div>
            <div className="form-group">
            <select className="form-control" placeholder="" onChange={this.handleChange} name="roleType">
                <option selected hidden value={this.state.roleType}  name="roleType" disabled selected hidden>{this.state.roleType}</option>
                <option value="Admin Access"  >Admin Access</option>
                <option value="User Access"  >User Access</option>
            </select>
            </div>
           
            <div className="form-group">
            <select className="form-control" onChange={this.handleChange} name="productHandling" placeholder=" Select Product Handling  ">
                <option value={this.state.productHandling}   disabled selected hidden>{this.state.productHandling}</option>
                <option value="UPI">UPI</option>
                <option value="RuPay">RuPay</option>
                <option value="FastTag">FastTag</option>
                <option value="All Product">All Product</option>
            </select>
            </div>
           
            <div className="form-group">
            <select className="form-control" onChange={this.handleChange} name="zone" placeholder="   ">
                <option value={this.state.zone}  disabled selected hidden>{this.state.zone} </option>
                <option value="East">East</option>
                <option value="West">West</option>
                <option value="North">North</option>
                <option value="South">South</option>
                <option value="All Region">All Region</option>
            </select>
            </div>
           
            <div className="form-group">
            <select className="form-control" onChange={this.handleChange} name="partner" placeholder="      ">
                <option value={this.state.partner}  disabled selected hidden>{this.state.partner}</option>
            
                <option value="Partner For Product UPI">Partner For Product UPI</option>
                <option value="Partner For Product RuPay">Partner For Product RuPay</option>
                <option value="Partner For Product FastTag">Partner For Product FastTag</option>
                </select>
            </div>

            <div className="form-group">
            <input value={this.state.date} onChange={this.handleChange} name="date"  type="text" className="form-control" placeholder="16 – 09 - 2020"/>
            </div>
           
         
        <input className="btn btn-secondary userManagement6" type="button" onClick={this.editUser.bind(this)} data-dismiss="modal" value="Edit"/>
        <input className="btn btn-danger userManagement6" type="button" onClick={this.deleteUser.bind(this, this.state.userName)} data-dismiss="modal" value="Delete"/>
        <button type="button" className="btn btn-secondary  userManagement6" data-dismiss="modal">Close</button>
           
                                
      </div>

      {/* <div className="modal-footer">
        <button type="button" className="btn btn-secondary " data-dismiss="modal">Close</button>
        <button type="button" className="btn btn-primary">Save changes</button>
      </div> */}
    </div>
  </div>
</div>

</div>


    )
}
}


export default userManagementCommon;