
import React, { useEffect, useState } from 'react';
import './OverallNpsScore.css';
import NpsSpeedoChart from '../../charts/npsSpeedoChart';
import NpsSpeedoChartSub from '../../charts/npsSpeedoChartSub';
import SplineChart from '../../charts/splineChart';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash'
import { Loader } from '../../../common/loader.js';
import { dateFilter, lastMonth, currentMonth, currentDay, Yesterday } from '../../../utils/common';
import Skeleton from 'react-loading-skeleton';


const OverallNpsScore = (props) => {
   const api = new API()
   const { Npci, Upi, Rupay, Fastag, Bhim, Netc,npsScore,loader,skeletonLoader1,setSkeletonLoader1 } = props;
   const [tabs, setTabs] = useState("Today")
   const [tabsDailyTrand, setTabsDailyTrand] = useState("Current Month3")
   const [dailyNpsTrends, setDailyNpsTrends] = useState([])
   const [skeletonLoader, setSkeletonLoader] = useState(false)
   // const [loader, setLoader] = useState(true);
   const [more,setMore] = useState(false);

    // nps score //
//   const [npci, setNpci] = useState([])
//   const [upi, setUpi] = useState([])
//   const [fastag, setFastag] = useState([])
//   const [rupay, setRupay] = useState([])
//   const [bhim, setBhim] = useState([])
//   const [netc, setNetc] = useState([])

   useEffect(() => {
      
    let start_date = currentDay('end_date')
    let end_date = currentDay('end_date')
      dailyNpsTrend({ start_date: currentMonth('start_date'), end_date: currentMonth('end_date') })

   }, [])

 // nps score //

//  const npsScore = async (dateFilter) => {
   

//   let npsScoreResult = await api.get(config.dashboardScore, true, dateFilter)
   
//    setLoader(false)
//    let npci = npsScoreResult.data.npci
//    let upi = npsScoreResult.data.upi
//    let fastag = npsScoreResult.data.fastag
//    let rupay = npsScoreResult.data.rupay
//    let bhim = npsScoreResult.data.bhim
//    let netc = npsScoreResult.data.netc_webform
//    setNpci(npci)
//    setUpi(upi)
//    setFastag(fastag)
//    setRupay(rupay)
//    setBhim(bhim)
//    setNetc(netc)
//    setLoader(false)
//  }


   const percentageCalculation = (Obj, key) => {
      let calculateValue = ((get(Obj, key, 0)) / ((get(Obj, 'total_promoters_rating', 0)) + (get(Obj, 'total_passive_rating', 0)) + (get(Obj, 'total_detectors_rating', 0))) * 100)

      calculateValue = isNaN(calculateValue) ? 0 : calculateValue
      return calculateValue.toFixed(2)
   }

   const onClickActive = (e) => {
      let dateFilter = {}
      if (e.target.id === "0") {
         setTabs('Today')
         dateFilter = { start_date: currentDay('start_date'), end_date: currentDay('end_date') }
         npsScore(dateFilter)
      }
      if (e.target.id === "1") {
         setTabs('Current Month')
         dateFilter = { start_date: currentMonth('start_date'), end_date: currentMonth('end_date') }
         npsScore(dateFilter)
      }
      if (e.target.id === "2") {
         setTabs('Last Month')
         dateFilter = { start_date: lastMonth('start_date'), end_date: lastMonth('end_date') }
         npsScore(dateFilter)
      }
      if (e.target.id === "3") {
         setTabs('Yesterday')
         dateFilter = { start_date: Yesterday('start_date'), end_date: Yesterday('end_date') }
         npsScore(dateFilter)
      }
      if (e.target.id === "4") {
         setTabsDailyTrand('Current Month3')
         dateFilter = { start_date: currentMonth('start_date'), end_date: currentMonth('end_date') }
         dailyNpsTrend(dateFilter)
      }
      if (e.target.id === "5") {
         setTabsDailyTrand('Last Month4')
         dateFilter = { start_date: lastMonth('start_date'), end_date: lastMonth('end_date') }
         dailyNpsTrend(dateFilter)
      }
      
   }
   const dailyNpsTrend = async (dateFilter) => {
      setSkeletonLoader(true)
      let dailyNpsTrendResult = await api.get(config.dashboardQDailyNpsTrend, true, dateFilter);
      let dailyNpsTrendResultData = dailyNpsTrendResult.data
      setDailyNpsTrends(dailyNpsTrendResultData)
      setSkeletonLoader(false)
   }

  
   return (



      <div className="col-md-12 mt-3">
         <div className="row">
            <div className="col-md-12">
               <div className="card bg-white left-right">
                  <div className="card-header d-flex justify-content-between align-items-center">

                     <img className="overallNpsScore3" src="img/qdegree.png" />

                     <h6 className="align-left"><img src="img/001-speedometer.png" className="overallNpsScore4" />
                     <b>  NPS Score = % (of Promoters) - % (of Detractors) </b>
                     </h6>
                     <div className="overallNpsScore5" >
                        {/* <button  onClick={onClickActive} id="1" className={"Current Month"== tabs ? "btn btn-primary active": "btn btn-primary"}>Current Month</button>
                        <button  onClick={onClickActive} id="2"  className={"Last Month"== tabs ? "btn btn-primary active": "btn btn-primary"}>Last Month</button> */}

                         { more ? <>  <button onClick={onClickActive} id="0" className={"Today" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>Today</button>
                        <button onClick={onClickActive} id="3" className={"Yesterday" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>Yesterday</button>
                        <button onClick={onClickActive} id="1" className={"Current Month" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>Current Month</button>
                         <button onClick={onClickActive} id="2" className={"Last Month" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>Last Month</button>
                        
                    </> : <> <button onClick={onClickActive} id="0" className={"Today" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>Today</button>
                        <button onClick={onClickActive} id="3" className={"Yesterday" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>Yesterday</button>
                      </>  }
                        <i className="fa  fa-bars ml-2 btn"  onClick={()=>setMore(!more)}/>
                     

                     </div>


                  </div>
                  <div className="card-body">
                     <div className="row">
                        <div className="col-md-12">
                           {skeletonLoader1 == true ?
                              <Skeleton count={20} />
                              : <>


{skeletonLoader1 == true  ? 
          <Skeleton count={20} /> : <>
                                       <div className="row">
                                          <div style={{ marginTop: 114 }} className="col-md-4">


                                             <NpsSpeedoChart title={""} name={'Score'} data={Math.round(get(Npci, 'total_score', 0))} />
                                             <h5 style={{ textTransform: "uppercase" }} className="col-lg-12 text-center "><b>Over All NPS Score</b></h5>
                                             <div className="row">
                                                <div className="col-lg-12 text-center">
                                                   <h2 className="text-danger mt-4">{(get(Npci, 'total_score', 0))}</h2>
                                                   <h6 className="text-danger">Net Promoter Score</h6>
                                                   <small className="text-secondary">{(get(Npci, 'tota_responses', 0))} Responses</small>
                                                </div>
                                             </div>
                                          </div>

                                          <div className="col-md-8">
                                             <div className="row">
                                                <div className="col-md-6 mt-2">

                                                   <NpsSpeedoChartSub title={""} name={'Score'} data={Math.round(get(Upi, 'total_score', 0))} />
                                                   <h5 style={{ textTransform: "uppercase" }} className="col-lg-12 text-center"><b >{(get(Upi, 'product_title', ''))}</b></h5>
                                                   <div className="row">
                                                      <div className="col-lg-4 text-center">
                                                         <h2 className="text-danger mt-3">{Math.round(get(Upi, 'total_score', 0))}</h2>
                                                         <h6 className="text-danger">Net Promoter Score</h6>
                                                         <small className="text-secondary">{(get(Upi, 'total_response', 0))} Responses</small>
                                                      </div>
                                                      <div className="col-lg-8 overallNpsScore6">

                                                         <div className="position-relative mt-4">
                                                            <div className="custom-success">
                                                               <div className="progress ml-4">
                                                                  <div className="progress-bar bg-success" style={{ width: `${percentageCalculation(Upi, 'total_promoters_rating')}${'%'}` }}>
                                                                  {`${percentageCalculation(Upi, 'total_promoters_rating')}${'%'}`}
                                                                  </div>
                                                               </div>

                                                            </div>
                                                         </div>

                                                         <div className="position-relative mt-4">
                                                            <div className=" custom-warning">
                                                               <div className="progress ml-4">
                                                                  <div className="progress-bar bg-warning" style={{ width: `${percentageCalculation(Upi, 'total_passive_rating')}${'%'}` }} >
                                                                  {`${percentageCalculation(Upi, 'total_passive_rating')}${'%'}`}
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>

                                                         <div className="position-relative mt-4">
                                                            <div className="custom-progress">
                                                               <div className="progress ml-4">
                                                                  <div className="progress-bar bg-danger" style={{ width: `${percentageCalculation(Upi, 'total_detectors_rating')}${'%'}` }} >
                                                                  {`${percentageCalculation(Upi, 'total_detectors_rating')}${'%'}`}
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-md-6 mt-2">

                                                   <NpsSpeedoChartSub title={""} name={'Score'} data={Math.round(get(Rupay, 'total_score', 0))} />
                                                   <h5 style={{ textTransform: "uppercase" }} className="col-lg-12 text-center"><b>{(get(Rupay, 'product_title', ''))}</b></h5>
                                                   <div className="row">
                                                      <div className="col-lg-4 text-center">
                                                         <h2 className="text-danger mt-3">{Math.round(get(Rupay, 'total_score', 0))}</h2>
                                                         <h6 className="text-danger">Net Promoter Score</h6>
                                                         <small className="text-secondary">{(get(Rupay, 'total_response', 0))} Responses</small>
                                                      </div>
                                                      <div className="col-lg-8 overallNpsScore6">

                                                         <div className="position-relative mt-4">
                                                            <div className="custom-success">
                                                               <div className="progress ml-4">
                                                                  <div className="progress-bar bg-success" style={{ width: `${percentageCalculation(Rupay, 'total_promoters_rating')}${'%'}` }} >
                                                                  {`${percentageCalculation(Rupay, 'total_promoters_rating')}${'%'}`}
                                                                  </div>
                                                               </div>

                                                            </div>
                                                         </div>

                                                         <div className="position-relative mt-4">
                                                            <div className=" custom-warning">
                                                               <div className="progress ml-4">
                                                                  <div className="progress-bar bg-warning" style={{ width: `${percentageCalculation(Rupay, 'total_passive_rating')}${'%'}` }} >
                                                                  {`${percentageCalculation(Rupay, 'total_passive_rating')}${'%'}`}
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>

                                                         <div className="position-relative mt-4">
                                                            <div className="custom-progress">
                                                               <div className="progress ml-4">
                                                                  <div className="progress-bar bg-danger" style={{ width: `${percentageCalculation(Rupay, 'total_detectors_rating')}${'%'}` }} >
                                                                  {`${percentageCalculation(Rupay, 'total_detectors_rating')}${'%'}`}
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>
                                                <div className="col-md-6 mt-2">

<NpsSpeedoChartSub title={""} name={'Score'} data={Math.round(get(Fastag, 'total_score', 0))} />
<h5 style={{ textTransform: "uppercase" }} className="col-lg-12 text-center"><b>{(get(Fastag, 'product_title', ''))}</b></h5>
<div className="row">
   <div className="col-lg-4 text-center">
      <h2 className="text-danger mt-3">{Math.round(get(Fastag, 'total_score', 0))}</h2>
      <h6 className="text-danger">Net Promoter Score</h6>
      <small className="text-secondary">{(get(Fastag, 'total_response', 0))} Responses</small>
   </div>
   <div className="col-lg-8 overallNpsScore6">

      <div className="position-relative mt-4">
         <div className="custom-success">
            <div className="progress ml-4">
               <div className="progress-bar bg-success" style={{ width: `${percentageCalculation(Fastag, 'total_promoters_rating')}${'%'}` }} >
               {`${percentageCalculation(Fastag, 'total_promoters_rating')}${'%'}`}
               </div>
            </div>

         </div>
      </div>

      <div className="position-relative mt-4">
         <div className=" custom-warning">
            <div className="progress ml-4">
               <div className="progress-bar bg-warning" style={{ width: `${percentageCalculation(Fastag, 'total_passive_rating')}${'%'}` }} >
               {`${percentageCalculation(Fastag, 'total_passive_rating')}${'%'}`}
               </div>
            </div>
         </div>
      </div>

      <div className="position-relative mt-4">
         <div className="custom-progress">
            <div className="progress ml-4">
               <div className="progress-bar bg-danger" style={{ width: `${percentageCalculation(Fastag, 'total_detectors_rating')}${'%'}` }} >
               {`${percentageCalculation(Fastag, 'total_detectors_rating')}${'%'}`}
               </div>
            </div>
         </div>
      </div>
   </div>
</div>
</div>

                                                <div className="col-md-6 mt-2">
                                                   <NpsSpeedoChartSub title={""} name={'Score'} data={Math.round(get(Bhim, 'total_score', 0))} />
                                                   <h5 style={{ textTransform: "uppercase" }} className="col-lg-12 text-center"><b>{(get(Bhim, 'product_title', ''))}</b></h5>
                                                   <div className="row">
                                                      <div className="col-lg-4 text-center">
                                                         <h2 className="text-danger mt-3">{Math.round(get(Bhim, 'total_score', 0))}</h2>
                                                         <h6 className="text-danger">Net Promoter Score</h6>
                                                         <small className="text-secondary">{(get(Bhim, 'total_response', 0))} Responses</small>
                                                      </div>
                                                      <div className="col-lg-8 overallNpsScore6">

                                                         <div className="position-relative mt-4">
                                                            <div className="custom-success">
                                                               <div className="progress ml-4">
                                                                  <div className="progress-bar bg-success" style={{ width: `${percentageCalculation(Bhim, 'total_promoters_rating')}${'%'}` }} >
                                                                  {`${percentageCalculation(Bhim, 'total_promoters_rating')}${'%'}`}
                                                                  </div>
                                                               </div>

                                                            </div>
                                                         </div>

                                                         <div className="position-relative mt-4">
                                                            <div className=" custom-warning">
                                                               <div className="progress ml-4">
                                                                  <div className="progress-bar bg-warning" style={{ width: `${percentageCalculation(Bhim, 'total_passive_rating')}${'%'}` }} >
                                                                  {`${percentageCalculation(Bhim, 'total_passive_rating')}${'%'}`}
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>

                                                         <div className="position-relative mt-4">
                                                            <div className="custom-progress">
                                                               <div className="progress ml-4">
                                                                  <div className="progress-bar bg-danger" style={{ width: `${percentageCalculation(Bhim, 'total_detectors_rating')}${'%'}` }} >
                                                                  {`${percentageCalculation(Bhim, 'total_detectors_rating')}${'%'}`}
                                                                  </div>
                                                               </div>
                                                            </div>
                                                         </div>
                                                      </div>
                                                   </div>
                                                </div>

                                                
                                             </div>
                                          </div>
                                       </div>

                                    </>}
                              </>}
                        </div>
                     </div>

                  </div>
               </div>

            </div>
         </div>
         <div className="row">
            <div className="col-md-12">
               <div className="card bg-white left-right">
                  <div className="card-header d-flex justify-content-between" >
                     <div className="d-flex align-items-center">
                        {/* <img style={{ width: '9%', marginRight: '10px' }} src="img/npci-logo.png" /> */}
                        <h5 className="text-left overallNpsScore1"><b>Date-Wise NPS Performance</b></h5>

                     </div>
                     <div className="cls">
                        <button onClick={onClickActive} id="4" className={"Current Month3" == tabsDailyTrand ? "btn bg-col-sky active" : "btn bg-col-sky"}>Current Month</button>
                        <button onClick={onClickActive} id="5" className={"Last Month4" == tabsDailyTrand ? "btn bg-col-sky active" : "btn bg-col-sky"}>Last Month</button>
                     </div>
                  </div>
                  <div className="card-body overallNpsScore2">
                     <div className="row">
                        <div className="col-md-12">
                           {skeletonLoader == true ?
                              <Skeleton count={20} />
                              : <>
                                 {skeletonLoader == true || dailyNpsTrends && dailyNpsTrends.length == 0 ? <>
                                    <Skeleton count={20} />
                                 </> : <>
                                       <SplineChart dailyNpsTrends={dailyNpsTrends} />
                                    </>}
                              </>}
                        </div>
                     </div>

                  </div>
               </div>

            </div>
         </div>
      </div>
   )
}

export default OverallNpsScore