import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import WordCloud from "highcharts/modules/wordcloud.src.js"
import Pareto from "highcharts/modules/pareto.js"
import Exporting from "highcharts/modules/exporting.js"
import ExportData from "highcharts/modules/export-data.js"


ExportData(Highcharts);
Exporting(Highcharts);
WordCloud(Highcharts);
Pareto(Highcharts);

const DemoChart = (props) => {
  const { Name, DailyCount,MonthDate,MtdNpsScore,Count,NpsScore} = props


 
  useEffect(() => {

    MonthDate.forEach((itemDate,index)=>{
      MonthDate[index]=itemDate + ", (c="+DailyCount[index]+")";
    })
  }, [])

 
const options={
  chart: {
    renderTo: 'container',
    type: 'column'
},
title: {
    text: '<div className="d-flex text-align:left "> C =  <b className="curtom-circle color:green"> Counts </b></div>'
},
tooltip: {
    shared: true
},
xAxis: [{
    categories: MonthDate,
    crosshair: true,
    title: {
      text: 'nps score and counts'
    }
}
],
yAxis: [{
    title: {
        text: ''
    },max: 100,
    min: -100,
}, {
    title: {
        text: ''
    },
   
    minPadding: 0,
    maxPadding: 0,
    opposite: true,
    // labels: {
    //     format: "{value}%"
    // }
}],
series: [{
    type: 'pareto',
    name: 'DailyCount',
    data:DailyCount,
    yAxis: 1,
    zIndex:2,
    dataLabels: {
      enabled: true,
      
      y: 20, // 10 pixels down from the top
      style: {
          fontSize: '10px',
          fontFamily: 'Verdana, sans-serif'
      }
  }
}, {
    name: 'NpsScore',
    type: 'column',
    zIndex: 2,
    data: NpsScore,
    dataLabels: {
      enabled: true,
      
      y: 20, // 10 pixels down from the top
      style: {
          fontSize: '10px',
          fontFamily: 'Verdana, sans-serif'
      }
  }
}]
}
  return (
    <Fragment>

      <HighchartsReact 
        highcharts={Highcharts}
        options={options}
      />


    </Fragment>
  );
}

export default DemoChart;