import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Timeline from "highcharts/modules/timeline.js"
import {get} from 'lodash'


Timeline(Highcharts);
const BasicDetailChart = (props) => {

    const {width,Required,Achieved,NPS} = props;
    // console.log("required", Required)
    // console.log("Achieved", Achieved)
    // console.log("width", width)

    
const options = {
    title: {
        text: '',
        align: 'left',
        margin: 0,
      },
     
      chart: {
        renderTo: 'container',
        type: 'bar',
        height: 80,
        width: width===true ? 500 : 290
      },
      credits: false,
      legend: false,
      navigation: {
        buttonOptions: {
          enabled: false
        }
      },
      xAxis: {
        visible: false,
      },
      yAxis: {
        visible: false,
        min: 0,
        max: 100,
      },
      tooltip: {
        valueSuffix: '%'
    },
      series: [{
        data:[100], //[`${Achieved}${Required}`],
        grouping: false,
        animation: false,
        enableMouseTracking: false,
        showInLegend: false,
        color: 'lightskyblue',
        pointWidth: 25,
        borderRadiusTopLeft: '4px',
        borderRadiusTopRight: '4px',
        borderRadiusBottomLeft: '4px',
        borderRadiusBottomRight: '4px',
        dataLabels: {
          className: 'highlight',
          format: `${(Achieved).toString()}/${(Required).toString()}`,//'200/150',//isNaN(Achieved) ? 0: Achieved.toString()/ isNaN(Required) ? 0: Required.toString() ,
          enabled: true,
          align: 'right',
          style: {
            color: 'white',
            textOutline: false,
          }
        }
      }, {
        enableMouseTracking: true,
        name:'Percentage of Achieved/Required ',
        data: [Math.round((Achieved/Required)*100)],
        borderRadiusBottomLeft: '4px',
        borderRadiusBottomRight: '4px',
        color: 'navy',
        pointWidth: 25,
        animation: {
          duration: 250,
        },
        dataLabels: {
          enabled: true,
          inside: true,
          align: 'left',
          format: '{point.y}%',
          style: {
            color: 'white',
            textOutline: false,
          }
        }
      }]
//     chart: {
//         type: 'timeline',
//         width: width===true ? 650 : 400
//     },
//     accessibility: {
//         screenReaderSection: {
//             beforeChartFormat: '<h5>{chartTitle}</h5>' +
//                 '<div>{typeDescription}</div>' +
//                 '<div>{chartSubtitle}</div>' +
//                 '<div>{chartLongdesc}</div>' +
//                 '<div>{viewTableButton}</div>'
//         },
//         point: {
//             valueDescriptionFormat: '{index}. {point.label}. {point.description}.'
//         }
//     },
//     xAxis: {
//         visible: false
//     },
//     yAxis: {
//         visible: false
//     },
//     title: {
//         text: ''
//     },
//     subtitle: {
//         text: ''
//     },
//     exporting: {
//     enabled: false
//   },
//     colors: [
//         '#4185F3',
//         '#427CDD',
//         '#406AB2',
//         '#3E5A8E',
//         '#3B4A68',
//         '#363C46'
//     ],
//     series: [{
//         data: [{
//             name: 'Target Required',
//             label: Required,//Math.round(Required),
//             description: ' '
//         }, {
//             name: 'Target Achieved',
//              label:Achieved, ///Math.round(Achieved),
//             description: ''
//         }, {
//             name: 'Net Promoter Score',
//             label: NPS,//Math.round(NPS),
//             description: ''
//         }]
//     }]
}

    return ( 
        <HighchartsReact 
            highcharts={Highcharts}
            options={options}
            
        />
     );
}
 
export default BasicDetailChart;