import React, { useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { Loader } from '../../common/loader.js';


const QuestionsAndResponsesChartSub = (props) => {

    const {title,questionAndResponseChart,onClick} = props
const options = {
    chart: {
        plotBackgroundColor: null,
        plotBorderWidth: null,
        plotShadow: false,
        type: 'pie',
        margin: [6, 6, 6, 6],
        spacingTop: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        spacingRight: 0
    },
    title: {
        text: title //'Parameter wise Responses'
    },
    exporting: {
    enabled: false
  },
    tooltip: {
        pointFormat: '{series.name}: <b>{point.percentage:.1f}%</b>'
    },
    accessibility: {
        point: {
            valueSuffix: '%'
        }
    },
    plotOptions: {
        pie: {
         size:'60%',
            allowPointSelect: true,
            cursor: 'pointer',
            dataLabels: {
                enabled: true,
                format: '<b>{point.name}</b>: {point.percentage:.1f} %'
            }
        }
    },
    series: [{
        name: 'Brands',
        colorByPoint: true,
        data: questionAndResponseChart,
        events: {
            click:  function (event) {
                onClick(event)
            }
        }
        
    //     [{
    //         name: 'Any other Specific feature',
    //         y: 11.41,
    //         sliced: true,
    //         selected: true
    //     },  {
    //         name: 'Issue resolution',
    //         y: 1.64
    //     }, {
    //         name: 'Easy to use app & plateform',
    //         y: 1.6
    //     }, {
    //         name: 'Security of payment',
    //         y: 1.2
    //     }, {
    //         name: 'Widely accepted at merchants/ecommerce platforms',
    //         y: 2.61
    //     }]
     }]
}

    return ( 
        <div> 
        {questionAndResponseChart == null ?<> 
            <Loader/>
        </>
        : <HighchartsReact
            highcharts={Highcharts}
            options={options}
            style={{width:450,height:400}}
        /> }
     </div>
     );
}
 
export default QuestionsAndResponsesChartSub;