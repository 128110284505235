import React, { Fragment, useState } from 'react';
// import { final } from './finalSurvey';
import './q1.css';
import FinalSurvey from './finalSurvey'
import {final} from './finalSurvey'
import { Link } from 'react-router-dom';

const QuestionOne = () => {
    const [allSurveyQuestion, setAllSurveyQuestion] = ([])
    const [uploadimg, setUploadimg ] = useState("CreateSurvey/icons/2.png")
    const [changeQuestionField, setchangeQuestionField ] = useState(false)
    const [que, setQue] = useState("Type Your Question Here")

    const [option, setOption] = useState({option1: "Type Option one", option2: " Type Option two", option3: "type Option Three"})
    const [changeOptionField, setchangeOptionField ] = useState({changeOption1Field: false,changeOption2Field: false,changeOption3Field: false })

const Uploadimg = (e) =>{
    setUploadimg(URL.createObjectURL(e.target.files[0]))
}
const changeQuestion = () =>{
    setchangeQuestionField(!changeQuestionField)
}
const changeQuestionDone = (e) =>{

    
    setQue(e.target.value)
}
const changeOption = (name) => {
    console.log(name)
    console.log(final)
    setchangeOptionField((prevState) => ({
        ...prevState, [name]: !prevState[name]
    }))
    
}
const changeOptionDone = (e) => {
    let value = e.target.value
    let name = e.target.name
    console.log(value, name)
    // setOption((prevState) =>({
    //     ...prevState, [name] : !prevState[value]
    // }))
    setOption({...option, [name]: value})
    
}
const submit=()=>{
    final.push({que, option, type: 1})
    console.log(final)
    alert("question added")
}

    
    return (
        
        <Fragment>
         {/* <!-- Blue https://codepen.io/angel_crawford/pen/pRrvbG --> */}
            <header className="fixed-top">
                <div className="header-wrap d-block text-center">
                    <img src= "CreateSurvey/images/logo.png" alt="" className="" style={{height: "50px", paddingTop: "5px"}} />
                </div>
                <div className="line-bottom"></div>
            </header>



  {/* <!-- https://simplestepscode.com/circular-progress-bar-with-css-and-color-change/  ( For Dialer Refernce ) --> */}
  <div className="min-height-body">
    <div className="">
    <div className="position-relative card-header custom-header equal-spacing extra-margin">
    {changeQuestionField == false && <h6 onClick={changeQuestion} className="f-16 mb-0 d-block mx-auto"> {que} ?</h6> }
    {changeQuestionField == true &&  
    <input onChange={changeQuestionDone} onMouseLeave={changeQuestion} style={{width:"30%", margin:"auto"}} className="form-control input-sm" value={que} />}
                    </div>
    
   

                    <div className="container">
                        <ul className="row px-2 mb-0 text-center">
                            <li className="col-lg-4 col-12 px-2 py-2">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="1" name="A"/>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-5 col-5 center-vh custom-border-right">
                                                <label for="op1" className="labelClass">
                                                <input onChange={Uploadimg} id="op1" type="file" hidden/>
                                                    <img for="op1" src={uploadimg} alt="" className="mx-auto  icon-img" />
                                                </label> 
                                                    
                                                    </div>
                                            
                                            <div className="col-lg-7  col-7 center-vh">
                                        {changeOptionField.changeOption1Field == false &&  <h6 onClick={() => changeOption('changeOption1Field')} name="changeOption1Field" style={{color:"black"}} className="f-14 mb-0">{option.option1} </h6> }
                                        {changeOptionField.changeOption1Field == true && <input onChange={changeOptionDone} onMouseLeave={() => changeOption('changeOption1Field')} name="option1" style={{margin:"auto"}} className="form-control input-sm" value={option.option1} /> }
                                                </div>
                                            </div>
                                        </div>
                                    {/* </input> */}
                                </label>
                            </li>
                            <li className="col-lg-4 col-12 px-2 py-lg-2">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="2" name="A"/>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-5 col-5 center-vh custom-border-right">
                                                <label for="op1" className="labelClass">
                                                <input id="op1" type="file" hidden/>
                                                    <img src="CreateSurvey/images/UPI_2.svg" alt="" className="mx-auto  icon-img" />
                                                </label>
                                                </div><div className="col-lg-7  col-7 center-vh">
                                     {changeOptionField.changeOption2Field == false && <h6 style={{color:"black"}} onClick={() => changeOption('changeOption2Field')} className="f-14  mb-0">{option.option2}</h6> }
                                     {changeOptionField.changeOption2Field == true && <input onChange={changeOptionDone} onMouseLeave={() => changeOption('changeOption2Field')} name="option2" style={{margin:"auto"}} className="form-control input-sm" value={option.option2} /> }
                                                </div>
                                            </div>
                                        </div>
                                    {/* </input> */}
                                </label>
                            </li>

                            <li className="col-lg-4 col-12 px-2 py-2">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="3" name="A"/>
                                        <div className="container">
                                            <div className="row center-vh">
                                                <div className="col-lg-5 col-5 center-vh custom-border-right">
                                                <label for="op1" className="labelClass">
                                                <input id="op1" type="file" hidden/>
                                                    <img src="CreateSurvey/icons/3.png" alt="" className="mx-auto  icon-img" />
                                                </label>
                                                </div>
                                                <div className="col-lg-7 col-7 center-vh">
                            {changeOptionField.changeOption3Field == false &&  <h6 onClick={() => changeOption('changeOption3Field')} style={{color:"black"}} className="f-14  mb-0"> {option.option3}</h6> }
                            { changeOptionField.changeOption3Field == true && <input onChange={changeOptionDone} onMouseLeave={() => changeOption('changeOption3Field')} name="option3" style={{margin:"auto"}} className="form-control input-sm" value={option.option3} /> }
                                                </div>
                                            </div>
                                        </div>
                                    {/* </input> */}
                                </label>
                            </li>
                        </ul>

                        <div className="col-lg-12 col-12 px-2 py-3 text-left">    
                         <textarea className="form-control extra-margin" placeholder="Free Text..." rows="1" data-toggle="modal" data-target="#myModal">

                         </textarea>

                        </div>
                        <button className="btn btn-primary" onClick={submit}>submit</button>
                    </div>
      
 

                </div>
            </div>
            <div className="monument d-xl-none">
                <div className="d-flex px-3" style={{justifyContent: "space-between"}}>
                    <a className="text-secondary extra-margin"> 
                     Back </a>
                    <a className="text-secondary extra-margin"> 4/8 </a></div>
                </div>



      
        </Fragment>
    )
}
export default QuestionOne