import React, { Fragment, useState, useEffect } from 'react';

import './logicSurvey.css';
import { Link } from 'react-router-dom';
import { final } from '../finalSurvey';
const LogicSurvey = () => {
    const [selectedNext, setSelectedNext] = useState("Select Next Question")

const selectedOption = (e, question) => {

    console.log("current", e.target)
    console.log("next", question)
    // alert(e.target.value)
}
    return (
        <Fragment>
            <div className="container">
                <div className="card">
                    <div className="card card-header">
                        <h4>Survey Logic Panel</h4>
                    </div>
                    <div className="card card-body">
                        
                        {final.length > 0 ? <>
                            <div className="row">
                            <div style={{textTransform: "uppercase"}} className="col-md-4"><h5 align="center">ALL QUESTIONS</h5></div>
                            <div style={{textTransform: "uppercase"}} className="col-md-4"><h5 align="center">Select Option for next Question</h5></div>
                            <div style={{textTransform: "uppercase"}} className="col-md-4"><h5 align="center">Question After Selected Option</h5></div>
                        </div>
                            <ul class="list-group">
                                {final.map((question, index) => {
                                    return (
                                        <div className="row">
                                            <div className="col-md-12">
                                                <li class="list-group-item">
                                                    <div className="row">
                                                        <div className="col-md-4">
                                                            {index + 1} {question.que}
                                                        </div>
                                                        <div className="col-md-4">
                                                            <select onChange={()=> selectedOption(Event, question)} className="form-control">
                                                                <option disabled>If Select Option</option>
                                                                {Object.keys(question.option).map((subOptionKey, indexOption) => {
                                                                    return (
                                                                        <option value={subOptionKey}>{question.option[subOptionKey]}</option>
                                                                    )

                                                                })}

                                                            </select>
                                                        </div>
                                                        <div className="col-md-4">
                                                           
                                                            <select className="form-control">
                                                                <option>Select Next Question</option>
                                                                {final.map((nextQue, indexNext)=> {
                                                                    return (
                                                                        <option value={nextQue.que}>{nextQue.que}</option>
                                                                    )
                                                                })}

                                                            </select>
                                                        </div>
                                                    </div>
                                                </li>
                                            </div>
                                        </div>
                                    )
                                })}
                            </ul>
                        </>
                            : <>
                                <div>
                                    <h2 align="center">Survey Not Found</h2>
                                    <h5 align="center">Please Create a Survey clicking on <Link to="/createssurvey"> create survey</Link> link</h5>
                                    {/* <FinalQuestionThree/> */}
                                </div>
                            </>}


                    </div>
                </div>
            </div>
        </Fragment>
    )
}

export default LogicSurvey