import React, { Fragment, useState } from 'react';
// import { final } from './finalSurvey';
import './q1final.css';

const FinalQuestionOne = (props) => {
    let img1 = "CreateSurvey/icons/2.png"
    const { question, option } = props;
    return (
        
        <Fragment>
         {/* <!-- Blue https://codepen.io/angel_crawford/pen/pRrvbG --> */}
            <header className="fixed-top">
                <div className="header-wrap d-block text-center">
                    <img src= "CreateSurvey/images/logo.png" alt="" className="" style={{height: "50px", paddingTop: "5px"}} />
                </div>
                <div className="line-bottom"></div>
            </header>



  {/* <!-- https://simplestepscode.com/circular-progress-bar-with-css-and-color-change/  ( For Dialer Refernce ) --> */}
  <div className="min-height-body">
    <div className="">
    <div className="position-relative card-header custom-header equal-spacing extra-margin">
     <h6 style={{color:"black"}} className="f-16 mb-0 d-block mx-auto">{question} ?</h6> 
          </div>
    
   

                    <div className="container">
                        <ul className="row px-2 mb-0 text-center">
                            <li className="col-lg-4 col-12 px-2 py-2">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="1" name="A"/>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-5 col-5 center-vh custom-border-right">
                                               
            
                                                    <img for="op1" src={img1} alt="" className="mx-auto  icon-img" />
                                               
                                                    
                                                    </div>
                                            
                                            <div className="col-lg-7  col-7 center-vh">
                                         <h6 style={{color:"black"}} className="f-14 mb-0">{option.option1} </h6> 
                                       
                                                </div>
                                            </div>
                                        </div>
                                    {/* </input> */}
                                </label>
                            </li>
                            <li className="col-lg-4 col-12 px-2 py-lg-2">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="2" name="A"/>
                                        <div className="container">
                                            <div className="row">
                                                <div className="col-lg-5 col-5 center-vh custom-border-right">
                                                    <img src="CreateSurvey/images/UPI_2.svg" alt="" className="mx-auto  icon-img" />
                                                </div><div className="col-lg-7  col-7 center-vh">
                                                <h6 style={{color:"black"}} className="f-14  mb-0">{option.option2}</h6> 
                                              </div>
                                            </div>
                                        </div>
                                    {/* </input> */}
                                </label>
                            </li>

                            <li className="col-lg-4 col-12 px-2 py-2">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="3" name="A"/>
                                        <div className="container">
                                            <div className="row center-vh">
                                                <div className="col-lg-5 col-5 center-vh custom-border-right">
                                                    <img src="CreateSurvey/icons/3.png" alt="" className="mx-auto  icon-img" />
                                                                                                </div>
                                                <div className="col-lg-7 col-7 center-vh">
                                             <h6  style={{color:"black"}} className="f-14  mb-0"> {option.option3}</h6> 
                                   </div>
                                            </div>
                                        </div>
                                    {/* </input> */}
                                </label>
                            </li>
                        </ul>

                        <div className="col-lg-12 col-12 px-2 py-3 text-left">    
                         <textarea className="form-control extra-margin" placeholder="Free Text..." rows="1" data-toggle="modal" data-target="#myModal">

                         </textarea>

                        </div>
                
                    </div>
      
 

                </div>
            </div>
            <div className="monument d-xl-none">
                <div className="d-flex px-3" style={{justifyContent: "space-between"}}>
                    <a className="text-secondary extra-margin"> 
                     Back </a>
                    <a className="text-secondary extra-margin"> 4/8 </a></div>
                </div>



      
        </Fragment>
    )
}
export default FinalQuestionOne