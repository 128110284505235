import React, { Fragment, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import solidGauge from "highcharts/modules/solid-gauge.js";
import highchartsMore from "highcharts/highcharts-more.js"

highchartsMore(Highcharts);
solidGauge(Highcharts);


const NpsScoreChart = (props) => {

    const {applicationUsageTitle,applicationUsageScore} = props;
    var options = {
        chart: {
            type: 'solidgauge',
            height:380,
        },
    
        title: '',
    
        pane: {
            center: ['50%', '85%'],
            size: '100%',
            startAngle: -90,
            endAngle: 90,
            background: {
                backgroundColor:
                    Highcharts.defaultOptions.legend.backgroundColor || '#EEE',
                innerRadius: '60%',
                outerRadius: '100%',
                shape: 'arc'
            }
        },
    
        exporting: {
            enabled: false
        },
    
        tooltip: {
            enabled: false
        },
    
        // the value axis
        yAxis: {
            stops: [
                [0.1, '#55BF3B'], // green
                [0.5, '#DDDF0D'], // yellow
                [0.9, '#DF5353'] // red
            ],
            lineWidth: 0,
            tickWidth: 0,
            minorTickInterval: null,
            tickAmount: 2,
            title: {
                y: 50
            },
            labels: {
                y: 16
            }
        },
    
        plotOptions: {
            solidgauge: {
                dataLabels: {
                    y: 5,
                    borderWidth: 0,
                    useHTML: true
                }
            }
        },
        yAxis: {
            min: -100,
            max: 100,
            title: {
                text: `${applicationUsageTitle} ${'Users NPS Score'}`,
                y : -95
            },
          
        },
    
        credits: {
            enabled: false
        },
    
        series: [{
            name: 'Nps Score',
            data: [applicationUsageScore],
            dataLabels: {
                format:
                    '<div style="text-align:center">' +
                    '<span style="font-size:25px">{y}</span><br/>' +
                    '<span style="font-size:12px;opacity:0.4">NPS Score</span>' +
                    '</div>'
            },
            tooltip: {
                valueSuffix: ''
            }
        }]
    }
    
    return (
                    <HighchartsReact
                        highcharts={Highcharts}
                        options={options}
                    />
    );
}

export default NpsScoreChart;