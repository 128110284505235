export default {
    development: {
      // site_api: 'http://localhost:3002',
      site_api: 'http://103.231.8.86:3008', 
      // site_api: 'https://npci.surveycxm.com:3000',
    },
    production: {
      // site_api: 'https://npci.surveycxm.com:3000',
      site_api: 'http://103.231.8.86:3008', 
      
    },
  };