import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Sankey from "highcharts/modules/sankey.js"
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { get } from 'lodash'
import CommentModel from '../../common/commentModel';
import { Loader } from '../../common/loader'

Sankey(Highcharts);
const CategoriesDepenciesChart = (props) => {

    const api = new API()

// CatSubCategories //
const [catSubCategoriesResultData, setCatSubCategoriesResultData] = useState({})
const [open,setOpen] = useState(false);
const [isLoad, setIsLoad] = useState(true);
useEffect(() => {
    catSubCategories()
  }, [])

const catSubCategories = async()=>{
    let catSubCategoriesResult = await api.get(config.dashboardCatSubCategories);
    let catSubCategoriesResultData = get(catSubCategoriesResult,'data',{})
    setCatSubCategoriesResultData(catSubCategoriesResultData)
    setIsLoad(false)
    // alert(catSubCategoriesResultData)
  }


const options = {
    chart: {
        height:550,
        width:1300,
    },

title: {
    text: ''
},

accessibility: {
    point: {
        valueDescriptionFormat: '{index}. {point.from} to {point.to}, {point.weight}.'
    }
},
exporting: {
    enabled: false
  },
series: [{
    keys: ['from', 'to', 'weight'],
    data: catSubCategoriesResultData,
    events: {
        click:function (event) {
          handleModel(event)
      }
    }
    //[
        // ['NPCI', 'UPI', 5],
        // ['NPCI', 'RuPay', 1],
        // ['NPCI', 'FASTag', 1],
       
        // ['UPI', 'Application', 2],
        // ['UPI', 'Service', 1],
        // ['UPI', 'Suggestion', 1],
        // ['UPI', 'Support', 3],
        // ['RuPay', 'Application', 1],
        // ['RuPay', 'Service', 3],
        // ['RuPay', 'Transaction', 3],
        // ['RuPay', 'Suggestion', 3],
        // ['RuPay', 'Support', 1],
        // ['FASTag', 'Service', 1],
        // ['FASTag', 'Suggestion', 3],
        // ['FASTag', 'Suppor', 1],
       
        // ['Support', 'China', 5],
        // ['Support', 'India', 1],
        // ['Support', 'Japan', 3],
        // ['Application', 'China', 5],
        // ['Application', 'India', 1],
        // ['Application', 'Japan', 3],
        // ['Service', 'China', 5],
        // ['Service', 'India', 1],
        // ['Service', 'Japan', 3],
        // ['Transaction', 'China', 5],
        // ['Transaction', 'India', 1],
        // ['Transaction', 'Japan', 3],
        // ['Suggestion', 'China', 5],
        // ['Suggestion', 'India', 1],
        // ['Suggestion', 'Japan', 3]
   // ],
    ,type: 'sankey',
    name: ''
}]

}
const handleModel=()=>{
    setOpen(!open)
  }

    return ( 
        <Fragment>
            { isLoad == true ? <> 
                    <Loader/>
                    </> : <> 

        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            catSubCategoriesResultData={catSubCategoriesResultData}
            
        />
        </>}
        <div style={{display:"none"}}><CommentModel  handleModel={handleModel} open={open}/>
        </div>
        </Fragment>
     );
}
 
export default CategoriesDepenciesChart;