import React from 'react'
import { useIdleTimer } from 'react-idle-timer';
import { useHistory } from 'react-router';

import { withCookies } from 'react-cookie';

const SESSION_IDEL_MINUTES = 5;

const AutoLogout = (props) => {
    const { ComposedClass,cookies } = props
    const history = useHistory()

    const handleOnIdle = (event) => {
        // SHOW YOUR MODAL HERE AND LAGOUT
        // console.log('user is idle', event)
        if (event == false || getLastActiveTime) {
        cookies.remove("token")
      
        history.push("/")
        }
    }

    const {getLastActiveTime } = useIdleTimer({
        timeout: 1000 * 60 * SESSION_IDEL_MINUTES,
        onIdle: handleOnIdle,
    })

    return <ComposedClass />
}

export default withCookies(AutoLogout);