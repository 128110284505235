import React, {Component, Fragment} from 'react';
import CreateNewSurveyCommon from './common/createNewSurveyCommon';


const CreateNewSurvey = () => {
    return (
        <div className="site-wrap" id="page-content-wrapper">
            
            <CreateNewSurveyCommon/>
        </div>
    )
}

export default CreateNewSurvey