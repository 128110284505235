import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Dumbbell from "highcharts/modules/dumbbell.js"
import exporting from 'highcharts/modules/exporting';
import heatmap from 'highcharts/modules/heatmap';





heatmap(Highcharts);
exporting(Highcharts);
Dumbbell(Highcharts);


const DumbbleChart = (props) => {
 const {appUseComparisionResultData,Name}= props
 
 
 var data = [{
    name: ['Loan'],
    low: 0,
    high: 50
}, {
    name: 'Support',
    low: 40,
    high: 81
}, {
    name: 'Process',
    low: 80,
    high: 75
}, {
    name: 'Transaction',
    low: 100,
    high: 78
}, {
    name: 'Application',
    low: 120,
    high: 81
}];


const options = {
    chart: {
        type: 'dumbbell',
        inverted: true
    },

    legend: {
        enabled: false
    },

    subtitle: {
        text: ''
    },

    title: {
        text: 'Key Drivers Fluctuation'
    },

    tooltip: {
        shared: true
    },

    xAxis: {
        type: 'category'
    },

    yAxis: {
        title: {
            text: ''
        }
    },

    series: [{
        name: 'Life expectancy change',
        data: data
    }]

}

    return ( 
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        />
     );
}
 
export default DumbbleChart;