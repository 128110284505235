import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Dumbbell from "highcharts/modules/dumbbell.js"
import exporting from 'highcharts/modules/exporting';
import heatmap from 'highcharts/modules/heatmap';





heatmap(Highcharts);
exporting(Highcharts);
Dumbbell(Highcharts);


const BubbleChart = (props) => {
 const {appUseComparisionResultData,Name}= props
 
 
 


const options = {
    chart: {
        type: 'packedbubble',
        height: 550
    },
    title: {
        text: 'Key Sentiment'
    },
    tooltip: {
        useHTML: true,
        pointFormat: '<b>{point.name}:</b> {point.value} '
    },
    plotOptions: {
        packedbubble: {
            minSize: '30%',
            maxSize: '120%',
            zMin: 0,
            zMax: 1000,
            layoutAlgorithm: {
                splitSeries: false,
                gravitationalConstant: 0.02
            },
            dataLabels: {
                enabled: true,
                format: '{point.name}',
                filter: {
                    property: 'y',
                    operator: '>',
                    value: 250
                },
                style: {
                    color: 'black',
                    textOutline: 'none',
                    fontWeight: 'normal'
                }
            }
        }
    },
    series: [{
        name: 'Transaction',
        data: [{
            name: 'Support',
            value: 270
        }, {
            name: 'Application',
            value: 300.7
        },
        {
            name: "Loan",
            value: 450.2
        },
        {
            name: "Process",
            value: 270.7
        },
        {
            name: "Service",
            value: 350.1
        },
        {
            name: "Transaction",
            value: 250.2
        }]
    }, {
        name: 'Transaction',
        data: [{
            name: "Support",
            value: 400.2
        },
        {
            name: "Service",
            value: 350.2
        },
       
        {
            name: "Loan",
            value: 300.1
        },
        {
            name: "Process",
            value: 260.1
        },
    {
        name: "Application",
        value: 250.1
    }]
    },]}



    return ( 
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            
        />
     );
}
 
export default BubbleChart;