import { get } from 'lodash';
import React, { useEffect, useState } from 'react';
import DatamapsIndia from 'react-datamaps-india'




const IndiaMapChart = (props) => {

  const {statesMap} = props;
  const [popUp,setPop] = useState('');



    return ( 
  
      <DatamapsIndia 
      regionData={
        statesMap
      }
     hoverComponent={({ value }) => {
     
              return (
                <>
                  <p>{get(value,"name","")}</p>
                  <p>NPS Score : {get(value,"value",0)}</p>
                  <p>Promoter Score: {get(value,"total_permoter",0)}</p>
                  <p>Passive Score: {get(value,"total_passive",0)}</p>
                  <p>Detractor Score: {get(value,"total_detractor",0)}</p>
                  <p>Cities: {get(value,"cities","")}</p>
                </>
              );
            }}
      mapLayout={{
        title: '',
        // legendTitle: 'Legend Title',
        startColor: '#f96d6d',
        endColor: '#77f96d',
        hoverTitle: 'Count',
        noDataColor: '#f5f5f5',
        borderColor: '#8D8D8D',
        hoverBorderColor: '#8D8D8D',
        hoverColor: '#add8e6'
      }}
    />
     );
}
 
export default IndiaMapChart;