import React, { useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'



const RatingWiseResponse = (props) => {

    const {name,width,ratingWiseNpsScores,ratingWiseNpsScoresPercentage} = props
console.log("ratingWiseNpsScores",ratingWiseNpsScores);
const options = {
    chart: {
        //   zoomType: 'xy'
    },
    title: {
        text: ''
    },
    exporting: {
        enabled: false
      },
    subtitle: {
        text: ''
    },
    credits: {
        enabled: false
    },
    xAxis: [{
        name:'NPS Score',
            categories: [0,1,2,3,4,5,6,7,8,9,10],
            crosshair: true,
        }],
        colors: [
            '#DF5353', // red
            '#DF5353', // red
            '#DF5353', // red
            '#DF5353', // red
            '#DF5353', // red
            '#DF5353', // red
            '#DF5353', // red
            '#DDDF0D', // yellow
            '#DDDF0D', // yellow
            '#55BF3B',// green
            '#55BF3B',// green
            ],
            
            plotOptions: {
                column: {
                    colorByPoint: true
                }
            },
    yAxis: [{// Primary yAxis
            labels: {
                enabled: false,
                format: '{value}',
                style: {
                    color: Highcharts.getOptions().colors[1]
                }
            },
            
            title: {
                text: '',
                style: {
                    color: Highcharts.getOptions().colors[1]
                }
            }
        }, {// Secondary yAxis
            title: {
                text: '',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            labels: {
                enabled: false,
                format: '{value}',
                style: {
                    color: Highcharts.getOptions().colors[0]
                }
            },
            opposite: true
        }],
    tooltip: {
        shared: true
    },
    legend: {
        enabled: false,
        layout: 'vertical',
        align: 'left',
        x: 10,
        verticalAlign: 'top',
        y: 10,
        floating: true,
        backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || // theme
                'rgba(255,255,255,0.25)'
    },
    series: [{
            name: 'Responses',
            type: 'column',
            yAxis: 1,
            data: ratingWiseNpsScores,
            tooltip: {
                valueSuffix: ' '
            },
            dataLabels: {
                enabled: true,
                //  rotation: -90,
                color: '#FFFFFF',
                align: 'center',
                //format: '{point.y}',
                formatter: function () {
                    if (this.y != 0) {
                        return this.y;
                    }
                },
                // one decimal
                y: 50, // 10 pixels down from the top
                style: {
                    fontSize: '11px',
                    fontFamily: 'Verdana, sans-serif'
                }
            }


        }, {
            name: '% Response',
            type: 'spline',
            
            marker: {
                
                symbol: 'square'
            },
            data: ratingWiseNpsScoresPercentage,
            tooltip: {
                valueSuffix: ' %'
            },
           
            dataLabels: {
                
                enabled: true,
                // y: 100,
                formatter: function () {
                    if (this.y != 0) {
                        return this.y + '';
                    }
                }

            },
        }]

 }

    return ( 
        <HighchartsReact 
            highcharts={Highcharts}
            options={options}
            
        />
     );
}
 
export default RatingWiseResponse;