import React, { Component, Fragment } from 'react'
import {DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import DragDrop from '../common/dragDrop'
import HorizontalBarChart from '../common/HorizontalBarChart'
import HorizontalStackedBarChart from '../common/HorizontalStackedBarChart'
import BarChart from '../common/barChart'
import StackedBarChart from '../common/StackedBarChart'
import LineChart from './LineChart';
import AreaChart from './AreaChart';
import ScatterChart from './ScatterChart';

// const columsFromBackend = [
//     { id: uuid(), content: 'first task' },
//     { id: uuid(), content: 'second task'}
// ];
// const columnsF



class ReportAnalysisCommon extends React.Component {
    constructor() {
        super()
   
    this.state = {
        chart : '',
        horizontalBarChart : false,
        horizontalStackedBarChart : false,
        barChart : false,
        stackedBarChart : false,
        lineChart : false,
        areaChart : false,
        scatterChart : false,
         Data : [
            {id: 1, value : "comments"},
            {id: 2, value : "last_name"},
            {id: 3, value : "first_name"},
            {id: 4, value : "rating"},
            {id: 5, value : "state"},
            {id: 6, value : "zone"},
            {id: 7, value : "tag_event_date"},
            {id: 8, value : "tag_brand_name"},
            {id: 9, value : "partner"},
            {id: 10, value : "tag_opportunity_id"},
            {id: 11, value : "tag_loan_application_id"}
        ]
        
      
    }
    this.selectChart = this.selectChart.bind(this)
}
    selectChart = (event) =>{
        alert("selected chart is", event.target.value)
        if(event.target.value == "barChart"){
            this.setState ({ 
                [event.target.value] : true,
                horizontalBarChart : false,
                horizontalStackedBarChart : false,
                stackedBarChart : false,

                })
            }   
        if(event.target.value == "horizontalBarChart"){
            this.setState ({ 
                [event.target.value] : true,
                horizontalStackedBarChart : false,
                stackedBarChart : false,
                barChart : false

                })
            }  
        if(event.target.value == "horizontalStackedBarChart"){
            this.setState ({ 
                [event.target.value] : true,
                horizontalBarChart : false,
                barChart : false,
                stackedBarChart : false,

                })
            }  
        if(event.target.value == "stackedBarChart"){
            this.setState ({ 
            [event.target.value] : true,
            horizontalBarChart : false,
            horizontalStackedBarChart : false,
            barChart : false,

                })
            }
        if(event.target.value == "lineChart"){
            this.setState ({ 
            [event.target.value] : true,
            horizontalBarChart : false,
            horizontalStackedBarChart : false,
            barChart : false,
            stackedBarChart : false,
    
                })
            }
            if(event.target.value == "areaChart"){
                this.setState ({ 
                [event.target.value] : true,
                horizontalBarChart : false,
                horizontalStackedBarChart : false,
                barChart : false,
                stackedBarChart : false,
                lineChart : false,
        
                    })
                }
            if(event.target.value == "scatterChart"){
                this.setState ({ 
                [event.target.value] : true,
                horizontalBarChart : false,
                horizontalStackedBarChart : false,
                barChart : false,
                stackedBarChart : false,
                lineChart : false,
                areaChart : false
        
                    })
                }
    }

   
    render(){
      
return (
<Fragment >
<div style={{ marginLeft: "1.5%" }} >
<div className="container-fluid">
<div className="row" style={{width:"99.5%"}}>
<div className="col-md-12 mt-3">
<div className="card">
    <div className="card-header">
        <h5 style={{textAlign:"left"}}>Report Analysis</h5>
        <div style={{textAlign:"left"}} className="card-body">
        <div className="row container-fluid">
            <div className="col-md-4">
                <select className="form-control" onChange={this.selectChart}>
                    
                    {/* <option>Table</option> */}
                    {/* <option>Table Barchart</option> */}
                    {/* <option>Heatmap</option> */}
                    {/* <option>Row Heatmap</option> */}
                    {/* <option>Col Heatmap</option> */}
                    <option value="horizontalBarChart">Horizontoal Bar Chart</option>
                    <option value="horizontalStackedBarChart">Horizontal Stacked Bar Chart</option>
                    <option value="barChart">Bar Chart</option>
                    <option value="stackedBarChart">Stacked Bar Chart</option>
                    <option value="lineChart"> Line Chart</option>
                    <option value="areaChart">Area Chart</option>
                    <option value="scatterChart">Scatter option</option>
                    {/* <option>Treeoptionption</option> */}
                    {/* <option>TSV</option> */}
                </select>
                <div className="content" style={{marginTop:"30px"}}>
                    {/* <DragDropContext onDropEnd={result => console.log(result)}> */}
                    {/* <DragDropContext> 
                   
                    <Droppable
                    droppableId="droppable"
                    renderClone={this.renderItem}
                    >
                    <div ref={provided.innerRef} {...provided.droppableProps}>
                        {this.state.Data.map((item, index)=> (
                        <Draggable draggableId={item.id} index={index}>
                            {renderItem}
                        </Draggable>
                        ))}
                    </div>
                    </Droppable>
                    </DragDropContext> */}
                <DragDrop></DragDrop>
                    
                    

                </div>
                </div>
                <div className="col-md-4">
                    <select className="form-control">
                        <option>count</option>
                        <option>Count Unique Value</option>
                        <option>List Unique Value</option>
                        <option>Sum</option>
                        <option>Integer Sum</option>
                        <option>Average</option>
                        <option>Mediam</option>
                        <option>Sample Variance</option>
                        <option>Sample Standard Deviation</option>
                        <option>Minium</option>
                        <option>Miximum</option>
                        <option>First</option>
                        <option>Last</option>
                        <option>Sum Over Sum</option>
                        <option>80% Upper Bound</option>
                        <option>80% Lower Bound</option>
                        <option>Sum as Fraction Of Total</option>
                        <option>Sum as Fraction Of Rows</option>
                        <option>Sum as Fraction Of Colums</option>
                        <option>Count as Fraction Of Total</option>
                        <option>Count as Fraction Of Row</option>
                        <option>Count as Fraction Of Colums</option>

                    </select>
                </div>
                <div className="col-md-4">
                    <select className="form-control">
                    <option>rating</option>
                    </select>

                    { this.state.horizontalBarChart == true ? <HorizontalBarChart/> : <h2></h2> }
                    { this.state.horizontalStackedBarChart == true ? <HorizontalStackedBarChart/> : <h2></h2> }
                    { this.state.barChart == true ? <BarChart/> : <h2></h2> }
                    { this.state.stackedBarChart == true ? <StackedBarChart/> : <h2></h2> }
                    { this.state.lineChart == true ? <LineChart/> : <h2></h2>}
                   { this.state.areaChart == true ? <AreaChart/> : <h2></h2>}
                    {this.state.scatterChart == true ? <ScatterChart/> : <h2></h2>}
                  

                    <div>
                       
                    </div>
                    
                    <div>
                        
                    </div>

                </div>
            </div>
        </div>
    </div>
</div>
</div>
</div>
</div>
</div>


</Fragment>
)
    }
}
export default ReportAnalysisCommon;