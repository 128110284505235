import React, { Fragment, useState } from 'react';
import FeedbackAndSuggestion from './surveyFile/feedbackAndSuggestion';
import QuestionOne from './surveyFile/q1';
import QuestionTwo from './surveyFile/q2';
import QuestionThree from './surveyFile/q3';
import { Link } from 'react-router-dom';

const CreateNewSurveyCommon =() => {
   const [id, setId] = useState(1)


   const next=()=>{
    if(id<3){
        setId(id+1)
    }
    
   }
   const previous=()=>{
    if(id>1){
        setId(id-1)
    }
   }
   const reset = ()=>{
       alert("reset")
   }
   const submit = () =>{
       alert("submit")
   }
    return (
        <Fragment>
            <div className="container-fluid">
                
                <div className="card" style={{backgroundColor:"white", minHeight:"700px"}}>  
                <h3 align="center" style={{marginTop:"2%"}}>Chose type of question one by one</h3>
                <div align="right">
                <Link to="/createSurvey"><button className="btn">Create Survey</button></Link>
                <Link to="/logicSurvey"><button className="btn">Survey Logic</button></Link>
                <Link to="/editSurvey"><button className="btn">Edit Survey</button></Link>
                <Link to="/finalSurvey"><button className="btn">View Survey</button></Link>
                    </div>
                        
                        {id == 1 && <div><QuestionOne/></div>  }
                        {/* {id == 2 && <div><QuestionTwo/></div>  } */}
                        {id == 2 && <div><QuestionThree/></div>  }
                        {id == 3 && <div><FeedbackAndSuggestion/></div> }
                        
                        <div className="footer">
                        <button  onClick={previous} className="btn btn-primary btn-small">Previous</button> 
                        <button onClick={reset} className="btn btn-primary btn-small">Reset</button>
                        <button onClick={submit} className="btn btn-primary btn-small">Submit</button>
                        <button onClick={next} className="btn btn-primary btn-small">Next</button>

                        </div>
                </div>
            </div>
                   
        </Fragment>
    )
}
export default CreateNewSurveyCommon