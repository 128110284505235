import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import './createSurvey.css';
import { Draggable, Droppable } from 'react-drag-and-drop'
import DragAndDrop from './dragAndDrop';


const CreateSurvey = (props) => {
   
    const [addMoreOption, SetAddMoreOption] = useState([1])

    // let addMoreOptionVar = [1]

    const getAll = () => {
       
    }
    useEffect(()=> {
        getAll()
    },[])

    const AddMoreOption = () => {
        let addMoreOptionCount = addMoreOption[addMoreOption.length - 1]
        SetAddMoreOption([...addMoreOption,  addMoreOptionCount+1])
        console.log(addMoreOption)
    }
    const RemoveMoreOption = () => {
        const temp = [...addMoreOption]
        if(addMoreOption.length-1>0){
        temp.splice(addMoreOption.length-1, 1)
        SetAddMoreOption(temp)
        console.log(addMoreOption)
    }
    else{
        alert("can't remove")
    }
    }


 

    return (
        
        <Fragment>
        {console.log(addMoreOption)}
           <div className="container">
               <div className="card">
               <div className="card card-header">
                    <h5>Create Survey Panel</h5>    
                </div>
                <div className="card card-body">
                    <div className="row">
                        <div className="col-md-2">
                            <div className="col-md-12 createSurveyContainer">
                           
                            <h5 style={{marginTop:"3%"}} align="center">Select Items</h5>
                                <ul class="list-group">
                                <li class="list-group-item">Input Text</li>
                                <li class="list-group-item">Radio Button</li>
                                <li class="list-group-item">Check Box</li>
                                <li class="list-group-item">Yes/No</li>
                                <li class="list-group-item">Item 5</li>
                                <li class="list-group-item">Item 6</li>
                                <li class="list-group-item">Item 7</li>
                                </ul>
                         
                            </div>
                        </div>
                        <div className="col-md-8">
                            <div className="col-md-12 createSurveyContainer" >
                                <div className="container" style={{marginTop:"3%"}}>
                                    <h5 align="center"> Create Survey Questions & options </h5>
                            <form>
                                <div class="form-group">
                                    <label className="labelClass" for="question">Type Question</label>
                                    <input type="text" class="form-control" id="question" aria-describedby="emailHelp" placeholder="Enter Question"/>
                                    <small id="emailHelp" class="form-text text-muted">Type your question here.</small>
                                </div>
                                
                               
                                    {addMoreOption.map((opt, index) => {
                                        return (<div class="form-group">
                                        <label className="labelClass" for="question">Type Option {index+1}</label>
                                        <input type="text" class="form-control" id="question" aria-describedby="emailHelp" placeholder="Enter Option"/>
                                </div>
                                    )})}
                                   

                                
                                <button style={{margin:"5px"}} type="button" onClick={AddMoreOption} class="btn rounded-pill border-primary">Add Option</button>
                                <button style={{margin:"5px"}} type="button" onClick={RemoveMoreOption} class="btn rounded-pill border-danger">Remove Option</button>
                                <button style={{margin:"5px"}} type="submit" class="btn rounded-pill border-primary">Submit</button>
                                </form>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-2">
                        <div className="col-md-12 createSurveyContainer">
                                {/* <DragAndDrop/> */}
                                </div>
                        </div>
                    </div>    
                </div>    
               </div>
               
           </div>
        </Fragment>
    )
}
export default CreateSurvey