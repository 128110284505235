import React, {Component, Fragment} from 'react';
import SentimentAnalysisCommon from './common/sentimentAnalysisCommon'

const SentimentAnalysis = () => {
    return (
        <div className="App site-wrap">
            <SentimentAnalysisCommon/>
        </div>
    )
}
export default SentimentAnalysis