import React, { Component } from "react";
import ReactDOM from "react-dom";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Timeline from "highcharts/modules/timeline.js"


// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// fake data generator

Timeline(Highcharts);


const HorizontalStackedBarChart = () =>  {
  
    const options =  {
        chart: {
            type: 'bar'
        },
        title: {
            text: 'Stacked bar chart'
        },
        xAxis: {
            categories: [1,2,3,4,5,6,7]
        },
        yAxis: {
            categories: [1,2,3,4,5,6,7,8,9,10, "totals"],
            // min: 0,
            title: {
                text: 'Rating Wise Score'
            }
        },
        legend: {
            reversed: true
        },
        plotOptions: {
            series: {
                stacking: 'normal'
            }
        },
        series: [{
            name: 'rating',
            data: [5, 3, 4, 7, 2, 10]
        // }, {
        //     name: 'Jane',
        //     data: [2, 2, 3, 2, 1]
        // }, {
        //     name: 'Joe',
        //     data: [3, 4, 4, 2, 5]
        }]
    }
  
    return (
       
      <div>
          <h4>HorizontalStacked Bar Chart</h4>
          <HighchartsReact
              highcharts={Highcharts}
              options= {options} >
          </HighchartsReact>
      </div>
   
    );
  
}

// Put the thing into the DOM!
export default HorizontalStackedBarChart