import React, { useEffect } from 'react';
// import logo from './logo.svg';
// import './user-management.css';
import DefaultHeader from '../../common/defaultHeader';
import UserManagementCommon from './common/userManagementCommon';

const UserManagement=(props)=> {
 useEffect(() => {
//   props.setBreadcrumb({label:'User Management', link:'/userManagement'})
 },[])
  return (
    <div className="App site-wrap" >
    
    <UserManagementCommon/>
    </div>
  );
}

export default UserManagement;
