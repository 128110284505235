import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import exporting from 'highcharts/modules/exporting';





exporting(Highcharts);


const CircularPieChart = (props) => {
 
 
 


const options = {
    chart: {
        type: 'pie',
        height:400
      },
      title: {
        text: ''
      },
      subtitle: {
        text: `<div>80%</div> of Total`,
        align: "center",
        verticalAlign: "middle",
        style: {
          "fontSize": "18px",
          "textAlign": "center"
        },
        x: 0,
        y: 33,
        useHTML: true
      },
      plotOptions: {
        pie: {
          shadow: false,
          center: ["50%", "50%"],
          dataLabels: {
            enabled: false
          },
          states: {
            hover: {
              enabled: false
            }
          },
          size: "45%",
          innerSize: "95%",
          borderColor: null,
          borderWidth: 8
        }
    
      },
      tooltip: {
        valueSuffix: '%'
      },
      series: [{
        type: 'pie',
        enableMouseTracking: false,
        innerSize: '80%',
        dataLabels: {
          enabled: false
        },
        data: [{
          y: 10,
          color: '#e3e3e3'
        }, {
          y: 90,
          color: '#28a745'
        }]
      }]
    }



    return ( 
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            
        />
     );
}
 
export default CircularPieChart;