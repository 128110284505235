import React, { useEffect, useState } from 'react';
import './productUpiBasicDetail.js'
import BasicDetailChart from '../../charts/basicDetailChart.js';
import UpiQuestionAndResponse from '../common/UpiQuestionAndResponse';
import RupayQuestionAndResponse from '../common/RupayQuestionAndResponse';
import FastTageQuestionAndResponse from '../common/FastTagQuestionAndResponse';
import RatingWiseResponse from '../../charts/ratingWiseResponse.js';
import { get } from 'lodash';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import  ModalPartner  from '../../../common/modalForPartner';
import Config from '../../../config';
import { Loader } from '../../../common/loader'

import {dateFilter,lastMonth,currentMonth,currentDay, Yesterday} from '../../../utils/common';
import PieChart from '../../charts/pieChart';

import Skeleton from 'react-loading-skeleton';

const ProductUpiBasicDetail =(props)=> {

const {upiBasic,rupayBasic,fastagBasic,bhimBasic,netcBasic,basicDetailUpi,basicDetailRupay,basicDetailFastag,basicDetailBhim,basicDetailNetc,filterObj,filterApply,
   bhimShow,setBhimShow,netcShow,setNetcShow,upiShow,setUpiShow,setRupayShow,setFastagShow,rupayShow,fastagShow} = props;
let product = get(filterObj,'product',[{id:1},{id:2},{id:3},{id:4},{id:5}]) 
product = product.length===0 ?  [{id:1},{id:2},{id:3},{id:4},{id:5}] : product

const api = new API()

const [tabs, setTabs] = useState("Today")
const [tabsRupay, setTabsRupay] = useState("Today1")
const [tabsFastag, setTabsFastag] = useState("Today2")
const [tabsBhim, setTabsBhim] = useState("Today3")
const [tabsNetc, setTabsNetc] = useState("Today4")
const [upiPartner, setUpiPartner] = useState(null)
const [rupayPartner, setRupayPartner] = useState(null)
const [fastagPartner, setFastagPartner] = useState(null)
const [bhimPartner, setBhimPartner] = useState(null)
const [netcPartner, setNetcPartner] = useState(null)
const [loader, setLoader] = useState(true)
const [parameterArr, setParameterArr] = useState([])
const [parameterArrRupay, setParameterArrRupay] = useState([])
const [parameterArrFastag, setParameterArrFastag] = useState([])
const [dpp, setDpp] = useState([])
const [dppRupay, setDppRupay] = useState([])
const [dppFastag, setDppFastag] = useState([])
const [piechart, setPieChart] = useState(false)
const [more,setMore] = useState(false);
const [more1,setMore1] = useState(false);
const [more2,setMore2] = useState(false);
const [more3,setMore3] = useState(false);


// Question&Response //
const [questionAndResponseUpi, setQuestionAndResponseUpi] = useState([])
const [noMoreUpi,setNoMoreUpi] = useState(null)

const [questionAndResponseRupay, setQuestionAndResponseRupay] = useState([])
const [noMoreRupay,setNoMoreRupay] = useState(null)

const [questionAndResponseFastag, setQuestionAndResponseFastag] = useState([])
const [noMoreFastag,setNoMoreFastag] = useState(null)

// RatingWiseNpsScore //
const [ratingWiseNpsScoresUpi, setRatingWiseNpsScoresUpi] = useState([])
const [ratingWiseNpsScoresFastag, setRatingWiseNpsScoresFastag] = useState([])
const [ratingWiseNpsScoresRupay, setRatingWiseNpsScoresRupay] = useState([])
const [ratingWiseNpsScoresBhim, setRatingWiseNpsScoresBhim] = useState([])
const [ratingWiseNpsScoresNetc, setRatingWiseNpsScoresNetc] = useState([])


// RatingWiseNpsScore pie chart //
const [ratingWiseNpsScoresPieUpi, setRatingWiseNpsScoresPieUpi] = useState([])
const [ratingWiseNpsScoresPieFastag, setRatingWiseNpsScoresPieFastag] = useState([])
const [ratingWiseNpsScoresPieRupay, setRatingWiseNpsScoresPieRupay] = useState([])
const [ratingWiseNpsScoresPieBhim, setRatingWiseNpsScoresPieBhim] = useState([])

// chart  update more //
const [chartsUpiMore, setChartsUpiMore] = useState([]);
const [chartsRupayMore, setChartsRupayMore] = useState([]);
const [chartsFastagMore, setChartsFastagMore] = useState([]);

const [ skeltonLoader1 ,setSkeltonLoader1] = useState(false)
const [ skeltonLoader2,setSkeltonLoader2] = useState(false)
const [ skeltonLoader3 ,setSkeltonLoader3] = useState(false)
const [ skeltonLoader4 ,setSkeltonLoader4] = useState(false)
const [ skeltonLoader5 ,setSkeltonLoader5] = useState(false)


// let loaderset = true
// if(upiPartner != null){
   // setLoader(false)
// }



// life cycle //
  useEffect(() => {
   PartnerWiseScoreUpi({product_id:1,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   PartnerWiseScoreRupay({product_id:2,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   PartnerWiseScoreFastag({product_id:3,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   PartnerWiseScoreBhim({product_id:4,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   PartnerWiseScoreNetc({product_id:5,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   dppChart()
   dppChartRupay()
   dppChartFastag()
   questionAndResUpi()
   questionAndResRupay()
   questionAndResFastag()
   ratingWiseNpsScoreUpi({product:1,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   ratingWiseNpsScoreRupay({product:2,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   ratingWiseNpsScoreFastag({product:3,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   ratingWiseNpsScoreBhim({product:4,start_date:currentDay('start_date'),end_date:currentDay('end_date')})

   ratingWiseNpsScorePieUpi({product:1,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   ratingWiseNpsScorePieRupay({product:2,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   ratingWiseNpsScorePieFastag({product:3,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   ratingWiseNpsScorePieBhim({product:4,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   ratingWiseNpsScoreNetc({product:5,start_date:currentDay('start_date'),end_date:currentDay('end_date')})
   
  }, [])


  const questionAndResUpi = async ()=>{
   let questionAndResponseUpiResult = await api.get(config.dashboardQuestionAndResponseUpi,);
    let questionAndResponseData =  questionAndResponseUpiResult.data
    if(questionAndResponseData && Array.isArray(questionAndResponseData)){
    const arrayData =  Array.isArray(questionAndResponseData) ? questionAndResponseData[0].options : [];
    setParameterArr(arrayData)
    setQuestionAndResponseUpi(questionAndResponseData);
    setLoader(false)
    }

 }


 const questionAndResUpis = async (product_id,rating_type,qid,title,stateName)=>{
   let concatArray = []
   if(qid){
      let arrayIndex = null;

     questionAndResponseUpi.map((data,index) => {if(data.qid==qid) arrayIndex = index})

     if(arrayIndex || arrayIndex === 0){

       if(arrayIndex=== 0 ){
        concatArray.push(questionAndResponseUpi[0]);
       }else{
        for(let i=0;i<arrayIndex; i++){
           //
           concatArray.push(questionAndResponseUpi[i]);
           
        }
       }
       
       
     }
     
   }
   let questionAndResponseUpiResults;
   if(title === 'ratingType'){

      // questionAndResponseUpiResults = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=${product_id }&rating_type=${rating_type }`);

     questionAndResponseUpiResults = await api.get(`${Config.site_api}/dashboard/questionAndResponse`,true,{product_id,rating_type,qid});
     setLoader(false)
   } else  {
      questionAndResponseUpiResults = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=1`);
      setLoader(false)
   }
   let questionAndResponseDataUpi = questionAndResponseUpiResults.data
   console.log(questionAndResponseDataUpi,Array.isArray(questionAndResponseDataUpi),'questionAndResponseDataUpi');
  if(questionAndResponseDataUpi && Array.isArray(questionAndResponseDataUpi)){
     const chartsMoreOptionsUpi = questionAndResponseDataUpi;
  const arrayDatasUpi =  Array.isArray(chartsMoreOptionsUpi) ?  questionAndResponseDataUpi.length > 0 ?  questionAndResponseDataUpi[0].options : [] : [];
  setParameterArr(arrayDatasUpi)
  
  setChartsUpiMore(chartsMoreOptionsUpi);
  
//   [stateName](questionAndResponseDataUpi)
if(questionAndResponseDataUpi && questionAndResponseDataUpi.length !==0){
   
   let finalArray =concatArray.concat(questionAndResponseDataUpi)
   setQuestionAndResponseUpi(finalArray)
   setNoMoreUpi(null)
   }else{
      setNoMoreUpi('No Record Found')
   }
}
  
    setLoader(false)
 }


 const questionAndResRupay = async ()=>{
   let questionAndResponseRupayResult = await api.get(config.dashboardQuestionAndResponseRupay);
    let questionAndResponseData = questionAndResponseRupayResult.data
    setQuestionAndResponseRupay(questionAndResponseData)
  
 }
 const questionAndResRupays = async (product_id,rating_type,qid,title)=>{
    let concatArray = []
    if(qid){
       let arrayIndex = null;

      questionAndResponseRupay.map((data,index) => {if(data.qid==qid) arrayIndex = index})

      if(arrayIndex || arrayIndex === 0){

        if(arrayIndex=== 0 ){
         concatArray.push(questionAndResponseRupay[0]);
        }else{
         for(let i=0;i<arrayIndex; i++){
            //
            concatArray.push(questionAndResponseRupay[i]);
            
         }
        }
        
        
      }
      
    }
   let questionAndResponseRupayResult;
   if(title === 'ratingType'){
      // questionAndResponseRupayResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=${product_id }&rating_type=${rating_type }`);

     questionAndResponseRupayResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse`,true,{product_id,rating_type,qid});
    
   } else  {
      questionAndResponseRupayResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=2`);
     
   }
   let questionAndResponseDataRupay = questionAndResponseRupayResult.data
  if(questionAndResponseDataRupay && Array.isArray(questionAndResponseDataRupay)){
     const chartsMoreOptionsRupay = questionAndResponseDataRupay;
  const arrayDatasRupay =  Array.isArray(chartsMoreOptionsRupay) ?  questionAndResponseDataRupay.length > 0 ?  questionAndResponseDataRupay[0].options : [] : [];
  setParameterArrRupay(arrayDatasRupay)
 
  setChartsRupayMore(chartsMoreOptionsRupay);
  if(questionAndResRupay && questionAndResRupay.length!==0){
   let finalArray =concatArray.concat(questionAndResponseDataRupay)
   setQuestionAndResponseRupay(finalArray)
   setNoMoreRupay(null)
  }else{
     setNoMoreRupay('No Record Found')
  }
  }
 }
 
 const questionAndResFastag = async ()=>{
   let questionAndResponseFastagResult = await api.get(config.dashboardQuestionAndResponseFastag);
    let questionAndResponseData = questionAndResponseFastagResult.data
    setQuestionAndResponseFastag(questionAndResponseData)
    
 }

 const questionAndResFastags = async (product_id,rating_type,qid,title)=>{
   let concatArray = []
   if(qid){
      let arrayIndex = null;

     questionAndResponseFastag.map((data,index) => {if(data.qid==qid) arrayIndex = index})

     if(arrayIndex || arrayIndex === 0){

       if(arrayIndex=== 0 ){
        concatArray.push(questionAndResponseFastag[0]);
       }else{
        for(let i=0;i<arrayIndex; i++){
           //
           concatArray.push(questionAndResponseFastag[i]);
           
        }
       }
       
       
     }
     
   }
   let questionAndResponseFastagResult;
   if(title === 'ratingType'){
      // questionAndResponseFastagResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=${product_id }&rating_type=${rating_type }`);

     questionAndResponseFastagResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse`,true,{product_id,rating_type,qid});
     
   } else  {
      questionAndResponseFastagResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=3`);

   }
   let questionAndResponseDataFastag = questionAndResponseFastagResult.data
  if(questionAndResponseDataFastag && Array.isArray(questionAndResponseDataFastag)){
     const chartsMoreOptionsFastag = questionAndResponseDataFastag;
  const arrayDatasFastag =  Array.isArray(chartsMoreOptionsFastag) ?  questionAndResponseDataFastag.length > 0 ?  questionAndResponseDataFastag[0].options : [] : [];
  setParameterArrFastag(arrayDatasFastag)
 
  setChartsFastagMore(chartsMoreOptionsFastag);
 if(questionAndResFastag && questionAndResFastag.length !==0){
   let finalArray =concatArray.concat(questionAndResponseDataFastag)
   setQuestionAndResponseFastag(finalArray)
   setNoMoreFastag(null)
 }else{
    setNoMoreFastag('No Record Found')
 }

//    let questionAndResponseFastagResult;
//    if(title === 'ratingType'){
//       questionAndResponseFastagResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=${product_id }&rating_type=${rating_type }`);

//     //  questionAndResponseFastagResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=${product_id }&rating_type=${rating_type }&qid=${qid || '' }`);

//    } else  {
//       questionAndResponseFastagResult = await api.get(`${Config.site_api}/dashboard/questionAndResponse?product_id=3`);

//    }
//    let questionAndResponseDataFastag = questionAndResponseFastagResult.data
//   if(questionAndResponseDataFastag && Array.isArray(questionAndResponseDataFastag)){
//      const chartsMoreOptionsFastag = questionAndResponseDataFastag;
//   const arrayDatasFastag =  Array.isArray(chartsMoreOptionsFastag) ?  questionAndResponseDataFastag.length > 0 ?  questionAndResponseDataFastag[0].options : [] : [];
//   setParameterArrFastag(arrayDatasFastag)
//  setChartsFastagMore(chartsMoreOptionsFastag);
//   setQuestionAndResponseFastag(questionAndResponseDataFastag)
  }
 }
 const ratingWiseNpsScoreUpi = async (dateFilter)=>{
   let ratingWiseNpsScoreResult = await api.get(config.dashboardRatingWiseNpsScore,true,dateFilter);
    let ratingWiseNpsScoreData = ratingWiseNpsScoreResult.data
    setRatingWiseNpsScoresUpi(ratingWiseNpsScoreData)
    setSkeltonLoader1(false)
 }

 
 const ratingWiseNpsScoreRupay = async (dateFilter)=>{
   let ratingWiseNpsScoreResult = await api.get(config.dashboardRatingWiseNpsScore,true,dateFilter);
    let ratingWiseNpsScoreData = ratingWiseNpsScoreResult.data
    setRatingWiseNpsScoresRupay(ratingWiseNpsScoreData)
    setSkeltonLoader2(false)
    
 }
 const ratingWiseNpsScoreFastag = async (dateFilter)=>{
   let ratingWiseNpsScoreResult = await api.get(config.dashboardRatingWiseNpsScore,true,dateFilter);
    let ratingWiseNpsScoreData = ratingWiseNpsScoreResult.data
    setRatingWiseNpsScoresFastag(ratingWiseNpsScoreData)
    setSkeltonLoader3(false)
 }
 const ratingWiseNpsScoreBhim = async (dateFilter)=>{
   let ratingWiseNpsScoreResult = await api.get(config.dashboardRatingWiseNpsScore,true,dateFilter);
    let ratingWiseNpsScoreData = ratingWiseNpsScoreResult.data
    setRatingWiseNpsScoresBhim(ratingWiseNpsScoreData)
    setSkeltonLoader4(false)
 }
 const ratingWiseNpsScoreNetc = async (dateFilter)=>{
   let ratingWiseNpsScoreResult = await api.get(config.dashboardRatingWiseNpsScore,true,dateFilter);
    let ratingWiseNpsScoreData = ratingWiseNpsScoreResult.data
    setRatingWiseNpsScoresNetc(ratingWiseNpsScoreData)
    setSkeltonLoader5(false)
 }

 const ratingWiseNpsScorePieUpi = async (dateFilter)=>{
   let ratingWiseNpsScorePieResult = await api.get(config.dashboardRatingWisePieNpsScore,true,dateFilter);
    let ratingWiseNpsScorePieData = ratingWiseNpsScorePieResult.data
    setRatingWiseNpsScoresPieUpi(ratingWiseNpsScorePieData)
    setSkeltonLoader1(false)
 }

 const ratingWiseNpsScorePieRupay = async (dateFilter)=>{
   let ratingWiseNpsScorePieResult = await api.get(config.dashboardRatingWisePieNpsScore,true,dateFilter);
    let ratingWiseNpsScorePieData = ratingWiseNpsScorePieResult.data
    setRatingWiseNpsScoresPieRupay(ratingWiseNpsScorePieData)
    setSkeltonLoader2(false)
    
 }
 const ratingWiseNpsScorePieFastag = async (dateFilter)=>{
   let ratingWiseNpsScorePieResult = await api.get(config.dashboardRatingWisePieNpsScore,true,dateFilter);
    let ratingWiseNpsScorePieData = ratingWiseNpsScorePieResult.data
    setRatingWiseNpsScoresPieFastag(ratingWiseNpsScorePieData)
    setSkeltonLoader3(false)
 }
 const ratingWiseNpsScorePieBhim = async (dateFilter)=>{
   let ratingWiseNpsScorePieResult = await api.get(config.dashboardRatingWisePieNpsScore,true,dateFilter);
    let ratingWiseNpsScorePieData = ratingWiseNpsScorePieResult.data
    setRatingWiseNpsScoresPieBhim(ratingWiseNpsScorePieData)
    setSkeltonLoader4(false)
 }


const PartnerWiseScoreUpi= async(dateFilter) =>{
   let partnerDataUpi = await api.get(config.dashboardPartnerWiseResponse,true,dateFilter)
   const partnerWiseDataUpi = partnerDataUpi.data
   setUpiPartner(partnerWiseDataUpi)
}

const PartnerWiseScoreRupay= async(dateFilter) =>{
   let partnerDataRupay = await api.get(config.dashboardPartnerWiseResponse,true,dateFilter)
   const partnerWiseDataRupay = partnerDataRupay.data
   setRupayPartner(partnerWiseDataRupay)
}

const PartnerWiseScoreFastag= async(dateFilter) =>{
   let partnerDataFastag = await api.get(config.dashboardPartnerWiseResponse,true,dateFilter)
   const partnerWiseDataFastag = partnerDataFastag.data
   setFastagPartner(partnerWiseDataFastag)
}

const PartnerWiseScoreBhim= async(dateFilter) =>{
   let partnerDataBhim = await api.get(config.dashboardPartnerWiseResponse,true,dateFilter)
   const partnerWiseDataBhim = partnerDataBhim.data
   setBhimPartner(partnerWiseDataBhim)
}
const PartnerWiseScoreNetc= async(dateFilter) =>{
   let partnerDataNetc = await api.get(config.dashboardPartnerWiseResponse,true,dateFilter)
   const partnerWiseDataNetc = partnerDataNetc.data
   setNetcPartner(partnerWiseDataNetc)
}

 const dppChart = async()=>{
   let dppResult = await api.get(config.dashboardDppDataUpi)
   let dppChartData = dppResult.data
   // questionAndResUpi(dppChartData[0])
   setDpp(dppChartData)
 } 

 const dppChartRupay = async()=>{
   let dppResultRupay = await api.get(config.dashboardDppDataRupay)
   let dppChartDataRupay = dppResultRupay.data
   // questionAndResRupay(dppChartDataRupay[0])
 setDppRupay(dppChartDataRupay)
 } 

 const dppChartFastag = async()=>{
   let dppResultFastag = await api.get(config.dashboardDppDataFastag)
   let dppChartDataFastag = dppResultFastag.data
   // questionAndResFastag(dppChartDataFastag[0])
 setDppFastag(dppChartDataFastag)
 } 

//  const dppChartBhim = async()=>{
//    let dppResultBhim = await api.get(config.dashboardDppDataBhim)
//    let dppChartDataBhim = dppResultBhim.data
//    // questionAndResBhim(dppChartDataBhim[0])
//  setDppBhim(dppChartDataBhim)
//  } 
//  const dppChartNetc = async()=>{
//    let dppResultNetc = await api.get(config.dashboardDppDataNetc)
//    let dppChartDataNetc = dppResultNetc.data
//    // questionAndResNetc(dppChartDataNetc[0])
//  setDppNetc(dppChartDataNetc)
//  } 
const upiShowHide =()=>{
   // if (upiBasic.product_id == 1) {
   setUpiShow(!upiShow)
   // }
}

const rupayShowHide =()=>{
   setRupayShow(!rupayShow)
}

const fastagShowHide =()=>{
   setFastagShow(!fastagShow)
}
const bhimShowHide =()=>{
   setBhimShow(!bhimShow)
}
const netcShowHide =()=>{
   setNetcShow(!netcShow)
}

const onClickActive = (e) => {
      
      // alert(">>>" +skeltonLoader)
      let dateFilter = {}
      let dateFilter1 = {}
       // upi date filter //
      if (e.target.id === "0") { 
         setSkeltonLoader1(true)
         setTabs('Today')
          dateFilter = {product:1,start_date:currentDay('start_date'),end_date:currentDay('end_date')}
          dateFilter1 = {product_id:1,start_date:currentDay('start_date'),end_date:currentDay('end_date')}
          basicDetailUpi(dateFilter)
          ratingWiseNpsScoreUpi(dateFilter)
          ratingWiseNpsScorePieUpi(dateFilter)
          PartnerWiseScoreUpi(dateFilter1)
      }
      if (e.target.id === "1") { 
         setSkeltonLoader1(true)
         setTabs('Current Month')
          dateFilter = {product:1,start_date:currentMonth('start_date'),end_date:currentMonth('end_date')}
          dateFilter1 = {product_id:1,start_date:currentMonth('start_date'),end_date:currentMonth('end_date')}
          basicDetailUpi(dateFilter)
          ratingWiseNpsScoreUpi(dateFilter)
          ratingWiseNpsScorePieUpi(dateFilter)
          PartnerWiseScoreUpi(dateFilter1)
      }
      if (e.target.id === "2") { 
         setSkeltonLoader1(true)
         setTabs('Last Month')
         dateFilter = {product:1,start_date:lastMonth('start_date'),end_date:lastMonth('end_date')}
         dateFilter1 = {product_id:1,start_date:lastMonth('start_date'),end_date:lastMonth('end_date')}
         basicDetailUpi(dateFilter)
          ratingWiseNpsScoreUpi(dateFilter)
          ratingWiseNpsScorePieUpi(dateFilter)
          PartnerWiseScoreUpi(dateFilter1)
      }
       if (e.target.id === "3") { 
         setSkeltonLoader1(true)
         setTabs('Yesterday')
         dateFilter = {product:1,start_date:Yesterday('start_date'),end_date:Yesterday('end_date')}
         dateFilter1 = {product_id:1,start_date:Yesterday('start_date'),end_date:Yesterday('end_date')}
         basicDetailUpi(dateFilter)
          ratingWiseNpsScoreUpi(dateFilter)
          ratingWiseNpsScorePieUpi(dateFilter)
          PartnerWiseScoreUpi(dateFilter1)
      }

      // rupay date filter //
      if (e.target.id === "4") { 
         setSkeltonLoader2(true)
         setTabsRupay('Today1')
         dateFilter = {product:2,start_date:currentDay('start_date'),end_date:currentDay('end_date')}
         dateFilter1 = {product_id:2,start_date:currentDay('start_date'),end_date:currentDay('end_date')}
         basicDetailRupay(dateFilter)
          ratingWiseNpsScoreRupay(dateFilter)
          ratingWiseNpsScorePieRupay(dateFilter)
          PartnerWiseScoreRupay(dateFilter1)
      }
      if (e.target.id === "5") { 
         setSkeltonLoader2(true)
         setTabsRupay('Current Month1')
         dateFilter = {product:2,start_date:currentMonth('start_date'),end_date:currentMonth('end_date')}
         dateFilter1 = {product_id:2,start_date:currentMonth('start_date'),end_date:currentMonth('end_date')}
         basicDetailRupay(dateFilter)
          ratingWiseNpsScoreRupay(dateFilter)
          ratingWiseNpsScorePieRupay(dateFilter)
          PartnerWiseScoreRupay(dateFilter1)
      }
      if (e.target.id === "6") { 
         setSkeltonLoader2(true)
         setTabsRupay('Last Month1')
         dateFilter = {product:2,start_date:lastMonth('start_date'),end_date:lastMonth('end_date')}
         dateFilter1 = {product_id:2,start_date:lastMonth('start_date'),end_date:lastMonth('end_date')}
         basicDetailRupay(dateFilter)
         ratingWiseNpsScoreRupay(dateFilter)
         ratingWiseNpsScorePieRupay(dateFilter)
         PartnerWiseScoreRupay(dateFilter1)
      }
      if (e.target.id === "7") { 
         setSkeltonLoader2(true)
         setTabsRupay('Yesterday1')
         dateFilter = {product:2,start_date:Yesterday('start_date'),end_date:Yesterday('end_date')}
         dateFilter1 = {product_id:2,start_date:Yesterday('start_date'),end_date:Yesterday('end_date')}
         basicDetailRupay(dateFilter)
          ratingWiseNpsScoreRupay(dateFilter)
          ratingWiseNpsScorePieRupay(dateFilter)
          PartnerWiseScoreRupay(dateFilter1)
      }

      // fastag date filter //
      if (e.target.id === "8") { 
         setSkeltonLoader3(true)
         setTabsRupay('Today2')
         dateFilter = {product:3,start_date:currentDay('start_date'),end_date:currentDay('end_date')}
         dateFilter1 = {product_id:3,start_date:currentDay('start_date'),end_date:currentDay('end_date')}
         basicDetailFastag(dateFilter)
         ratingWiseNpsScoreFastag(dateFilter)
         ratingWiseNpsScorePieFastag(dateFilter)
         PartnerWiseScoreFastag(dateFilter1)
      }
      if (e.target.id === "9") { 
         setSkeltonLoader3(true)
         setTabsFastag('Current Month2')
         dateFilter = {product:3,start_date:currentMonth('start_date'),end_date:currentMonth('end_date')}
         dateFilter1 = {product_id:3,start_date:currentMonth('start_date'),end_date:currentMonth('end_date')}
         basicDetailFastag(dateFilter)
         ratingWiseNpsScoreFastag(dateFilter)
         ratingWiseNpsScorePieFastag(dateFilter)
         PartnerWiseScoreFastag(dateFilter1)
      }
      if (e.target.id === "10") { 
         setSkeltonLoader3(true)
         setTabsFastag('Last Month2')
         dateFilter = {product:3,start_date:lastMonth('start_date'),end_date:lastMonth('end_date')}
         dateFilter1 = {product_id:3,start_date:lastMonth('start_date'),end_date:lastMonth('end_date')}
         basicDetailFastag(dateFilter)
         ratingWiseNpsScoreFastag(dateFilter)
         ratingWiseNpsScorePieFastag(dateFilter)
         PartnerWiseScoreFastag(dateFilter1)
      }
      if (e.target.id === "11") { 
         setSkeltonLoader3(true)
         setTabsFastag('Yesterday2')
         dateFilter = {product:3,start_date:Yesterday('start_date'),end_date:Yesterday('end_date')}
         dateFilter1 = {product_id:3,start_date:Yesterday('start_date'),end_date:Yesterday('end_date')}
         basicDetailFastag(dateFilter)
         ratingWiseNpsScoreFastag(dateFilter)
         ratingWiseNpsScorePieFastag(dateFilter)
         PartnerWiseScoreFastag(dateFilter1)
      }

      // Bhim date filter //
      if (e.target.id === "12") { 
         setSkeltonLoader4(true)
         setTabsBhim('Today3')
         dateFilter = {product:4,start_date:currentDay('start_date'),end_date:currentDay('end_date')}
         dateFilter1 = {product_id:4,start_date:currentDay('start_date'),end_date:currentDay('end_date')}
         basicDetailBhim(dateFilter)
         ratingWiseNpsScoreBhim(dateFilter)
         ratingWiseNpsScorePieBhim(dateFilter)
         PartnerWiseScoreBhim(dateFilter1)
      }

      if (e.target.id === "13") { 
         setSkeltonLoader4(true)
         setTabsBhim('Current Month3')
         dateFilter = {product:4,start_date:currentMonth('start_date'),end_date:currentMonth('end_date')}
         dateFilter1 = {product_id:4,start_date:currentMonth('start_date'),end_date:currentMonth('end_date')}
         basicDetailBhim(dateFilter)
         ratingWiseNpsScoreBhim(dateFilter)
         ratingWiseNpsScorePieBhim(dateFilter)
         PartnerWiseScoreBhim(dateFilter1)
      }
      if (e.target.id === "14") { 
         setSkeltonLoader4(true)
         setTabsBhim('Last Month3')
         dateFilter = {product:4,start_date:lastMonth('start_date'),end_date:lastMonth('end_date')}
         dateFilter1 = {product_id:4,start_date:lastMonth('start_date'),end_date:lastMonth('end_date')}
         basicDetailBhim(dateFilter)
         ratingWiseNpsScoreBhim(dateFilter)
         ratingWiseNpsScorePieBhim(dateFilter)
         PartnerWiseScoreBhim(dateFilter1)
      }
      if (e.target.id === "15") { 
         setSkeltonLoader4(true)
         setTabsBhim('Yesterday3')
         dateFilter = {product:4,start_date:currentDay('start_date'),end_date:currentDay('end_date')}
         dateFilter1 = {product_id:4,start_date:currentDay('start_date'),end_date:currentDay('end_date')}
         basicDetailBhim(dateFilter)
         ratingWiseNpsScoreBhim(dateFilter)
         ratingWiseNpsScorePieBhim(dateFilter)
         PartnerWiseScoreBhim(dateFilter1)
      }
      // //
      // if (e.target.id === "16") { 
      //    setSkeltonLoader5(true)
      //    setTabsNetc('Yesterday4')
      //    dateFilter = {product:5,start_date:Yesterday('start_date'),end_date:Yesterday('end_date')}
      //    dateFilter1 = {product_id:5,start_date:Yesterday('start_date'),end_date:Yesterday('end_date')}
      //    basicDetailNetc(dateFilter)
      //    ratingWiseNpsScoreNetc(dateFilter)
      //    PartnerWiseScoreNetc(dateFilter1)
      // }
      // if (e.target.id === "17") { 
      //    setSkeltonLoader5(true)
      //    setTabsNetc('Last Month4')
      //    dateFilter = {product:5,start_date:lastMonth('start_date'),end_date:lastMonth('end_date')}
      //    dateFilter1 = {product_id:5,start_date:lastMonth('start_date'),end_date:lastMonth('end_date')}
      //    basicDetailNetc(dateFilter)
      //    ratingWiseNpsScoreNetc(dateFilter)
      //    PartnerWiseScoreNetc(dateFilter1)
      // }
      
    }


    const selectChartOptions = (e) => {
       if(e.target.value == "PieChart"){
         setPieChart(true)
       }
       else{
         setPieChart(false)
       }
       
       
    }

 
    return(

<div className="col-md-12 mt-3">

 <div className="row">                           
             <div className="col-md-12">
                <div className="card">

                   <div className="card-header d-flex justify-content-between">
                      <div className="d-flex align-items-center">
                         <h5  className="primary-cl productUpiBasicDetails1"><b>Basic Details </b></h5>

                      </div>
                      <div>
                         {/* <button onClick={(e)=> setSelectChart(e.target.name)} name="All" className={selectChart == "All" ?"btn bg-col-sky active":"btn bg-col-sky"}>All</button> */}
                         {/* <button style={{ width: "130px" }} onClick={selectChartOptions} id="1" className={"Current Month" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>{piechart ? "Select Line Bar chart" : "Select Pie Chart"}</button> */}
                         <th className="productUpiBasicDetails2">
                            <select required className="form-control" name="roleType" placeholder="" onChange={selectChartOptions}>
                                    <option  selected hidden>Select Chart Type...</option>
                                    <option  value="LineBarChart" >Line Bar Chart</option>
                                    <option  value="PieChart" >Pie Chart</option>
                                    {/* <option  value={this.state.roleType} onChange={this.handleChange} name="roleType" disabled selected hidden>Select Role Type...</option> */}
                                </select></th>
                      </div>
                   </div>

                </div>
             </div>
   {/* Upi Details */}
   {product && product.find((item)=>item.id===1)!==undefined && ((upiShow===false && rupayShow === false && fastagShow===false && bhimShow===false && netcShow===false) || (upiShow)) && <div className={upiShow === true || (filterApply && product && product.find((item)=>item.id===1)!==undefined)  ? "col-md-12" : "col-md-6 col-sm-12 col-12 col-lg-6" }>
       <div className="card bg-white left-right">
          <div className="card-header d-flex justify-content-between">
             <div className="d-flex align-items-center">
                <img onClick={upiShowHide} className="productUpiBasicDetails3"  src="img/ph-logo-2.png" />
               <h5 onClick={upiShowHide} className="">Product Name 1 </h5>
             </div> 
             <div className="sml-btn">
             { more ? <>  <button onClick={onClickActive} id="0" className={"Today" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>Today</button>
                        <button onClick={onClickActive} id="3" className={"Yesterday" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>Yesterday</button>
                        <button onClick={onClickActive} id="1" className={"Current Month" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>Current Month</button>
                    
                         <button onClick={onClickActive} id="2" className={"Last Month" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>Last Month</button>
                    </> : <> <button onClick={onClickActive} id="0" className={"Today" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>Today</button>
                        <button onClick={onClickActive} id="3" className={"Yesterday" == tabs ? "btn bg-col-sky active" : "btn bg-col-sky"}>Yesterday</button>
                      </>  }
                        <i className="fa  fa-bars ml-2 btn"  onClick={()=>setMore(!more)}/>
                     
                         </div>
          </div>
          { upiBasic.length <= 0 ? <> 
            <Loader/>
         </> : <> 
          <div className="row card-body">
         <div  className={(upiShow === true || (filterApply && product && product.find((item)=>item.id===1)!==undefined)) ? "col-md-6 " : "col-md-12"}>
           
            <figure className="highcharts-figure">
               <h5 className="mb-3 text-center productUpiBasicDetails4"><b> {get(upiBasic,'product_title','')} Basic Details</b></h5>
             <div className="productUpiBasicDetails5" >
                
             <BasicDetailChart  Required={get(upiBasic,"target_required",0)} Achieved={get(upiBasic,"target_achieved",0)} NPS={get(upiBasic,"total_score",0)} Basic={upiBasic} 
             width={upiShow}/>
            </div>

             </figure>
                 <table className="table no-padding">
                    <tbody>
               <tr rowSpan="2" >
               <th >Partner wise NPS Score </th>
                  <th className="text-center"> <span className="badge "> Count </span></th>
                  <th className="text-center"><span className="badge "> NPS Score </span></th>
               </tr>

               { upiPartner  ?  <> 
               {upiPartner.length>0 ? <> {  upiPartner.map((data, index ) => (
                    (index%2 == 0 && index<6) ? (
                   <tr  className="productUpiBasicDetails6" >
                   <td>{data.partner_name} </td>
                   <td className="text-center"> <span className="badge ">{data.total_rating}</span> </td>
                   <td className="text-center"><span className="badge">{data.partner_rating}</span></td>
                </tr>
                ) : (index<6) ? (<tr className="productUpiBasicDetails7">
                <td>{data.partner_name} </td>
                <td className="text-center"> <span className="badge ">{data.total_rating}</span> </td>
                   <td className="text-center"><span className="badge ">{data.partner_rating}</span></td>
                      </tr>) : <span></span>
               
              
             
               ))} </>: <div>No Record Found</div>}
               </> : <> 
               <div className="spinner-border text-info" role="status"> 
                <span className="sr-only">Loading...</span>
               {/* <h2>hello</h2> */}
               </div> 
               </> }
               
               </tbody> 
            </table>
            {/* <button style={{float: "right"}}  className="btn btn-link">Show All Partner</button> */}
            {/* <ModalPartner title="List Of All Upi Partner" content={upiPartner}
            classname={"float: 'right'"} buttonLabel={"Show All Partner"}
            action={""}/> */}

            
            {/* <ModalDiv title={"All Comments"} content={`All comments`} 
                      classname={"btn btn-link text-white"} 
                      buttonLabel={"Show All Message"}  buttonSubmit={"Ok"}/> */}
         </div>
         <div className={(upiShow === true || (filterApply && product && product.find((item)=>item.id===1)!==undefined))? "col-md-6" : "col-md-12"}>
         <p  className="text-left productUpiBasicDetails8"><b>Rating Given By Customers</b></p>
              
               
               {skeltonLoader1 ? 
               <Skeleton count={20}/>
               :<>
               {piechart == false ?
                  <RatingWiseResponse ratingWiseNpsScores={get(ratingWiseNpsScoresUpi,'product_name_1',[])} ratingWiseNpsScoresPercentage={get(ratingWiseNpsScoresUpi,'product_name_1_percent',[])} width={upiShow}/>
                  
                   : <PieChart ratingWiseNpsScoresPie={ratingWiseNpsScoresPieUpi} />
                  }
               </>}
             

            </div>
       </div>
       </> }  
       </div>
    </div>}
   

   {/* Rupay Details */}
    {product && product.find((item)=>item.id===2)!==undefined && ((upiShow===false && rupayShow===false && fastagShow===false && bhimShow===false && netcShow===false) || (rupayShow)) && <div className={rupayShow === true || (filterApply && product && product.find((item)=>item.id===2)!==undefined) ? "col-md-12" : "col-md-6 col-sm-12 col-12 col-lg-6"}>
       <div className="card bg-white left-right">
          <div className="card-header d-flex justify-content-between">
             <div className="d-flex align-items-center">
                <img onClick={rupayShowHide} className="productUpiBasicDetails3"  src="img/ph-logo-2.png"/>
                <h5 onClick={rupayShowHide} >Product Name 2 </h5>
             </div>
            
                     <div className="sml-btn">
             { more1 ? <>  <button onClick={onClickActive} id="4" className={"Today1" == tabsRupay ? "btn bg-col-sky active" : "btn bg-col-sky"}>Today</button>
                        <button onClick={onClickActive} id="7" className={"Yesterday1" == tabsRupay ? "btn bg-col-sky active" : "btn bg-col-sky"}>Yesterday</button>
                        <button onClick={onClickActive} id="5" className={"Current Month1" == tabsRupay ? "btn bg-col-sky active" : "btn bg-col-sky"}>Current Month</button>
                         <button onClick={onClickActive} id="6" className={"Last Month1" == tabsRupay ? "btn bg-col-sky active" : "btn bg-col-sky"}>Last Month</button>
                    </> : <> <button onClick={onClickActive} id="4" className={"Today1" == tabsRupay ? "btn bg-col-sky active" : "btn bg-col-sky"}>Today</button>
                        <button onClick={onClickActive} id="7" className={"Yesterday1" == tabsRupay ? "btn bg-col-sky active" : "btn bg-col-sky"}>Yesterday</button>
                      </>  }
                        <i className="fa  fa-bars ml-2 btn"  onClick={()=>setMore1(!more1)}/>
                     
                         </div>
          </div>
          { rupayBasic.length <= 0 ? <> 
            <Loader/>
         </> : <> 

          <div className="row card-body">
         <div  className={(rupayShow === true || (filterApply && product && product.find((item)=>item.id===2)!==undefined))? "col-md-6 " : "col-md-12"}>
           
            <figure className="highcharts-figure">
               <h5 className="mb-3 text-center productUpiBasicDetails4"><b> {get(rupayBasic,'product_title','')} Basic Details</b></h5>
             
             <BasicDetailChart Required={get(rupayBasic,"target_required",0)} Achieved={get(rupayBasic,"target_achieved",0)} NPS={get(rupayBasic,"total_score",0)} width={rupayShow}/>
            
             </figure>
             <table className="table no-padding">
                <tbody>
                <tr rowSpan="2" >
               <th >Partner wise NPS Score </th>
                  <th className="text-center"> <span className="badge "> Count </span></th>
                  <th className="text-center"><span className="badge "> NPS Score </span></th>
               </tr>


                 { rupayPartner  ?  <> 
               {rupayPartner.length>0 ? <> {  rupayPartner.map((data, index ) => (
                    (index%2 == 0 && index<6) ? (
                     <tr  className="productUpiBasicDetails6" >
                     <td>{data.partner_name} </td>
                     <td className="text-center"> <span className="badge ">{data.total_rating}</span> </td>
                     <td className="text-center"><span className="badge">{data.partner_rating}</span></td>
                  </tr>
                  ) : (index<6) ? (<tr className="productUpiBasicDetails7">
                  <td>{data.partner_name} </td>
                  <td className="text-center"> <span className="badge ">{data.total_rating}</span> </td>
                     <td className="text-center"><span className="badge ">{data.partner_rating}</span></td>
                        </tr>) : <span></span>
               
              
             
               ))} </>: <div>No Record Found</div>}
               </> : <> 
               <div className="spinner-border text-info" role="status"> 
                <span className="sr-only">Loading...</span>
               {/* <h2>hello</h2> */}
               </div> 
               </> }
              </tbody>
             </table>
             {/* <button style={{float: "right"}}  className="btn btn-link">Show All Partner</button> */}
             {/* <ModalPartner title="List Of All RuPay Partner" content={rupayPartner}
            classname={"float: 'right'"} buttonLabel={"Show All Partner"}
            action={""}/> */}
         </div>
         <div className={(rupayShow === true || (filterApply && product && product.find((item)=>item.id===2)!==undefined)) ? "col-md-6" : "col-md-12"}>
         <p className="text-left productUpiBasicDetails8"><b>Rating Given By Customers</b></p>
         
         {skeltonLoader2 ? 
               <Skeleton count={20}/>
               :<>

               {piechart == false ?
               <RatingWiseResponse ratingWiseNpsScores={get(ratingWiseNpsScoresRupay,'product_name_2',[])} ratingWiseNpsScoresPercentage={get(ratingWiseNpsScoresRupay,'product_name_2_percent',[])} width={rupayShow} />
              
               : <PieChart ratingWiseNpsScoresPie={ratingWiseNpsScoresPieRupay} />}
            </>}
            </div>
       </div>
       
       </> }
       </div>
    </div>}
   

    {/* FASTag Details */}
    {product && product.find((item)=>item.id===3)!==undefined && ((upiShow===false && rupayShow===false && fastagShow===false && bhimShow===false && netcShow===false) || (fastagShow)) && <div className={fastagShow === true || (filterApply && product && product.find((item)=>item.id===3)!==undefined) ? "col-md-12" : "col-md-6 col-sm-12 col-12 col-lg-6"}>
       <div className="card bg-white left-right">
          <div className="card-header d-flex justify-content-between">
             <div className="d-flex align-items-center">
             <img onClick={fastagShowHide}  className="productUpiBasicDetails3" src="img/ph-logo-2.png"/>
             <h5 onClick={fastagShowHide} >Journey Name 1 </h5>
             </div>
             <div className="sml-btn">
             { more2 ? <>  <button onClick={onClickActive} id="8" className={"Today2" == tabsFastag ? "btn bg-col-sky active" : "btn bg-col-sky"}>Today</button>
                        <button onClick={onClickActive} id="11" className={"Yesterday2" == tabsFastag ? "btn bg-col-sky active" : "btn bg-col-sky"}>Yesterday</button>
                        <button onClick={onClickActive} id="9" className={"Current Month2" == tabsFastag ? "btn bg-col-sky active" : "btn bg-col-sky"}>Current Month</button>
                         <button onClick={onClickActive} id="10" className={"Last Month2" == tabsFastag ? "btn bg-col-sky active" : "btn bg-col-sky"}>Last Month</button>
                    </> : <> <button onClick={onClickActive} id="8" className={"Today2" == tabsFastag ? "btn bg-col-sky active" : "btn bg-col-sky"}>Today</button>
                        <button onClick={onClickActive} id="11" className={"Yesterday2" == tabsFastag ? "btn bg-col-sky active" : "btn bg-col-sky"}>Yesterday</button>
                      </>  }
                        <i className="fa  fa-bars ml-2 btn"  onClick={()=>setMore2(!more2)}/>
            </div>
            </div>
          { fastagBasic.length <= 0 ? <> 
            <Loader/>
         </> : <> 

          <div className="row card-body">
         <div  className={(fastagShow === true || (filterApply && product && product.find((item)=>item.id===3)!==undefined)) ? "col-md-6 " : "col-md-12"}>
           
            <figure className="highcharts-figure">
               <h5 className="mb-3 text-center productUpiBasicDetails7"><b> {get(fastagBasic,'product_title','')} Basic Details</b></h5>
           
            <BasicDetailChart Required={get(fastagBasic,"target_required",0)} Achieved={get(fastagBasic,"target_achieved",0)} NPS={get(fastagBasic,"total_score",0)} width={fastagShow}/>
            
             </figure>
             <table className="table no-padding">
                <tbody>
                <tr rowSpan="2" >
               <th >Partner wise NPS Score </th>
                  <th className="text-center"> <span className="badge "> Count </span></th>
                  <th className="text-center"><span className="badge "> NPS Score </span></th>
               </tr>
               
                 { fastagPartner  ?  <> 
               {fastagPartner.length>0 ? <> {  fastagPartner.map((data, index ) => (
                   (index%2 == 0 && index<6) ? (
                     <tr  className="productUpiBasicDetails6" >
                     <td>{data.partner_name} </td>
                     <td className="text-center"> <span className="badge ">{data.total_rating}</span> </td>
                     <td className="text-center"><span className="badge ">{data.partner_rating}</span></td>
                  </tr>
                  ) : (index<6) ? (<tr className="productUpiBasicDetails7">
                  <td>{data.partner_name} </td>
                  <td className="text-center"> <span className="badge ">{data.total_rating}</span> </td>
                     <td className="text-center"><span className="badge ">{data.partner_rating}</span></td>
                        </tr>) : <span></span>
               
              
             
               ))} </>: <div>No Record Found</div>}
               </> : <> 
               <div className="spinner-border text-info" role="status"> 
                <span className="sr-only">Loading...</span>
               {/* <h2>hello</h2> */}
               </div> 
               </> }
              </tbody>
             </table>
             {/* <button style={{float: "right"}}  className="btn btn-link">Show All Partner</button> */}
             {/* <ModalPartner title="List Of All FasTag Partner" content={fastagPartner}
            classname={"float: 'right'"} buttonLabel={"Show All Partner"}
            action={""}/> */}
         </div>
         <div className={(fastagShow === true || (filterApply && product && product.find((item)=>item.id===3)!==undefined))   ? "col-md-6" : "col-md-12"}>
         <p className="text-left productUpiBasicDetails8"><b>Rating Given By Customers</b></p>
         
         {skeltonLoader3 ? 
               <Skeleton count={20}/>
               :<>
               {piechart == false ?
               <RatingWiseResponse ratingWiseNpsScores={get(ratingWiseNpsScoresFastag,'journey_name_1',[])} ratingWiseNpsScoresPercentage={get(ratingWiseNpsScoresFastag,'journey_name_1_percent',[])} width={fastagShow}/> 
               
               : <PieChart ratingWiseNpsScoresPie={ratingWiseNpsScoresPieFastag} />}
               </>}
               
            </div>
       </div>
       </>}
       </div>
    </div>}
    
    {/* Bhim Details */}
    {product && product.find((item)=>item.id===4)!==undefined && ((upiShow===false && rupayShow===false && fastagShow===false && bhimShow===false && netcShow===false) || (bhimShow)) && <div className={bhimShow === true || (filterApply && product && product.find((item)=>item.id===4)!==undefined) ? "col-md-12" : "col-md-6 col-sm-12 col-12 col-lg-6"}>
       <div className="card bg-white left-right">
          <div className="card-header d-flex justify-content-between">
             <div className="d-flex align-items-center">
             <img onClick={bhimShowHide} className="productUpiBasicDetails3"  src="img/ph-logo-2.png"/>
                <h5 onClick={bhimShowHide}  >Journey Name 2</h5>
                
             </div>
             <div className="sml-btn">
             { more3 ? <>  <button onClick={onClickActive} id="12" className={"Today3" == tabsBhim ? "btn bg-col-sky active" : "btn bg-col-sky"}>Today</button>
                        <button onClick={onClickActive} id="15" className={"Yesterday3" == tabsBhim ? "btn bg-col-sky active" : "btn bg-col-sky"}>Yesterday</button>
                        <button onClick={onClickActive} id="13" className={"Current Month3" == tabsBhim ? "btn bg-col-sky active" : "btn bg-col-sky"}>Current Month</button>
                    
                         <button onClick={onClickActive} id="14" className={"Last Month3" == tabsBhim ? "btn bg-col-sky active" : "btn bg-col-sky"}>Last Month</button>
                    </> : <> <button onClick={onClickActive} id="12" className={"Today3" == tabsBhim ? "btn bg-col-sky active" : "btn bg-col-sky"}>Today</button>
                        <button onClick={onClickActive} id="15" className={"Yesterday3" == tabsBhim ? "btn bg-col-sky active" : "btn bg-col-sky"}>Yesterday</button>
                      </>  }
                        <i className="fa  fa-bars ml-2 btn"  onClick={()=>setMore3(!more3)}/>
            </div>
           
          </div>
          { bhimBasic.length <= 0 ? <> 
            <Loader/>
         </> : <> 

          <div className="row card-body">
         <div  className={(bhimShow === true || (filterApply && product && product.find((item)=>item.id===4)!==undefined)) ? "col-md-6 " : "col-md-12"}>
           
            <figure className="highcharts-figure">
               <h5 className="mb-3 text-center productUpiBasicDetails7"><b> {get(bhimBasic,'product_title','')} Basic Details</b></h5>
           
            <BasicDetailChart Required={get(bhimBasic,"target_required",0)} Achieved={get(bhimBasic,"target_achieved",0)} NPS={get(bhimBasic,"total_score",0)} width={bhimShow}/>
            
             </figure>
             <table className="table no-padding">
                <tbody>
                <tr rowSpan="2" >
               <th >Partner wise NPS Score </th>
                  <th className="text-center"> <span className="badge "> Count </span></th>
                  <th className="text-center"><span className="badge "> NPS Score </span></th>
               </tr>
               
                 { bhimPartner  ?  <> 
               {bhimPartner.length>0 ? <> {  bhimPartner.map((data, index ) => (
                    (index%2 == 0 && index<6) ? (
                     <tr  className="productUpiBasicDetails6" >
                     <td>{data.partner_name} </td>
                     <td className="text-center"> <span className="badge ">{data.total_rating}</span> </td>
                     <td className="text-center"><span className="badge ">{data.partner_rating}</span></td>
                  </tr>
                  ) : (index<6) ? (<tr className="productUpiBasicDetails7">
                  <td>{data.partner_name} </td>
                  <td className="text-center"> <span className="badge ">{data.total_rating}</span> </td>
                     <td className="text-center"><span className="badge ">{data.partner_rating}</span></td>
                        </tr>) : <span></span>
               
              
             
               ))} </>: <div>No Record Found</div>}
               </> : <> 
               <div className="spinner-border text-info" role="status"> 
                <span className="sr-only">Loading...</span>
               {/* <h2>hello</h2> */}
               </div> 
               </> }
              </tbody>
             </table>
             {/* <button style={{float: "right"}}  className="btn btn-link">Show All Partner</button> */}
             {/* <ModalPartner title="List Of All Bhim Partner" content={bhimPartner}
            classname={"float: 'right'"} buttonLabel={"Show All Partner"}
            action={""}/> */}
         </div>
         <div className={(bhimShow === true || (filterApply && product && product.find((item)=>item.id===4)!==undefined))   ? "col-md-6" : "col-md-12"}>
         <p className="text-left productUpiBasicDetails8"><b>Rating Given By Customers</b></p>
         
         {skeltonLoader4 ? 
               <Skeleton count={20}/>
               :<>
               {piechart == false ?
               <RatingWiseResponse ratingWiseNpsScores={get(ratingWiseNpsScoresBhim,'journey_name_2',[])} ratingWiseNpsScoresPercentage={get(ratingWiseNpsScoresBhim,'journey_name_2_percent',[])} width={bhimShow}/> 
               
               : <PieChart ratingWiseNpsScoresPie={ratingWiseNpsScoresPieBhim} />}
               </>}
               
            </div>
       </div>
       </>}
       </div>
    </div>}
 
   
    <div className="clearfix"></div>
   
 
    {/* </div>
    </div> */}
   </div>
   {product && product.find((item)=>item.id===1)!==undefined && ((upiShow===false && rupayShow===false && fastagShow===false) || upiShow ) &&  <UpiQuestionAndResponse loader={loader} questionAndResponse={questionAndResponseUpi} dpp={dpp} noMoreUpi={noMoreUpi} parameterArr={parameterArr} chartsUpiMore={chartsUpiMore} questionAndResUpis={questionAndResUpis} questionAndResUpi={(value) => questionAndResUpi(value)}/> }
    { product && product.find((item)=>item.id===2)!==undefined && ((upiShow===false && rupayShow===false && fastagShow===false) || rupayShow) &&  <RupayQuestionAndResponse questionAndResponse={questionAndResponseRupay} dpp={dppRupay} noMoreRupay={noMoreRupay} parameterArrRupay={parameterArrRupay} chartsRupayMore={chartsRupayMore} questionAndResRupays={questionAndResRupays} questionAndResRupay={(value) => questionAndResRupay(value)}/> }
    { product && product.find((item)=>item.id===3)!==undefined && ((upiShow===false && rupayShow===false && fastagShow===false) || fastagShow) &&  <FastTageQuestionAndResponse questionAndResponse={questionAndResponseFastag} dpp={dppFastag} noMoreFastag={noMoreFastag}  parameterArrFastag={parameterArrFastag} chartsFastagMore={chartsFastagMore} questionAndResFastags={questionAndResFastags} questionAndResFastag={(value) => questionAndResFastag(value)}/> }
 
 
  
  
    </div>
 
    )
}
export default ProductUpiBasicDetail