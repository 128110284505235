import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import './commentModel.css';
import './loader.css'

export const Loader = () => {
 


  return (
  <div>
      
     
      <div className="container">
          <div className="loader">
              {/* <div className="loader--dot"></div>
              <div className="loader--dot"></div>
              <div className="loader--dot"></div> */}
              <div className="loader--dot"></div>
              <div className="loader--dot"></div>
              <div className="loader--dot"></div>
              <div className="loader--text"></div>
          </div>
      </div>

  </div>
    )
}

// export default Loader;