import React, { Fragment, useEffect, useState } from 'react'
import IndiaMapChart from '../../charts/indiaMapChart'
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { Loader } from '../../../common/loader'

function StateWiseImpactOnNps() {
    
    const api = new API()
    const [states,setStates] = useState({})
    const [statesMap,setStatesMap] = useState([])
    const [keyParameter,setKeyParameter] = useState([])



    useEffect(() => {
        stateWiseMapResponse()
        stateWiseResponse()
        keyParametersResponse()
    },[])
    
    //  stateWiseResponse //
    const stateWiseResponse = async()=> {
        let stateWiseResponseResult = await api.get(config.dashboardStateWiseResponse)
        let stateWiseResponseData = stateWiseResponseResult.data
        setStates(stateWiseResponseData)
    }


    // stateWiseMapResponse //
    const stateWiseMapResponse = async()=> {
        let stateWiseMapResponseResult = await api.get(config.dashboardStateWiseMapResponse)
        let stateWiseMapResponseData = stateWiseMapResponseResult.data
        setStatesMap(stateWiseMapResponseData)
    }

// keyParameters //
const keyParametersResponse = async()=> {
    let keyParametersResponseResult = await api.get(config.dashboardKeyParameters)
    let keyParametersResponseData = keyParametersResponseResult.data
    setKeyParameter(keyParametersResponseData)
}
  
    const  handleStateWiseUpdate =()=>{
        stateWiseResponse()
    }    


    return (
        <div className="col-md-12 mt-3">
            <div className="row">
                <div className="col-md-12">
                    <div className="card bg-white left-right">
                        <div className="card-header">

                            <h5 className="stateWiseImpactNps1"><b>State-Wise NPS Data and Key Working Areas</b></h5>
                        </div>
                        {statesMap && statesMap.length <=0 ? <> 
                    <Loader/>
                    </> : <> 
                  
                        <div className="card-body stateWiseImpactNps2">
                            <div className="row">
                                <div  className="col-md-6 site-list stateWiseImpactNps3">

                                    <p>Cursor on state to view NPS related information & Major key areas, Working on then will help to improve existing NPS score
                                    </p>
                                    <br></br>
                                    <h5>{statesMap && states.title} Score</h5>
                                    {/* <div>
                                    <input onClick = {handleCheck} value={panIndiaCheck} type="checkbox" name="panIndiaCheck" /> <span><label>  PAN INDIA Score</label></span>
                                    
                                    </div> */}
                                    <h4 style={{color:"black"}}>-----------------------------------------------------------</h4>
                                    <ul className="info-list row">
                                        <li className="col-lg-12">
                                            <label>Total Promoter</label>
                                            <h6 className="stateWiseImpactNps5" ><b>{statesMap && states.total_permoters}</b></h6>
                                        </li>
                                        <li className="col-lg-12">
                                            <label>Total Passive</label>
                                            <h6 className="stateWiseImpactNps5"><b>{statesMap && states.total_passives}</b></h6>
                                         </li>
                                        <li className="col-lg-12">
                                            <label>Total Detractor</label>
                                            <h6 className="stateWiseImpactNps5"><b>{statesMap && states.total_detractors}</b></h6>
                                        </li>
                                        <li className="col-lg-12">
                                            <label>Current NPS Status  </label>
                                         <h6 className="stateWiseImpactNps5"><b>{statesMap && states.nps_score}</b></h6>
                                         </li>

                                    </ul>
                                    <h4 className="stateWiseImpactNps4">-----------------------------------------------------------</h4>
                                    <h5 className="mb-1"><b>KEY Parameters to work on </b>
                                   </h5>
                                   <h4 className="stateWiseImpactNps4">-----------------------------------------------------------</h4>
                                    <ol className="pl-3">
                                       {keyParameter && keyParameter.map((data,index)=>(
                                           <Fragment>
                                           <li key={index} className="stateWiseImpactNps6">{data.optionTitle}</li>
                                           <br></br>
                                           </Fragment>
                                       )) }
                                        
                                        {/* <li style={{color:"red"}}>Any Other Specific Feature </li>
                                        <br></br>
                                        <li style={{color:"red"}}> Difficult to Use the Application </li> */}
                                    </ol>
                                    <h4 className="stateWiseImpactNps5">-----------------------------------------------------------</h4>
                                </div>
                                <div className="col-md-6 map-india">
                                    <IndiaMapChart handleStateWiseUpdate={handleStateWiseUpdate} statesMap={statesMap} />
                                </div>
                            </div>
                        </div>
                        </>}
                    </div>
                </div>

            </div>
        </div>

    )
}
export default StateWiseImpactOnNps