import React, { Fragment, useState } from 'react';
import './q3.css';
import {final} from './finalSurvey';


const FeedbackAndSuggestion = () => {
  const [heading, setHeading] = useState("Awesome, we are happy to know that you liked our services, what you like the most in the app ?")
  const [isHeadingChange, setIsHeadingChange] = useState(false)
  const [feedbackAndSuggestionMsg, setFeedbackAndSuggestionMsg] = useState("Any Feedback Or Suggestion")
  const [isFeedSuggChange, setIsFeedSuggChange] = useState(false)
    
   const changeHeading = () =>{
    setIsHeadingChange(!isHeadingChange)
   }
   const setHeadingChanges = (e) => {
    setHeading(e.target.value)
   }
   const changeFeedSugges = () =>{
    setIsFeedSuggChange(!isFeedSuggChange)
   }
   const setFeedSuggestion = (e) => {
    setFeedbackAndSuggestionMsg(e.target.value)
   }

   const submit=()=>{
    final.push({heading, feedbackAndSuggestionMsg, type: 100})
    console.log(final)
    }

   return (
        
        <Fragment>

<header className="fixed-top">
  <div className="header-wrap d-block text-center">
    <img src="CreateSurveyimages/logo.png" alt="" className="" style={{height: "50px", paddingTop: "5px"}}/>    
  </div>
  <div className="line-bottom"></div>
  </header>

  <div className="min-height-body">
    <div className="">
    <div className="position-relative card-header custom-header equal-spacing mt-3 mb-3">       
    {isHeadingChange == false && <h6 onClick={changeHeading} className="f-16 mb-0 d-block mx-auto"> {heading}</h6> }
      {isHeadingChange == true && <input onChange={setHeadingChanges} onMouseLeave={changeHeading} style={{ width:"50%", margin:"auto"}} className="form-control input-sm" value={heading} />}
    </div>
  

      <div className="container">
        <div className="text-center">
        <label for="op1" className="labelClass">
          <input id="op1" type="file" hidden/>
          <img src="CreateSurvey/images/feedback.svg" alt="" className="mx-auto mb-4 mt-4 icon-img"/>
          </label>
    {isFeedSuggChange == false && <h6 style={{color:"black"}} onClick={changeFeedSugges}> {feedbackAndSuggestionMsg}</h6> }
    {isFeedSuggChange == true && <input onChange={setFeedSuggestion} onMouseLeave={changeFeedSugges} style={{ width:"30%", margin:"auto"}} className="form-control input-sm" value={feedbackAndSuggestionMsg} />}
        </div>
        <div className="form-group">
          <textarea className="form-control mb-2 extra-margin" placeholder="Free Text..." rows="4" data-toggle="modal" data-target="#myModal">

          </textarea>
        </div>
        <button className="btn btn-primary" onClick={submit}>submit</button>
    
      </div>
      
 

</div>
  </div>
<div  className="monument ">
  <div className="d-flex pb-4 px-3" style={{justifyContent: "spaceBetween"}}>
      <a className="text-secondary extra-margin">
        Back 
        </a>
        <a className="text-secondary extra-margin"> 
        4/8 </a>
        </div>

</div>

      
        </Fragment>
    )
}
export default FeedbackAndSuggestion












