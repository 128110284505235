import React, { Fragment, useState } from 'react';
import { Link } from 'react-router-dom';
import FeedbackAndSuggestionFinal from './FinalSurvey/feedbackAndSuggestionfinal';
// import QuestionOne from './q1';
import FinalQuestionOne from './FinalSurvey/q1final';
import FinalQuestionThree from './FinalSurvey/q3final';

const final = []
    
const FinalSurvey = () => {
    let names = "vijendra"
    const [index, setIndex] = useState(0);
    
    const previous = () => {
        if(index>0){
            setIndex(index-1)
        }else{
            alert("previous question not available")
        }
        
    } 
    const next = () => {
        if(index<final.length-1){
            setIndex(index+1)
        }else{
            alert("Next question not available")
        }
    }
    return (
        
        <Fragment>
            <div className="container">
            <h4 align="center">Final Survey View</h4>
            {/* {final.map((finalVal) => {
                return (<div>
                    {console.log(finalVal)}
                  {finalVal.type==1 && <FinalQuestionOne question = {finalVal.que} option={finalVal.option}/>}
                </div>
                )
            })} */}

            {/* ____________________________________________ */}
          {console.log(final)}
           {final.length>0 ? <>
            {final[index].type==1 && <FinalQuestionOne question = {final[index].que} option={final[index].option}/>}
            {final[index].type==3 && <FinalQuestionThree question = {final[index].que} option={final[index].option}/>}
            {final[index].type==100 && <FeedbackAndSuggestionFinal headingByProps = {final[index].heading} feedbackAndSuggestionMsgByProps={final[index].feedbackAndSuggestionMsg}/>}
            
            
           
            <div align="center">
            <button style={{margin:"1%", padding:"10px"}} align="center" className="btn btn-success" onClick={previous}> Previous </button>
            <button style={{margin:"1%", padding:"10px"}} align="center" className="btn btn-success" onClick={next}> Next </button>
            </div>
       
        </>
            : <><div>
            <h2 align="center">Survey Not Found</h2>
            <h5 align="center">Please Create a Survey Clicking on<Link to="/createssurvey"> create survey</Link> link</h5>    
            {/* <FinalQuestionThree/> */}
        </div></>} 
      
       

            </div>
        </Fragment>
    )
}
export default FinalSurvey
export { final }