import React, { useState, useEffect,useRef ,createRef} from 'react';
import { Table } from 'reactstrap';
// import Pagination from "react-js-pagination";
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Loader from './loader';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';
import Button from 'reactstrap/lib/Button';
import Pdf from "react-to-pdf";
import { CSVLink, CSVDownload } from "react-csv";
import { exportComponentAsJPEG, exportComponentAsPDF, exportComponentAsPNG } from 'react-component-export-image';
import _ from "lodash";
import { addDays } from 'date-fns';
import DateRangePicker from './dateRangePicker';
import { ButtonDropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import { PDFExport } from '@progress/kendo-react-pdf';


const PartnerWiseListTable = (props) => {
  const csvLink = useRef()
  const ref = createRef();
  const inputEl = useRef(null);
  const api = new API()
  const Id = props.match.params.id;
  // const {surveyList} = props;
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [totalData, setTotalData] = useState(0)
  const [currentDataCount, setCurrentDataCount] = useState(0)
  const [responseList, setResponseList] = useState([])
  const [loader, setLoader] = useState(true)
  const [totalItem, setTotalItem] = useState(0)
  const [firstIndexItem, setFirstIndexItem] = useState(0)
  const [responseDownloadData, setResponseDownloadData] = useState([])
  const [sortConfig, setSortConfig] = useState(config);
  const [itemPerPage, setItemPerPage] = useState(5)
  const [searchProduct, setSearchProduct] = useState('')
  const [searchPartner, setSearchPartner] = useState('')
  const [isAsending, setIsAsending] = useState(false)
  const [hide,setHide] = useState(false);
  const [startDate, setStartDate] = useState('')
  const [endDate, setEndDate] = useState('')
  const [more,setMore] = useState(false);
  const [downloadDisable,setDownloadDisable] = useState(false);


  useEffect(() => {
    responseListResponse()
    responseDownload()
  }, [])

  useEffect(() => {
    responseDownload()
    responseListResponse()
  }, [page,itemPerPage,searchProduct,searchPartner,startDate,endDate,downloadDisable])



  const responseListResponse = async () => {
  
   
    let  Data = { survey_id: Id, page_no: page, item_per_page:itemPerPage,
      start_date:startDate,
      end_date:endDate}

      if (Data.start_date === "") {
        delete Data.start_date
      }
      if (Data.end_date === "") {
        delete Data.end_date
      }
   
      const result = await api.post(config.reportPartnerList, Data)
      let responseData = result && result.data && result.data.finalResult
      let responseListData = result && result.data
    
      setResponseList(responseData)
      setTotalItem(responseListData.total_item)
      setTotalPage(responseListData.total_page)
      setTotalData(responseListData.last_index)
      setCurrentDataCount(responseListData.current_page)
      setFirstIndexItem(responseListData.first_index)
  
    

  }

  const handlePageChange = async (event, value) => {
   
    setPage(value); 
    setLoader(false)
  }

 

  const responseDownload = async () => {
    let Datas = {
      survey_id: Id,  
      partner:searchPartner,
      product:searchProduct,
      start_date:startDate,
      end_date:endDate
    }
    if (Datas.partner === "") {
      delete Datas.partner
    }
    if (Datas.product === "") {
      delete Datas.product
    }
    if (Datas.start_date === "") {
      delete Datas.start_date
    }
    if (Datas.end_date === "") {
      delete Datas.end_date
    }
    const result = await api.post(config.reportPartnerDownload, Datas)
    setResponseDownloadData(result.data)
   
    setDownloadDisable(true)
    setLoader(false)
  }

  const sortFilter =(sortBy,order)=>{
    console.log(sortBy,order,responseList,"sortBy,order");
    let tem=_.orderBy(responseList, [sortBy],[order])
    setResponseList(tem);
      setIsAsending(prev => !prev)
      setLoader(false)
  };
  const onButtonPdfClick = () => {
    inputEl.current.save();
  };
  return (

    <div className="container App site-wrap">
      <div className="card-header card-body py-3 "><h5 className="mb-0" >PARTNER WISH SURVEY RESPONSE</h5></div>
    
    {loader == true ? 
       <Skeleton count={10}/> :
       <>

     <div className="text-left py-4 d-flex bg-light card-body justify-content-between">
     <div className>
            <label for="email">Rows per page:</label>
            <select onChange={(e)=> setItemPerPage(e.target.value)} value={itemPerPage} className="form-control" id="sel1">
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
          </div>
         
        <DateRangePicker DateStart={setStartDate}  DateEnd={setEndDate}/>
        <ButtonDropdown isOpen={more} toggle={() => setMore(!more)}>
              <DropdownToggle className="btn white-btn mt-4">
                <i className="fa  fa-bars ml-2 btn color-white" />
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem>
                  <CSVLink color="black" data={responseDownloadData}
                    filename={"Survey-Response-List.csv"} ref={csvLink}
                  >Excel Download
              </CSVLink>
                </DropdownItem>
                <DropdownItem>
                <span onClick={onButtonPdfClick}>Generate Pdf</span>
                
                </DropdownItem>
                <DropdownItem filename={"Survey-Response-List.png"} onClick={() => exportComponentAsJPEG(ref)}>JPEG Download</DropdownItem>
                <DropdownItem filename={"Survey-Response-List.png"} onClick={() => exportComponentAsPNG(ref)}>PNG Download</DropdownItem>
              </DropdownMenu>
            </ButtonDropdown>
          {/* {more ? <div className="d-flex">
          <CSVLink data={responseDownloadData}
            filename={"Survey-Response-List.csv"} ref={csvLink}
          ><Button className="color-primary btn btn-secondary  mr-3 mt-4 btn btn-secondary">Excel Download</Button>
          </CSVLink>
          <Pdf targetRef={ref}  filename={"Survey-Response-List.pdf"}>
        {({ toPdf }) => <button className="color-primary btn btn-secondary  mr-3 mt-4 btn btn-secondary" onClick={toPdf}>Generate Pdf</button>}
      </Pdf>
      <button filename={"Survey-Response-List.jpeg"} className="color-primary btn btn-secondary mr-3  mt-4 btn btn-secondary" onClick={() => exportComponentAsJPEG(ref)}>
        JPEG Download
       </button>
       <button filename={"Survey-Response-List.png"} className="color-primary btn btn-secondary   mt-4 btn btn-secondary" onClick={() => exportComponentAsPNG(ref)}>
        PNG Download
       </button>
     </div> : null}
     <i className="fa  fa-bars ml-2 btn color-info mt-4"  onClick={()=>setMore(!more)}/> */}
     </div>
     
     {/* <div className="bg-dark card-body py-3 text-white"><h5 className="mb-0">{responseList && responseList.data && responseList.data.product_name}</h5></div> */}
     <PDFExport
                    forcePageBreak=".page-break"
                    paperSize={'A4'}
                    margin="1.5cm"
                    scale={0.7}
                    fileName={ 'Survey-Response-List.pdf'}
                    ref={inputEl}
                >
      <div ref={ref} className="table text-center mb-4">
        
        {responseList && responseList.length === 0 ? <div>"No Result"</div> : 
        <>
        <table className="table table-Common table-bordered table-striped" >



          <thead>
            <tr className="bg-secondary text-white">
            <th>S No.</th>
              <th><div style={{cursor:'pointer'}} onClick={() => sortFilter('product_name',isAsending? 'desc' : 'asc')}>Product <i className={isAsending ? "fa fa-sort-asc":"fa fa-sort-desc"} style={{color:'white'}} /></div></th>
              <th><div style={{cursor:'pointer'}} onClick={() => sortFilter('parnter_name',isAsending? 'desc' : 'asc')}>Partner <i className={isAsending ? "fa fa-sort-asc":"fa fa-sort-desc"} style={{color:'white'}} /></div></th>
              <th><div style={{cursor:'pointer'}} onClick={() => sortFilter('registered',isAsending? 'desc' : 'asc')}>Registered <i className={isAsending ? "fa fa-sort-asc":"fa fa-sort-desc"} style={{color:'white'}} /></div></th>
              <th><div style={{cursor:'pointer'}} onClick={() => sortFilter('total_count',isAsending? 'desc' : 'asc')}>Responses <i className={isAsending ? "fa fa-sort-asc":"fa fa-sort-desc"} style={{color:'white'}} /></div></th>
              <th><div style={{cursor:'pointer'}} onClick={() => sortFilter('response_percentage',isAsending? 'desc' : 'asc')}>Responses % <i className={isAsending ? "fa fa-sort-asc":"fa fa-sort-desc"} style={{color:'white'}} /></div></th>
              <th><div style={{cursor:'pointer'}} onClick={() => sortFilter('detractors',isAsending? 'desc' : 'asc')}>Detractor <i className={isAsending ? "fa fa-sort-asc":"fa fa-sort-desc"} style={{color:'white'}} /></div></th>
              <th><div style={{cursor:'pointer'}} onClick={() => sortFilter('passives',isAsending? 'desc' : 'asc')}>Passive <i className={isAsending ? "fa fa-sort-asc":"fa fa-sort-desc"} style={{color:'white'}} /></div></th>
              <th><div style={{cursor:'pointer'}} onClick={() => sortFilter('promoters',isAsending? 'desc' : 'asc')}>Promoter <i className={isAsending ? "fa fa-sort-asc":"fa fa-sort-desc"} style={{color:'white'}} /></div></th>
              <th><div style={{cursor:'pointer'}} onClick={() => sortFilter('nps_score',isAsending? 'desc' : 'asc')}>NPS Score <i className={isAsending ? "fa fa-sort-asc":"fa fa-sort-desc"} style={{color:'white'}} /></div></th>
            
            </tr>
          </thead>
           <tbody>
           {responseList && responseList.map((data, index) => (
           <tr key={index}>
             <th scope="row">{firstIndexItem + index}</th>
             <td>{data.product_name}</td>
             <td>{data.parnter_name}</td>
             <td>{data.registered}</td>
             <td>{data.total_count}</td>
             <td>{data.response_percentage} %</td>
             <td>{data.detractors}</td>
             <td>{data.passives}</td>
             <td>{data.promoters}</td>
             <td>{data.nps_score} <span className="curtom-circle" style={{backgroundColor:data.color_code}}></span></td>
           </tr>
           ))}

         </tbody>

        </table>
        <div className="text-center d-flex justify-content-between align-items-center">
            <Pagination count={totalPage} page={page} onChange={handlePageChange} variant="outlined" color="primary" align="center" />
           
            <h5 className="float-left mb-0">{firstIndexItem} - {totalData} of {totalItem} items </h5>
          
          </div>
        </>
}

     </div>
    </PDFExport>
     </>
}   
</div>
  );
}

export default PartnerWiseListTable;