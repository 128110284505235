import React, { useState } from 'react';


const DoYourSurvey = (props) => {
    const [radioBtn, setRadioBtn] = useState(false)
    const [radioArr, setRadioArr] = useState([])
    const [textArea, setTextArea] = useState(false)
    const [textAreaArr, setTextAreaArr] = useState([])
    const [checkbox, setCheckbox] = useState(false)
    const [checkboxArr, setCheckboxArr] = useState([])
    const [textField, setTextField] = useState(false)
    const [textFieldArr, setTextFieldArr] = useState([])
    const [imageChoose, setImageChoose] = useState(false)
    const [imageChooseArr, setImageChooseArr] = useState([])


    const radioHandle = () => {
        if (radioBtn) {
            setRadioArr([])
            setRadioBtn(!radioBtn)
        } else {
            setRadioArr([{ label: `radio ${radioArr.length + 1}`, isSelected: false }])
            setRadioBtn(!radioBtn)
        }
    }

    const textAreaHandle = () => {
        if (textArea) {
            setTextAreaArr([])
            setTextArea(!textArea)
        } else {
            setTextAreaArr([{ label: `TextField ${textAreaArr.length + 1}`, value: "" }])
            setTextArea(!textArea)
        }
        
    }

    const textFieldHandle = () => {
        if (textField) {
        setTextFieldArr([])
        setTextField(!textField)
    } else {
        setTextFieldArr([{ label: `TextField ${textFieldArr.length + 1}`, value: "" }])
        setTextField(!textField)
    }
        
    }
    const checkboxHandle = () => {
        if (checkbox) {
        setCheckboxArr([])
        setCheckbox(!checkbox)
    } else {
        setCheckboxArr([{ label: `Checkbox ${checkboxArr.length + 1}` , isSelected: false }])
        setCheckbox(!checkbox)
    }
        
    }

    const chooseImageHandle = () => {
         if (imageChoose) {
        setImageChooseArr([])
        setImageChoose(!imageChoose)
    } else {
        setImageChooseArr([{ label: `Image ${imageChooseArr.length + 1}` , isSelected: false }])
        setImageChoose(!imageChoose)
    }
    }

   

    const updateItems = (action, field, index) => {
        if (field === 'radio') {
            if (action === 'plus') {
                setRadioArr((prev) =>
                    [...prev, { label: `radio ${prev.length + 1}`, isSelected: false }]
                )
            } else {
                let temp = [...radioArr]

                temp.splice(index, 1)
                setRadioArr(temp)
            }
        } else if (field === 'textArea') {

            if (action === 'plus') {
                setTextAreaArr((prev) =>
                    [...prev, { label: `TextField ${prev.length + 1}`, value: "" }]
                )
            } else {
                let temp = [...textAreaArr]

                temp.splice(index, 1)
                setTextAreaArr(temp)
            }

        }
        else if (field === 'textField') {

            if (action === 'plus') {
                setTextFieldArr((prev) =>
                    [...prev, { label: `TextField ${prev.length + 1}`, value: "" }]
                )
            } else {
                let temp = [...textFieldArr]

                temp.splice(index, 1)
                setTextFieldArr(temp)
            }

        }
        else if (field === 'checkbox') {

            if (action === 'plus') {
                setCheckboxArr((prev) =>
                    [...prev, { label: `checkbox ${prev.length + 1}`, value: "" }]
                )
            } else {
                let temp = [...checkboxArr]

                temp.splice(index, 1)
                setCheckboxArr(temp)
            }

        }
        else if (field === 'image') {

            if (action === 'plus') {
                setImageChooseArr((prev) =>
                    [...prev, { label: `Image ${prev.length + 1}`, value: "" }]
                )
            } else {
                let temp = [...imageChooseArr]

                temp.splice(index, 1)
                setImageChooseArr(temp)
            }

        }
       
    }

    return (
        <section className="dashboard bg-light " >
            <div className="row mx-0">
                <div className="col-lg-3">
                    <div className="card card-custom-height rounded-0 border-0">
                        <div className="card-body">
                            <h6 className="mb-0 text-dark">Select Survey Inputs</h6>
                            <p><small>Please Select Desire input type you want to use!</small></p>
                            <div  className="card rounded-0 card-border mt-5 mb-3">
                                <div className="">
                                    <div  className="card-flex">
                                        <p onClick={radioHandle} className="mb-0 font-medium">Radio Button</p>
                                        <div onClick={() => updateItems('plus', 'radio')} className="circle-btn">
                                            +
                                    </div>

                                    </div>
                                </div>
                            </div>
                            <div   className="card rounded-0 card-border mb-3">
                                <div className="">
                                    <div  className="card-flex">
                                        <p onClick={textFieldHandle} className="mb-0 font-medium">Textbox</p>
                                        <div onClick={() => updateItems('plus', 'textField')} className="circle-btn">
                                            +
                                    </div>

                                    </div>
                                </div>
                            </div>
                            <div className="card rounded-0 card-border mb-3">
                                <div className="">
                                    <div className="card-flex">
                                        <p onClick={textAreaHandle} className="mb-0 font-medium">Textarea</p>
                                        <div onClick={() => updateItems('plus', 'textArea')} className="circle-btn">
                                            +
                                    </div>

                                    </div>
                                </div>
                            </div>
                            <div className="card rounded-0 card-border mb-3">
                                <div className="">
                                    <div className="card-flex">
                                        <p onClick={checkboxHandle} className="mb-0 font-medium">Checkbox</p>
                                        <div onClick={() => updateItems('plus', 'checkbox')} className="circle-btn">
                                            +
                                    </div>

                                    </div>
                                </div>
                            </div>
                            <div className="card rounded-0 card-border mb-3">
                                <div className="">
                                    <div className="card-flex">
                                        <p onClick={chooseImageHandle} className="mb-0 font-medium">Images</p>
                                        <div onClick={() => updateItems('plus', 'image')} className="circle-btn">
                                            +
                                    </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-6 px-0">
                    <div className="card  card-custom-height rounded-0 border-0">
                        <div className="card-body">
                            <div className="card-flex">
                                <div className="">
                                    <h6 className="mb-0 text-dark">Create Questions</h6>
                                    <p className="mb-0"><small>Please Select Desire input type you want to use!</small></p>
                                </div>
                                <buttom className="btn btn-green font-regular">View Existing Questions</buttom>
                            </div>

                            <div className="form-group mt-5">
                                <label for="comment mb-3"><strong>Enter Question ?</strong></label>
                                <textarea className="form-control mt-3" rows="5" id="comment"></textarea>
                            </div>


                            {radioBtn && radioArr.map(({ label, isSelected }, index) => (
                                <div className="col-lg-6">
                                <div key={index} className="form-check">
                                    <input className="form-check-input d-block" type="radio" name="exampleRadios" id={label} value={label} checked />
                                    <label className="form-check-label" for={label}>
                                        {label}
                                    </label>
                                    <label onClick={() => updateItems('minus', 'radio', index)} className="circle-btn">
                                        x
                                    </label>
                                </div>
                                </div>
                            ))
                            }

                        

                            {textField && textFieldArr.map(({label,value},index) => (
                                <div key={index} className="form-group mt-5">
                                <label for="comment mb-3"><strong>{label}</strong></label>
                                <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder={value} />
                                <div onClick={() => updateItems('minus', 'textField', index)} className="circle-btn">
                                        x  
                                    </div>
                            </div>
                             )) }

                            {textArea && textAreaArr.map(({label,value},index) => (
                                <div key={index} className="form-group mt-5">
                                <label for="comment mb-3"><strong>{label}</strong></label>
                                <textarea className="form-control mt-3" rows="5" id="comment" value={value}></textarea>
                                <div onClick={() => updateItems('minus', 'textArea', index)} className="circle-btn">
                                        x  
                                    </div>
                            </div>
                            ))
                            }



                            {checkbox && checkboxArr.map(({ label, isSelected }, index) => (
                            <div key={index} className="form-check">
                                <input type="checkbox" className="form-check-input" id={label} value={label} />
                                <label className="form-check-label" for={label}>{label}</label>
                                <label onClick={() => updateItems('minus', 'checkbox', index)} className="circle-btn">
                                        x
                            </label>
                            </div> ))}


                            {imageChoose &&  imageChooseArr.map(({ label, value }, index) => (
                                <div key={index} className="form-group">
                                    <label for="comment mb-3 "><strong>{label}</strong></label>
                                    <input type="file" className="form-control-file btn btn-primary" id={label}  value={value}/><label onClick={() => updateItems('minus', 'image', index)} className="circle-btn">
                                        x
                            </label>
                                </div>
                            ))    
                        }


                        </div>
                        <div className="card-footer bg-white ">
                            <div className="card-flex">
                                <a className="text-dark">Save As Draft</a>
                                <div className="">
                                    <buttom className="btn btn-link text-secondary font-regular">Discard</buttom>
                                    <buttom className="btn btn-green font-regular">Save</buttom>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="col-lg-3">
                    <div className="card card-custom-height rounded-0 border-0">
                        <div className="card-body ">

                            <h6 className="mb-0 text-dark">NPS Score Based Questions</h6>
                            <p><small>Please Select Desire input type you want to use!</small></p>
                            <div className="alert alert-green font-medium py-4 mb-4 rounded-0 mt-5">
                                Questions 9 - 10
                            </div>

                            <div className="alert alert-yellow font-medium py-4 mb-4 rounded-0">
                                Questions 7 - 8
                            </div>

                            <div className="alert alert-red font-medium py-4 mb-4 rounded-0">
                                Questions 0 - 6
                            </div>
                        </div>

                    </div>
                </div>

            </div>
        </section>
    );
}

export default DoYourSurvey;