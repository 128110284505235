import React, { useState } from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';

const ModalDiv = (props) => {
  const {
    buttonLabel,
    className,title,buttonPrimary,buttonSubmit,handleSubmit,content,user, classname, action
  } = props;

  const [modal, setModal] = useState(false);

  const creatUser =() => {
    //   alert(handleSubmit)
    handleSubmit()
    setModal(!modal)    
    
        
    }
  const toggle = () => {
    
   setModal(!modal);
  }

  return (
    <div>
        {
            action === "edit" ?<><i className={classname} onClick={toggle}></i></>
            : <Button  className={classname} onClick={toggle}>{buttonLabel}</Button>  
        }
      
    
      <Modal isOpen={modal} toggle={toggle} className={className}>
        <ModalHeader style={{backgroundColor:"#6f4e37", color:"white"}} toggle={toggle}>{title}</ModalHeader>
        <ModalBody>
         <h5 style={{color:"#6f4e37"}}>{content}</h5>
        </ModalBody>
        <ModalFooter>
          <a color="primary" style={{cursor: "pointer", marginRight:"20px"}} type="link" onClick={toggle}>Close</a>{' '}
          <Button color="secondary" type="submit" onClick={creatUser}><h6>{buttonSubmit}</h6></Button>
        </ModalFooter>
      </Modal>
    </div>
  );
}

export default ModalDiv;