import React, { useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import solidGauge from "highcharts/modules/solid-gauge.js";
import highchartsMore from "highcharts/highcharts-more.js"

highchartsMore(Highcharts);
solidGauge(Highcharts);

const NpsSpeedoChart = (props) => {

    const {name,data, title} = props
const options = {
   
chart: {
    type: 'gauge',
    plotBackgroundColor: null,
    plotBackgroundImage: null,
    plotBorderWidth: 0,
    plotShadow: false,
    height:400,
    
},
credits: {
    enabled: false
},
title: {
    text: title
},
exporting: {
    enabled: false
  },

pane: {
    startAngle: -150,
    endAngle: 150,
    background: [{
        backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
                [0, '#FFF'],
                [1, '#333']
            ]
        },
        borderWidth: 0,
        outerRadius: '109%'
    }, {
        backgroundColor: {
            linearGradient: { x1: 0, y1: 0, x2: 0, y2: 1 },
            stops: [
                [0, '#333'],
                [1, '#FFF']
            ]
        },
        borderWidth: 1,
        outerRadius: '107%'
    }, {
        // default background
    }, {
        backgroundColor: '#DDD',
        borderWidth: 0,
        outerRadius: '105%',
        innerRadius: '103%'
    }]
},

// the value axis
yAxis: {
    min: -100,
    max: 100,

    minorTickInterval: 'auto',
    minorTickWidth: 1,
    minorTickLength: 10,
    minorTickPosition: 'inside',
    minorTickColor: '#666',

    tickPixelInterval: 40,
    tickWidth: 2,
    tickPosition: 'inside',
    tickLength: 10,
    tickColor: '#666',
    labels: {
        step: 2,
        rotation: 'auto'
    },
    title: {
        text: name,
        y:22
    },
    plotBands: [{
        from: 0,
        to: 40,
        color: '#DDDF0D' // green
    }, {
        from: 40,
        to: 100,
        color: '#55BF3B' // yellow
    }, {
        from: -100,
        to: 0,
        color: '#DF5353' // red
    }]
},

series: [{
    name: 'Current NPS Score: ',
    data: [data],
    tooltip: {
        valueSuffix: ''
    }
}]

    
}

    return ( 
        <HighchartsReact   
            highcharts={Highcharts}
            options={options}
            
        />
     );
}
 
export default NpsSpeedoChart;