import React, {Component, Fragment, useEffect, useState} from 'react';
import { get } from 'lodash';
import DumpDataTable from '../../common/dumpDataTable';

const DumpData = (props) => {


  
    return (
        <Fragment>
        
        <div className="App site-wrap">
            <DumpDataTable />
        </div>
        
        <div className="mb-4 mt-4"></div>
</Fragment>

    )
}

export default DumpData; 