import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Treemap from "highcharts/modules/treemap.js"
import exporting from 'highcharts/modules/exporting';
import heatmap from 'highcharts/modules/heatmap';





heatmap(Highcharts);
exporting(Highcharts);
Treemap(Highcharts);
const ApplicationUsageChart = (props) => {
 const {appUseComparisionResultData,Name}= props
 
 
const options = {
    colorAxis: {
        minColor: '#f96d6d',
        maxColor: Highcharts.getOptions().colors[0]
    },
    exporting: {
        enabled: false
      },
     series:[{
        type: 'treemap',
        layoutAlgorithm: 'squarified',
        name: Name,
        data: appUseComparisionResultData 
    }],
    title: {
        text: "My Application usage comparison w.r.t Other Applications",
        align: 'left'
    }
}

    return ( 
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
            
        />
     );
}
 
export default ApplicationUsageChart;