import React, {Fragment, PureComponent, useEffect, useState,  } from 'react';
import './reportCommon.css';
import ReactPaginate from 'react-paginate';

const data = [
    {firstname : 'a', lastname : 'ab', email: 'email@gmail.com'},
    {firstname : 'a', lastname : 'ab', email: 'email@gmail.com'},
    {firstname : 'a', lastname : 'ab', email: 'email@gmail.com'},
    {firstname : 'a', lastname : 'ab', email: 'email@gmail.com'},
    {firstname : 'a', lastname : 'ab', email: 'email@gmail.com'},

]

export class ReportCommon extends PureComponent {

    constructor(props) {
        super(props)
        this.state = {
            offset: 0,
            tableData: [],
            orgtableData: [],
            perPage: 5,
            currentPage : 0
        }
    }


componentDidMount(){
    this.getData();
} 

    getData=()=> {
      var Data = data;
      var slice = Data.slice(this.state.offset, this.state.offset+this.state.perPage)
      this.setState({
          pageCount: Math.ceil(Data.length / this.state.perPage),
          orgtableData : Data,
          tableData: Data

      })
    }

    selectReportOptions =(e)=>{
        const {value} = e.target
        alert ("report type is " +JSON.stringify(value))
    }

    render(){

    return (
        <Fragment>
             <div style={{ marginLeft: "1.5%" }} >
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mt-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                            <h5 style={{textAlign:"left"}}><b>Report Portal</b> </h5>
                                            </div>
                            <div className="card-body" style={{minHeight:"400px"}}>
                            
                          <div className="bodyHeader">
                            <div className="selectReportType">
 
                            <select required className="form-control reportSelect" name="reportType" placeholder="" onChange={this.selectReportOptions}>
                                    <option  selected hidden>Select Report...</option>
                                    <option  value="report option 1" >Report 1</option>
                                    <option  value="report option 2" >Report 2</option>
                                    <option  value="report option 3" >Report 3</option>
                                    <option  value="report option 4" >Report 4</option>
                                    <option  value="report option 5" >Report 5</option>
                                    <option  value="report option 6" >Report 6</option>
                                    <option  value="report option 7" >Report 7</option>
                                    <option  value="report option 8" >Report 8</option>
                                    <option  value="report option 9" >Report 9</option>
                                    <option  value="report option 10" >Report 10</option>

                                    {/* <option  value={this.state.roleType} onChange={this.handleChange} name="roleType" disabled selected hidden>Select Role Type...</option> */}
                                </select>
                                </div>
                             <div className="downloadbuttondiv">
                                <select required className="form-control downloadbutton" name="reportType" placeholder="">
                                    <option  selected hidden>Download</option>
                                    <option  value="report option 1" >Excle</option>
                                    <option  value="report option 1" >Pdf</option>

                                    {/* <option  value={this.state.roleType} onChange={this.handleChange} name="roleType" disabled selected hidden>Select Role Type...</option> */}
                                </select>
                                </div>
                                </div>

                                    <div class="container">
                                    <h2>Report</h2>
                                    <p>Download report in pdf & excel</p>            
                                    <table class="table table-bordered">
                                        <thead>
                                        <tr>
                                            <th>Firstname</th>
                                            <th>Lastname</th>
                                            <th>Email</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                       
                                        <tr>
                                            <td>John</td>
                                            <td>Doe</td>
                                            <td>john@example.com</td>
                                        </tr>
                                        <tr>
                                            <td>Mary</td>
                                            <td>Moe</td>
                                            <td>mary@example.com</td>
                                        </tr>
                                        <tr>
                                            <td>July</td>
                                            <td>Dooley</td>
                                            <td>july@example.com</td>
                                        </tr>
                                        </tbody>
                                    </table>

                                    <div className="paginationDiv">
                                    <ReactPaginate
                                    previousLabel={"Prev"}
                                    nextLabel={"Next"}
                                    breakLabel={"..."}
                                    breakClassName={"break-me"}
                                    pageCount={this.state.pageCount}
                                    marginPagesDisplayed={2}
                                    pageRangeDisplayed={5}
                                    onPageChange={this.handlePageClick}
                                    containerClassName={"pages pagination"}
                                    activeClassName={"active"}/>
                                     </div>
                                </div>
            
                </div>
                </div>
                </div>


                </div>
                            
                             
        </div>
    </div>
  
    </div>

</div>
</Fragment>
    )
}
}
export default ReportCommon