import React, { useState, useEffect } from 'react';
import { Table } from 'reactstrap';
import { makeStyles } from '@material-ui/core/styles';
import Pagination from '@material-ui/lab/Pagination';
import Loader from '../common/loader';
import Skeleton, { SkeletonTheme } from 'react-loading-skeleton';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import { get } from 'lodash';
import { Link } from 'react-router-dom';


const ReportTable = (props) => {
  const api = new API()
  // const {surveyList} = props;
  const [page, setPage] = useState(1)
  const [totalPage, setTotalPage] = useState(1)
  const [totalData, setTotalData] = useState(0)
  const [currentDataCount, setCurrentDataCount] = useState(0)
  const [surveyList, setSurveyList] = useState([])
  const [loader, setLoader] = useState(true)
  const [totalItem, setTotalItem] = useState(0)
  const [firstIndexItem, setFirstIndexItem] = useState(0)

  useEffect(() => {
    surveyListResponse()
  }, [])





  const surveyListResponse = async (value=1) => {
    let Data
    
      Data = { page_no: value}
  

    if (page <= totalPage) {
      const result = await api.post(config.reportSurveyList, Data)
      let responseData = result && result.data && result.data.finalResult
      let surveyResponseData = result && result.data
      setLoader(false)
      // console.log("history data is", result.data.doc.redeemPoint)
      setSurveyList(responseData)
      setTotalItem(get(surveyResponseData,"total_item",0))
      setTotalPage(surveyResponseData.total_page)
      setTotalData(surveyResponseData.last_index)
      setCurrentDataCount(surveyResponseData.current_page)
      setFirstIndexItem(surveyResponseData.first_index)

    }

  }

  const handlePageChange = async (event, value) => {
    setLoader(true)
    console.log(`active page is ${value}`);

    setPage(value);
    surveyListResponse(value);
  }

  const viewById = (Id) => {

  }

  return (

    <div className="container">
      <div className="card-header card-body py-3 "><h5 className="mb-0 ">SURVEY LIST</h5></div>
    
       {loader == true ? 
          <Skeleton count={10}/> :
          <>
        <div className="text-left py-4 d-flex bg-light card-body justify-content-between">
          {/* <div className>
            <label for="email">Rows per page:</label>
            <select onChange={handleSelectedPartner} value={itemPerPage} className="form-control" id="sel1">
              <option value={5}>5</option>
              <option value={10}>10</option>
              <option value={20}>20</option>
            </select>
          </div> */}
          {/* <div className="d-flex">
            <div className="mr-3">
              <label for="email">Start End</label>
              <input type="date" className="form-control" placeholder="Enter email" id="email"/>
            </div>
            <div className="">
              <label for="email">Email address:</label>
              <input type="date" className="form-control" placeholder="Enter email" id="email"/>
            </div>
          </div> */}
        </div>
      <div className="table text-center mb-4">
        <table className="table table-Common table-bordered table-striped" >
          <thead className="bg-secondary text-white">
            <tr>
              <th>S No.</th>
              <th>Id</th>
              <th>Product</th>
              <th>Status</th>
              <th>Start Date</th>
              <th>Survey Type</th>
              <th>Survey Created Time</th>
              <th colSpan="2">Actions</th>
            </tr>
          </thead>
          <tbody>
            {surveyList && surveyList.map((data, index) => (
              <tr key={index}>
                <td className="table-td-1" scope="row">{firstIndexItem + index}</td>
                <td className="table-td-1">{data.Id} </td>
                <th className="table-td">{data.Product}</th>
                <td className="table-td">{data.Status}</td>
                <td className="table-td-date">{data["Start Date"]}</td>
                <td className="table-td">{data["Survey Type"]}</td>
                <td className="table-td-date">{data["Survey Created Time"]}</td>
                <td className="table-td">
                  {(data.Status == "Active" ?
                    <>
                      {/* <div className=" read-date badge badge-secondary text-white btn-block">
                        <Link className="text-white"
                          to={`/listview/${data.Id}`} onClick={() => viewById(data.Id)}
                        >
                          <i className="fa fa-eye" />
                          <span className=""> {"View"}</span>

                        </Link>

                      </div> */}

                      <div className="read-date badge badge-dark btn-block text-white">
                        <Link className="text-white"
                          to={`/partnerwiselistview/${data.Id}`} onClick={() => viewById(data.Id)}
                        >
                          <i className="fa fa-eye " />
                          <span className=""> {"Partner view dump data"}</span>

                        </Link>
                      </div>
                    </> : "No Record")}</td>

              </tr>
            ))}

          </tbody>
        

        </table>
        <div className="text-center d-flex justify-content-between align-items-center pb-5">
            <Pagination count={totalPage} page={page} onChange={handlePageChange} variant="outlined" color="primary" align="center" />
           
            <h5 className="float-left mb-0">{firstIndexItem} - {totalData} of {totalItem} items </h5>
          
          </div>
      </div>
      </>
}
   </div>
  );
}

export default ReportTable;