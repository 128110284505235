import React, { useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Timeline from "highcharts/modules/timeline.js"
import {get} from 'lodash'





Timeline(Highcharts);
const SentimentBarChart = (props) => {

    
const options = {
    chart: {
        type: 'bar',
        height: '400'
    },
    title: {
        text: 'Reason for Non Conversion'
    },
    xAxis: {
        categories: ['Service', 'Loan', 'Support', 'Process', 'Transaction', 'Application']
    },
    yAxis: {
        categories: ['0%', '1%', '2%', '3%', '4%', '5%','6%','7%'],
        // min: 0,
        title: {
            // text: 'Total fruit consumption'
        }
    },
    legend: {
        reversed: true
    },
    plotOptions: {
        series: {
            stacking: 'normal'
        }
    },
    series: [{
         
        // name: 'Jane',
        data: [7, 5, 4, 2, 1],
        color:"#6f4e37",
    }]
}

    return ( 
        <HighchartsReact 
            highcharts={Highcharts}
            options={options}
            
        />
     );
}
 
export default SentimentBarChart;