import React, { Fragment, useEffect, useState } from 'react';

import RatingWiseComment from '../../charts/ratingWiseCommentChart';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash'
import ModalDiv from '../../../common/model';
import ModalPartner from '../../../common/modalForPartner';
import  './RecentFeedbacksAndSuggestions.css';
import CommentModel from '../../../common/commentModel';
import { Loader } from '../../../common/loader'
import Skeleton from 'react-loading-skeleton';
function RecentFeedbacksAndSuggestions() {
    const api = new API()
    
    const [selectChart,setSelectChart] = useState('All')
    const [ comments, setComments] = useState([])
    const [modal, setModal] = useState(false);
    const [modalComment, setModalComment] = useState(false);
    const [selectedPartner, setSelectedPartner] = useState('')
    const [open,setOpen] = useState(false);
    const [isLoad,setIsLoad] = useState(true);
    // const [commentId, setCommentId] = useState();
    // const [commentFatch,setCommentFatch] = useState([]);
    const [showAllCommentsGet,setShowAllComments] = useState([]);
    const [skeletonLoader, setSkeletonLoader] = useState([false])


    useEffect(() => {
        suggectionAndFeedback()
    },[selectChart])
    
 const onClickChange= (e) =>{
    setSkeletonLoader(true)
    setSelectChart(e.target.name)
    
 }

    // suggectionAndFeedback //
    const suggectionAndFeedback = async()=> {
        let suggectionAndFeedbackResult = await api.get(config.dashboardFeedbackAndSuggestion,selectChart==="All" ? false:true,{type:selectChart});
        let suggectionAndFeedbackData = get(suggectionAndFeedbackResult, 'data','')
        setComments(suggectionAndFeedbackData)
        setIsLoad(false);
        setSkeletonLoader(false)
    }
  

    const handleModel=(id, rating)=> {
        // alert(id, rating)
        // setCommentId(id);
        if(!open){
            clickedComment(id);
        }
        
        // console.log("__________________",id)
        setOpen(!open)
        }
      const clickedComment = async(comment_id)=> {
        let clickedComment = await api.get(config.dashboardCommentDetailClicked,true,{id:comment_id});
        let commentFatchForPopup = get(clickedComment, 'data','')
        // setCommentFatch(commentFatchForPopup)
        setShowAllComments(commentFatchForPopup)
        // alert(JSON.stringify(commentFatch))
        // setOpen(!open)
        // setIsLoad(false);
    }
    const handleModelShowAllMessage=() =>{
        if(!open){
            showAllCommentscmt();
        }
        setOpen(!open)
    }
    const showAllCommentscmt = async() => {
    
        let ShowAll_comments = await api.get(config.dashboardShowAllComments,selectChart==="All" ? false:true,{type:selectChart})
        let ShowA = ShowAll_comments.data
        let cmt = get(ShowA, 'comments')
        // alert(JSON.stringify(cmt))
        setShowAllComments(cmt)
    }
    // const toggle = () => {
    
    //     setModal(!modal);
    //    }
    //    const handleButton = (e)=> {
    //      const {name} = e.target;
    //      setSelectedPartner(name)
    //      alert("selected partner", e)
    //      // console.log("selected", e)
    //    }
    //    const handleComment = (e)=> {
    //     const {name} = e.target;
    //     setModalComment(name)
    //     alert("selected partner", e)
    //     // console.log("selected", e)
    //   }
    return (

                <div className="col-md-12 mt-3">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card bg-white left-right">
                        <div className="card-header d-flex justify-content-between">
                            <div className="d-flex align-items-center">
                                <h5 className="primary-cl recentFeedbackAndSuggestion1"><b>Know inputs Of Your Customers - Feedbacks & Suggestions </b></h5>
                                
                            </div>
                            <div className="cls">
                                <button onClick={onClickChange} name="All" className={selectChart == "All" ?"btn bg-col-sky active":"btn bg-col-sky"}>All</button>
                                <button onClick={onClickChange}  name="Promoter" className={selectChart== "Promoter" ?"btn bg-col-sky active":"btn bg-col-sky"}>Promoter</button>
                                <button onClick={onClickChange}  name="Passive" className={selectChart== "Passive" ?"btn bg-col-sky active":"btn bg-col-sky"}>Passive</button>
                                <button onClick={onClickChange}  name="Detractor" className={selectChart== "Detractor" ?"btn bg-col-sky active":"btn bg-col-sky"}>Detractor</button>
                                {/* <button onClick={suggectionAndFeedback} name="All" className={selectChart == "All" ?"btn bg-col-sky active":"btn bg-col-sky"}>All</button>
                                <button onClick={suggectionAndFeedback0}  name="Promoter" className={selectChart== "Promoter" ?"btn bg-col-sky active":"btn bg-col-sky"}>Promoter</button>
                                <button onClick={suggectionAndFeedback1}  name="Passive" className={selectChart== "Passive" ?"btn bg-col-sky active":"btn bg-col-sky"}>Passive</button>
                                <button onClick={suggectionAndFeedback2}  name="Detractor" className={selectChart== "Detractor" ?"btn bg-col-sky active":"btn bg-col-sky"}>Detractor</button> */}
                            </div>
                        </div>
                    
                    { isLoad == true ? <> 
                    <Loader/>
                    </> : <> 
                        <div className="card-body">
                            <div className="row">
                                <div className="col-md-5">
                                {skeletonLoader == true ?
                                            <Skeleton count={18}/> 
                                         :<>
  
                                {comments && comments.length > 0 ?<>
                                    {comments.map((comment,index) => (
                                      
                                    <div key={comment.id} className="card-box comments" 
                                    onClick={()=>handleModel(comment.id, comment.rating_answer)}>
                                        {/* {alert(JSON.stringify(comment))} */}

                                        <div className="recentFeedbackAndSuggestion2">
                                        <CommentModel commentDetailResultData={showAllCommentsGet} handleModel={handleModel} open={open}/>
                                        </div>
                                    <h5 data-toggle="tooltip" data-placement="right" title="Click to see full comments!">{comment.rating_answer}</h5>
                                        
                                    </div>
                                    
                                ))}
                                 </> : <>
                                 <h5 data-toggle="tooltip" data-placement="right" title="Click to see full comments!">No Comments</h5>
                                  </>}
                                  </>}
                            
                               
                                    {/* <div className="card-box" style={{textAlign:'left'}}>
                                    

                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap …..
                                    </div>
                                    <div className="card-box" style={{textAlign:'left'}}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap …..
                                    </div>
                                    <div className="card-box" style={{textAlign:'left'}}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap …..
                                    </div>
                                    <div className="card-box" style={{textAlign:'left'}}>
                                    Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap …..
                                    </div> */}

                                {/* <button style={{float: "right"}}  className="btn btn-link">Show All Message</button> */}
                                {/* <ModalDiv title={"All Comments"} content={`All comments`} 
                                     classname={"btn btn-link text-white"} buttonLabel={"Show All Message"}  buttonSubmit={"Ok"} modal={modal} toggle={toggle} handleButton={handleButton}/>
                               */}
                                    
                                <a className="recentFeedbackAndSuggestion3"  onClick={handleModelShowAllMessage}> <u>Show All Message</u>
                                {open == !open ? <CommentModel commentDetailResultData={showAllCommentsGet}  handleModel={handleModelShowAllMessage} open={open} buttonLabel={""}/>
                                : <span></span> }
                                    </a>
                                {/* <ModalPartner title="List Of All Upi Partner" 
                                classname={"float: 'right'"} buttonLabel={"Show All Comments"}
                                action={"comments"}/>                     */}
                                    
                                </div>
                                
                                {/* <a color="primary" className="primary" style={{fontSize: "10px", cursor: "pointer", float:"right", color:"blue"}} type="link" ><b><u>Show All Message</u></b></a> */}

                                <div className="col-md-7">
                                {skeletonLoader == true ?
                                    <Skeleton count={18}/> 
                                    :<>
                                    <RatingWiseComment Name={selectChart}/>
                                </>}    
                                </div>
                            </div>
                            
                        </div>
                        </>}
                        </div>
                    
                    </div>
                    </div>
                </div>
                              )
}
export default RecentFeedbacksAndSuggestions