import React, { useEffect, useState } from 'react';
import QuestionsAndResponsesChart from '../../charts/QuestionsAndResponsesChart'
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash'
import QuestionsAndResponsesChartSub from '../../charts/QuestionsAndResponsesChartSub';
import { Loader } from '../../../common/loader'

function UpiQuestionAndResponse(props){

  const api = new API()
  const {questionAndResponse,dpp,questionAndResUpi,parameterArr,questionAndResUpis,chartsUpiMore,loader,noMoreUpi} = props;
console.log(questionAndResponse,'questionAndResponse')
  const [questionShow,setQuestionShow] = useState(false)
  const [question,setQuestion] = useState([])
console.log(noMoreUpi,'noomoreupi')
  const questionShowHide =()=>{
    setQuestionShow(!questionShow)
  }
  const clicked = (e) => {
    

    const {qid,option_id,value} = e.point.options
    
  const product_id = value
    questionShowHide()
   const rating_type = option_id
    // questionAndResUpis(product_id,rating_type,qid)
    questionAndResUpis(1,rating_type,qid, 'ratingType')
  }

  const clickedUpi = (e) => {
    const product_id = 1
      questionShowHide()
      const rating_type = e.point.options.value
    // questionAndResUpis(product_id,rating_type)
      questionAndResUpis(1,rating_type,0,'ratingType')
    }

  
  
  
    return(
      <div className="card">
        <div className="card-header">
          <h5 className="upiQuesAndRes2">Product Name 1 || Questions &amp; Responses</h5>
        </div>
      
        <div className="card-body">
          <div className="row">
          <div className="col-md-6">
            {loader == true && dpp && dpp.data && dpp.data.length < 0 ? <Loader/> : 
              <div className="card-box">
              <QuestionsAndResponsesChart questionAndResponseChart={dpp} onClick={(e)=>clickedUpi(e)} title={"Product Name 1 Promoter, Passive, Detractor Rating"}/>
              </div>
              }
            </div>
            {/* <div className="col-md-6">
              <div className="card-box">
              <QuestionsAndResponsesChart questionAndResponseChart={parameterArr} onClick={(e)=>clicked(e)} title={"Parameter wise responses"}/>
              </div>
            </div> */}
            
            {loader == true && Array.isArray(questionAndResponse) && questionAndResponse &&  questionAndResponse.data && questionAndResponse.data.length > 0 ? <Loader/> : 
            <>
            {Array.isArray(questionAndResponse) && questionAndResponse.length > 0 && questionAndResponse.map((Item,index)=>(
            <div key={index} className="col-md-6">
               <div  className="card-box">
              <QuestionsAndResponsesChartSub questionAndResponseChart={get(Item,"options",{})} onClick={(e)=>clicked(e)} title={Item.question}/>
              </div> 
            </div>))}
            {noMoreUpi &&<div  className="col-md-6">
               <div  className="card-box">
              <div>{noMoreUpi}</div>
               </div>
               </div>}
            </>
}
            {/* <div className="col-md-6">
              <div className="card-box">
              <QuestionsAndResponsesChart questionAndResponseChart={parameterArr} onClick={(e)=>clicked(e)} title={"Parameter wise responses"}/>
              </div>
            </div> */}
             {/* {Array.isArray(chartsUpiMore) && chartsUpiMore.length > 0 && chartsUpiMore.map((Item,index)=>{
               return(
              <div key={index} className="col-md-6">
               <div  className="card-box">
              <QuestionsAndResponsesChart questionAndResponseChart={get(Item,"options",{})} onClick={(e)=>clicked(e)} title={Item.question}/>
              </div> 
            </div>
            )})} */}
           
          </div>
        </div>
      
      </div>
                       
    )
}
export default UpiQuestionAndResponse