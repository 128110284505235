import React, { useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Moment from 'moment'



const SplineChart = (props) => {
const {dailyNpsTrends} = props;



const condition = Boolean(Math.round(Math.random()))
const options = {
    rangeSelector: {
        selected: 1
    },
    chart: {
        type: 'spline',
        scrollablePlotArea: {
            minWidth: 500,
            maxHeight:450,
            scrollPositionX: 1
          }
    },
    title: {
        text: 'All Products Daily Trend View - Click on individual graph for better picture or select multiple products for comparison ',
        align: 'left'
    },
    scrollbar: {
        barBackgroundColor: 'gray',
        barBorderRadius: 7,
        barBorderWidth: 0,
        buttonBackgroundColor: 'gray',
        buttonBorderWidth: 0,
        buttonBorderRadius: 7,
        trackBackgroundColor: 'none',
        trackBorderWidth: 1,
        trackBorderRadius: 8,
        trackBorderColor: '#CCC'
    },
    series: {
        showInNavigator: true
    },
    subtitle: {
        text: '',
        align: 'left'
    },
    xAxis: {
        
        type: 'datetime',
        tickInterval: 1000 * 3600 * 24,
    // dateTimeLabelFormats: {
    // 	day: condition ? "%A, %b %e, %Y" : "%A, %b %e, %Y",
    	// month: condition ? "%b-%y" : '%b \'%y'
    
//   },
        labels: {
            overflow: 'justify'
        }
    },
    yAxis: {
        min: -100,
        max: 100,
        title: {
            text: 'Net Promotor Score'
        },
        minorGridLineWidth: 0,
        gridLineWidth: 0,
        alternateGridColor: null,
        plotBands: [{ // Alert Area
            from: -100,
            to: 0,
            color: 'rgba(255, 160, 122, 0.2)',
            label: {
                text: 'Recommending, require strong actions to increase NPS Score',
                style: {
                    color: '#606060'
                }
            }
        }, { // Todo Area
            from: 0,
            to: 50,
            color: 'rgba(255, 255, 224, 0.2)',
            label: {
                text: "Doing good, It's time to improve more",
                style: {
                    color: '#606060'
                }
            }
        }, { // Relax Area
            from: 50,
            to: 100,
            color: 'rgba(152, 251, 152, 0.2)',
            label: {
                text: 'Great, Keep going',
                style: {
                    color: '#606060'
                }
            }
        
        },
       
    ]
    },
    exporting: {
        enabled: false
      },
    tooltip: {
        valueSuffix: ''
    },
    plotOptions: {
        spline: {
            lineWidth: 4,
            // color: 'red',
            states: {
                hover: {
                    lineWidth: 5
                }
            },
            marker: {
                enabled: false
            },
            // pointInterval: 36000*24, // one day
            //   pointStart: Date.UTC()
        }
    },
    series: dailyNpsTrends,
    //    [
    //         [Date.UTC(2020, 1), -90],
    //         [Date.UTC(2020, 2), 50],
    //         [Date.UTC(2020, 3), -72],
    //         [Date.UTC(2020, 4), 12],
    //         [Date.UTC(2020, 5), 30],
    //         [Date.UTC(2020, 6), 45],
    //     ]1d

    // }, {
    //     name: 'UPI',
    //     data: [
    //         [Date.UTC(2020, 1), -60],
    //         [Date.UTC(2020, 2), 50],
    //         [Date.UTC(2020, 3), -52],
    //         [Date.UTC(2020, 4), 52],
    //         [Date.UTC(2020, 5), 30],
    //         [Date.UTC(2020, 6), 45],
    //     ]
    // },{
    //     name: 'RUPAY',
    //     data: [
    //         [Date.UTC(2020, 1), -70],
    //         [Date.UTC(2020, 2), 40],
    //         [Date.UTC(2020, 3), -32],
    //         [Date.UTC(2020, 4), 88],
    //         [Date.UTC(2020, 5), 75],
    //         [Date.UTC(2020, 6), 50],
    //     ]
    // },
    // {
    //     name: 'FASTAG',
    //     data: [
    //         [Date.UTC(2020, 1), -30],
    //         [Date.UTC(2020, 2), 30],
    //         [Date.UTC(2020, 3), -62],
    //         [Date.UTC(2020, 4), 66],
    //         [Date.UTC(2020, 5), 70],
    //         [Date.UTC(2020, 6), 4],
    //     ]
  //  }
//],
    navigation: {
        menuItemStyle: {
            fontSize: '10px'
        }
    }
}

    return ( 
        <HighchartsReact
            highcharts={Highcharts}
            options={options} />
     );
}
 
export default SplineChart;