import React, { Fragment } from 'react';
import BubbleChart from '../../charts/bubbleChart';
import DumbbleChart from '../../charts/dumbbleChart';
import SentimentBarChart from '../../charts/sentimentBarChart';

const SentimentAnalysisCommon = () => {
    return ( 

        <Fragment>
                <div className="sentimentAnalysis1">
                    <div className="container-fluid">
                        <div className="row">
                            <div className="col-md-12 mt-3">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                            <h5 className="sentimentAnalysis2"><b>Sentiment Analysis</b> </h5>
                                            </div>
                                            <div className="card-body">
                                                <DumbbleChart/>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            {/* ****************************** */}
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="card">
                                            <div className="card-header">
                                            <h5 className="sentimentAnalysis2"><b>Sentiment Analysis</b> </h5>
                                            </div>
                                            <div className="card-body">
                                                <div className="row">
                                                    <div className="col-md-6">
                                                        <SentimentBarChart/>
                                                    </div>
                                                    <div className="col-md-6">
                                                        <BubbleChart/>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </div>

            </Fragment>
        
     );
}
 

export default SentimentAnalysisCommon