import React, { Component } from "react";
import ReactDOM from "react-dom";
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Timeline from "highcharts/modules/timeline.js"


// import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";

// fake data generator

Timeline(Highcharts);


const HorizontalBarChart = () =>  {
  
    const options =  {
        chart: {
            type: 'bar'
        },
        title: {
            text: 'report analysis '
        },
        subtitle: {
            text: 'Source: <a href="https://en.wikipedia.org/wiki/World_population">Wikipedia.org</a>'
        },
        xAxis: {
            categories: [1,2,3,4,5,6,7],
            title: {
                text: null
            }
        },
        yAxis: {
            // min: 0,
            categories: [1,2,3,4,5,6,7,8,9,10],
            title: {
                text: 'rating wise response',
                align: 'high'
            },
            labels: {
                overflow: 'justify'
            }
        },
        tooltip: {
            valueSuffix: ' millions'
        },
        plotOptions: {
            bar: {
                dataLabels: {
                    enabled: true
                }
            }
        },
        legend: {
            layout: 'vertical',
            align: 'right',
            verticalAlign: 'top',
            x: -40,
            y: 80,
            floating: true,
            borderWidth: 1,
            backgroundColor:
                Highcharts.defaultOptions.legend.backgroundColor || '#FFFFFF',
            shadow: true
        },
        credits: {
            enabled: false
        },
        series: [{
            name: 'Year 1800',
            data: [107, 31, 635, 203, 2]
        }, {
            name: 'Year 1900',
            data: [133, 156, 947, 408, 6]
        }, {
            name: 'Year 2000',
            data: [814, 841, 3714, 727, 31]
        }, {
            name: 'Year 2016',
            data: [1216, 1001, 4436, 738, 40]
        }]
    }
  
    return (
       
      <div>
          <h4>Horizontal Bar Chart</h4>
          <HighchartsReact
              highcharts={Highcharts}
              options= {options} >
          </HighchartsReact>
      </div>
   
    );
  
}

// Put the thing into the DOM!
export default HorizontalBarChart