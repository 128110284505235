import React, { useEffect, useState } from 'react';
// import { CheckBoxSelection, Inject, MultiSelectComponent } from '@syncfusion/ej2-react-dropdowns';
import { setLocalDataAsObject } from '../utils/CoustomStorage';
import moment from 'moment'
import { get } from 'lodash';
import { currentDay, currentMonth, dateFilter } from '../utils/common';
// import './subNavBar.css'
const partner = [
  { id: '1', Partner: 'Airtel Payments Bank' },
  { id: '2', Partner: 'State Bank of India' },
  { id: '3', Partner: 'PayTm Bank' },
  { id: '4', Partner: 'PhonePe' },
  { id: '5', Partner: 'Punjab National Bank' },
  { id: '6', Partner: 'Federal Bank' },
  { id: '7', Partner: 'Axis Bank' },
  { id: '8', Partner: 'Hdfc Bank' },
  { id: '9', Partner: 'Icici Bank' }
]

const fields = { text: 'Partner', value: 'id' };

const SubNavBar = ({ setFilterApply, filterApply,appliedFilterCallingApi, filterObj, setFilterObj,upiShow,setUpiShow,setRupayShow,setFastagShow,rupayShow,fastagShow }) => {

  const [openNav, setOpenNav] = useState(false)
  const [appliedFilter, setAppliedFilter] = useState(false)
  const [optradio, setOptRadio] = useState('dropdown')
  const [dateWise, setDateWise] = useState(
    {
      dates: [
        { id: '0', Date: 'Select One', value: '' },
        { id: '1', Date: 'Weekly', value: 'Weekly' },
        { id: '2', Date: 'Last Month', value: 'Last Month' },
        { id: '3', Date: 'Current Month', value: 'Current Month' },
        { id: '4', Date: 'Yearly', value: 'Yearly' },
      ]
    }
  )
  const [selectedDateWise, setSelectedDateWise] = useState('Current')
  
  const [selectedRangeWise, setSelectedRangeWise] = useState('')

  const [endDate, setEndDate] = useState('')
  const [startDate, setStartDate] = useState('')


useEffect(() => {
 if(filterObj && filterObj.product && filterObj.product.length===0 && filterObj.channel && filterObj.channel.length===0 && filterObj.region && filterObj.region.length===0 && filterObj.partner && filterObj.partner.length===0 ){
  appliedFilterCallingApi({})
  setFilterApply(false)
 } 
},[filterObj])



  // for filter state and setState //
  const [filter, setFilter] = useState({
    select_channel: [
      { id: 1, label: 'SMS', hasSelected: false, value: "sms" }, { id: 2, label: 'EMAIL', hasSelected: false, value: "email" }, { id: 3, label: 'IVR', hasSelected: false, value: "ivr" }, { id: 4, label: 'CALLING', hasSelected: false, value: "calling" }, { id: 5, label: 'All Channels', hasSelected: false, value: "allChannels" }
    ],


    select_partner: {
      partner_product_upi: [],
      partner_product_rupay: [],
      partner_product_fastag: [],
    },
    select_date: {
      partner_product_dropdown: 1,
      partner_product_date_range: 1,
      partner_product_fastag: 2,
    },
  })
  // for initialfilter value //
  const initialFilter = {
    select_channel: [
      { id: 1, label: 'SMS', hasSelected: false, value: "sms" }, { id: 2, label: 'EMAIL', hasSelected: false, value: "email" }, { id: 3, label: 'IVR', hasSelected: false, value: "ivr" }, { id: 4, label: 'CALLING', hasSelected: false, value: "calling" }, { id: 5, label: 'All Channels', hasSelected: false, value: "allChannels" }
    ],
    select_partner: {
      partner_product_upi: [],
      partner_product_rupay: [],
      partner_product_fastag: [],
    },
    select_date: {
      partner_product_dropdown: 1,
      partner_product_date_range: 1,
      partner_product_fastag: 2,
    },
  }


  // for filterProduct state and setState //
  const [filterProduct, setFilterProduct] = useState({
    select_product: [
      { id: 1, label: 'Product Name 1', hasSelected: false, value: "Product Name 1" }, { id: 2, label: 'Product Name 2', hasSelected: false, value: "Product Name 2" }, { id: 3, label: 'Journey Name 1', hasSelected: false, value: "Journey Name 1" },{ id: 4, label: 'Journey Name 2', hasSelected: false, value: "Journey Name 2" }, { id: 5, label: 'All Products', hasSelected: false, value: "allProducts" }
    ]
  })


  // for initialfilterProduct value //
  const initialFilterProduct = {
    select_product: [
      { id: 1, label: 'Product Name 1', hasSelected: false, value: "Product Name 1" }, { id: 2, label: 'Product Name 2', hasSelected: false, value: "Product Name 2" }, { id: 3, label: 'Journey Name 1', hasSelected: false, value: "Journey Name 1" },{ id: 4, label: 'Journey Name 2', hasSelected: false, value: "Journey Name 2" }, { id: 5, label: 'All Products', hasSelected: false, value: "allProducts" }
    ]
  }


  // for filterRegion state and setState //
  const [filterRegion, setFilterRegion] = useState({
    select_region: [
      { id: 1, label: 'EAST', hasSelected: false, value: "east" }, { id: 2, label: 'WEST', hasSelected: false, value: "west" }, { id: 3, label: 'NORTH', hasSelected: false, value: "north" }, { id: 4, label: 'SOUTH', hasSelected: false, value: "" }, { id: 5, label: 'All Regions', hasSelected: false, value: "allRegions" }
    ],
  })

  // for initialfilterRegion value //
  const initialFilterRegion = {
    select_region: [
      { id: 1, label: 'EAST', hasSelected: false, value: "east" }, { id: 2, label: 'WEST', hasSelected: false, value: "west" }, { id: 3, label: 'NORTH', hasSelected: false, value: "north" }, { id: 4, label: 'SOUTH', hasSelected: false, value: "" }, { id: 5, label: 'All Regions', hasSelected: false, value: "allRegions" }
    ]
  }


  const getEndDate = (e) => {
    switch (e) {
      case 'Weekly':
        return moment().subtract(7, 'd').format('YYYY-MM-DD');
        break;
      case 'Last Month':
        return moment(new Date()).subtract(1, 'months').format('YYYY-MM-DD')
        break;
      case 'Current Month':
        return moment(new Date()).format('YYYY-MM-DD')
        break;
      case 'Yearly':
        return moment(new Date()).subtract(12, 'months').format('YYYY-MM-DD')
        break;
      default:
        return moment(new Date()).format('YYYY-MM-DD')
        break;
    }
  }



  // handle events start//

  const openNavs = (btnName,e) => {
    e.preventDefault();
    setUpiShow(false)
    setRupayShow(false)
    setFastagShow(false)
    if (btnName === 'openFilter') {
      setOpenNav(!openNav)
    } else if (btnName === 'applyFilter') {
      
      setOpenNav(!openNav)
      let obj = {}
      obj.product = []
      filterProduct.select_product.forEach(element => {
        if (element.hasSelected === true) {
          obj.product.push(element)
        }
        obj.channel = []
        filter.select_channel.forEach(element => {
          if (element.hasSelected === true) {
            obj.channel.push(element)
          }
        });
        obj.region = []
        filterRegion.select_region.forEach(element => {
          if (element.hasSelected === true) {
            obj.region.push(element)
          }
        });
        obj.partner = filter.select_partner !== undefined ? filter.select_partner : []
        obj.start_date = optradio === 'dropdown' ? selectedDateWise==="Current Month" ?  currentMonth('start_date'): getEndDate(selectedDateWise)  : startDate
        obj.end_date = optradio === 'dropdown' ? moment(new Date()).format('YYYY-MM-DD') : endDate
      });
      setLocalDataAsObject('filter', obj)
      setFilterObj(obj)
      appliedFilterCallingApi(obj)
      setFilterApply(true)
      // setFilterApplys()
      
    } 
    else {
      removeFilter()
      setLocalDataAsObject('filter', {})
      setOpenNav(!openNav)
    }
  }

  const handleAppliedFilter = () => {
    setAppliedFilter(!appliedFilter)
  }


  // channel handle event // 
  const handleSelectedChecked = (objName, index) => {
    const temp = filter.select_channel
    if (objName === 'allChannels') {

      if (temp[index].hasSelected) {
        temp[index].hasSelected = false;
        temp.map((ele) => ele.hasSelected = false);
      } else {
        temp[index].hasSelected = true;
        temp.map((ele) => ele.hasSelected = true);
      }

      setFilter({ select_channel: temp })
    } else {
      let checkFilterForAll = false
      temp[index].hasSelected = !temp[index].hasSelected; // true  
      for (let index = 0; index < temp.length - 1; index++) {
        const element = temp[index].hasSelected;
        if (element === false) {
          checkFilterForAll = false
          break
        } else {
          checkFilterForAll = true
        }
      }
      temp[temp.length - 1].hasSelected = checkFilterForAll

      setFilter({ select_channel: temp })
    }
  }

  // product handle event //
  const handleSelectedCheckedProducts = (objName, index) => {

    const temp = filterProduct.select_product
    if (objName === 'allProducts') {

      if (temp[index].hasSelected) {
        temp[index].hasSelected = false;
        temp.map((ele) => ele.hasSelected = false);
      } else {
        temp[index].hasSelected = true;
        temp.map((ele) => ele.hasSelected = true);
      }

      setFilterProduct({ select_product: temp })
    } else {
      let checkFilterForAll = false
      temp[index].hasSelected = !temp[index].hasSelected; // true  
      for (let index = 0; index < temp.length - 1; index++) {
        const element = temp[index].hasSelected;
        if (element === false) {
          checkFilterForAll = false
          break
        } else {
          checkFilterForAll = true
        }
      }
      temp[temp.length - 1].hasSelected = checkFilterForAll

      setFilterProduct({ select_product: temp })
    }
  }

  // region handle event // 
  const handleSelectedCheckedRegion = (objName, index) => {
    const temp = filterRegion.select_region
    if (objName === 'allRegions') {

      if (temp[index].hasSelected) {
        temp[index].hasSelected = false;
        temp.map((ele) => ele.hasSelected = false);
      } else {
        temp[index].hasSelected = true;
        temp.map((ele) => ele.hasSelected = true);
      }

      setFilterRegion({ select_region: temp })
    } else {
      let checkFilterForAll = false
      temp[index].hasSelected = !temp[index].hasSelected; // true  
      for (let index = 0; index < temp.length - 1; index++) {
        const element = temp[index].hasSelected;
        if (element === false) {
          checkFilterForAll = false
          break
        } else {
          checkFilterForAll = true
        }
      }
      temp[temp.length - 1].hasSelected = checkFilterForAll

      setFilterRegion({ select_region: temp })
    }
  }

  // deleteItemFromFilter handle event // 
  const deleteItemFromFilter = (e,objName, index) => {
    e.preventDefault();
    if (objName === 'channel') {
      let temp = filterObj.channel
      temp.splice(index, 1)
      setFilterObj({ ...filterObj, channel: temp })
    } else if (objName === 'product') {
      let temp = filterObj.product
      temp.splice(index, 1)
      setFilterObj({ ...filterObj, product: temp })
    } else if (objName === 'region') {
      let temp = filterObj.region
      temp.splice(index, 1)
      setFilterObj({ ...filterObj, region: temp })
    } else if (objName === 'dates') {
      let temp = filterObj
      delete temp.start_date
      delete temp.end_date
      setFilterObj(temp)
    }
  }

  // handleSelectedPartner handle event // 
  const handleSelectedPartner = (e) => {
    const { value, name } = e.target
    if (name === 'optradio') {
      setOptRadio(value)
    } else {
      setFilter(value)
    }
  }


  // removeFilter handle event // 
  const removeFilter = () => {
    
    setFilter(initialFilter)
    setFilterProduct(initialFilterProduct)
    setFilterRegion(initialFilterRegion)
    setOptRadio('dropdown')
    setEndDate('')
    setStartDate('')
    setSelectedDateWise('Current')
    setFilterObj({})
    appliedFilterCallingApi({})
    setLocalDataAsObject('filter', {}) 
  }

  const closeFilterCross=()=>{
    removeFilter()
    setOpenNav(false)
    setFilterApply(false)
  }
 
  return (

    <div className="container-fluid">
      <div className="row">


        <div className="col-md-12">

          <div className="row second-heading tsm mb-1">
            <div className="col-md-12">
              <div className="page-title">
                <div className="row mt-1">
                  <div className="col-md-12 col-lg-9 col-12">
                    <div className="row">
                      <div className="col-md-12">
                        <div className="relative">


                          {filterApply  ? <> <div className="read-date  d-flex align-items-center ">

                            <div className="filtr">Filters Applied:</div>
                            <div className="scrolleble">
                              {filterObj && filterObj.channel && filterObj.channel.map((element, index) => (
                                <div className="keyword">
                                  <span>Channel: {element.label}</span>
                                  <a href="#" onClick={(e) => deleteItemFromFilter(e,'channel', index)}>x</a>
                                </div>
                              ))}
                              {filterObj && filterObj.product && filterObj.product.map((element, index) => (
                                <div className="keyword">
                                  <span>Product: {element.label}</span>
                                  <a href="#" onClick={(e) => deleteItemFromFilter(e,'product', index)}>x</a>
                                </div>
                              ))}
                              {filterObj && filterObj.region && filterObj.region.map((element, index) => (
                                <div className="keyword">
                                  <span>Region: {element.label}</span>
                                  <a href="#" onClick={(e) => deleteItemFromFilter(e,'region', index)}>x</a>
                                </div>
                              ))}
                              {optradio === 'duration' ?
                                (filterObj && filterObj.start_date && filterObj.end_date) && <div className="keyword">
                                  <span>Date Range: {filterObj.start_date} - {filterObj.end_date}</span>
                                  <a href="#" onClick={(e) => {
                                    deleteItemFromFilter(e,'dates')
                                  }}>x</a>
                                </div> :
                                (filterObj && filterObj.start_date && filterObj.end_date) && <div className="keyword">
                                  <span>{selectedDateWise}</span>
                                  <a href="#" onClick={(e) => {
                                    setSelectedDateWise('')
                                    deleteItemFromFilter(e,'dates')
                                  }}>x</a>
                                </div>
                              }
                            </div>
                            <a href="#" onClick={removeFilter} className="ml-auto remove-filtr read-date" >Remove All Filters</a> </div></> :
                            <div className=" d-flex align-items-center ">
                              <div className="filtr"><b>Apply Filters :</b></div>
                              <div ><b className="filtrNoFilter">Use filters to modify data-set ...</b></div>
                            </div>
                          }


                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-3 col-md-12 col-12 d-flex align-items-end justify-content-end">
                    <div className="row btns">
                     
                      <div className="col-md-2 refresh mr-5" >
                      <img onClick={(e) => openNavs('openFilter',e)} src="img/008-filter.png" style={{ width: '18px' }} />
                   
                      </div>
                    </div>
                  </div>
                  {openNav === true &&
                    <div style={{ width: '100%' }} className="overlay1">
                      <i className="fa fa-times filter-cross" onClick={closeFilterCross} />
                      <div className="overlay-content container-fluid">
                        <div className="row">

                          {/* channel */}
                          <div className="col-md-2 rating-form">
                            <h6>Select Channel</h6>
                            <span className="small"> (Source from where getting responses) </span>
                            {filter && filter.select_channel && filter.select_channel.map((channelData, channelIndex) => (
                              <div className="mb-3" key={channelIndex}>
                                <input type="checkbox" value={channelData.hasSelected} />
                                <label onClick={() => handleSelectedChecked(channelData.value, channelIndex)} value={channelData.id} className={`${channelData.hasSelected === true ? "npsrating  hover-smily-box checkmark selected-checked  " : "npsrating  hover-smily-box checkmark"}`}>
                                  <span className="rating-number">{channelData.label}
                                    <div className="hover-img" />
                                  </span>
                                </label>
                              </div>
                            ))}
                          </div>

                          {/* product */}
                          <div className="col-md-2 rating-form">
                            <h6>Select Product </h6>
                            <span className="small"> (For what products looking for responses)</span>
                            {filterProduct && filterProduct.select_product.map((productData, productIndex) => (
                              <div className="mb-3" key={productIndex}>
                                <input type="checkbox" value={productData.hasSelected} />
                                <label onClick={() => handleSelectedCheckedProducts(productData.value, productIndex)} value={productData.id} className={`${productData.hasSelected === true ? "npsrating  hover-smily-box checkmark selected-checked  " : "npsrating  hover-smily-box checkmark"}`}>
                                  <span className="rating-number">{productData.label}
                                    <div className="hover-img" />
                                  </span>
                                </label>
                              </div>
                            ))}
                          </div>

                          {/* region */}
                          <div className="col-md-2 rating-form">
                            <h6>Select region</h6>
                            <span className="small"> (Geographical selection of the products)</span>
                            {filterRegion && filterRegion.select_region && filterRegion.select_region.map((regionData, RegionIndex) => (
                              <div className="mb-3" key={RegionIndex}>
                                <input type="checkbox" value={regionData.hasSelected} />
                                <label onClick={() => handleSelectedCheckedRegion(regionData.value, RegionIndex)} value={regionData.id} className={`${regionData.hasSelected === true ? "npsrating  hover-smily-box checkmark selected-checked  " : "npsrating  hover-smily-box checkmark"}`}>
                                  <span className="rating-number">{regionData.label}
                                    <div className="hover-img" />
                                  </span>
                                </label>
                              </div>
                            ))}
                          </div>

                          <div className="col-md-2 rating-form">
                          


                            <h6>Select Date </h6>
                            <span className="small"> (To view data datewise)</span>
                            <div className="radio-btn">
                              <input type="radio" name="optradio" value={'dropdown'} onChange={handleSelectedPartner} checked={optradio === 'dropdown'} />
                              <label> &nbsp; Select from dropdown </label>
                              {optradio === 'dropdown' && <select name="selectedDateWise" onChange={(e) => setSelectedDateWise(e.target.value)} value={selectedDateWise} className="form-control mb-3" placeholder="Select from dropdown">
                                {dateWise && dateWise.dates && dateWise.dates.map(({ value, Date }, dateIndex) => (<option key={dateIndex} value={value}>{Date}</option>))}
                              </select>}
                            </div>
                            <div className="radio-btn">
                              <input type="radio" name="optradio" value={'duration'} onChange={handleSelectedPartner} checked={optradio === 'duration'} />
                              <label> &nbsp; Select from Date Range </label>
                              {/* <select name="selectedDateWise" onChange={(e) => setSelectedRangeWise(e.target.value)} value={selectedRangeWise} className="form-control mb-3" placeholder="Select from dropdown"  type="date">
                                {dateRangeWise && dateRangeWise.dateRanges && dateRangeWise.dateRanges.map((dateRanges, dateRangesIndex) => (<option key={dateRangesIndex} value={dateRanges.id}>{dateRanges.Date}</option>))}

                              </select> */}
                              {optradio === 'duration' &&
                                <>
                                  <div className="form-group">
                                    <label> Start Date </label>
                                    <input onChange={(e) => setStartDate(e.target.value)} type="date" name="startDate" value={startDate} className="form-control" />
                                  </div>
                                  <div className="form-group">
                                    <label> End Date </label>
                                    <input type="date" onChange={(e) => setEndDate(e.target.value)} name="endDate" value={endDate} className="form-control" />
                                  </div>
                                </>}
                              {/* <label>Partners for Product FASTag </label>
                              <select className="form-control mb-3" placeholder="Select from dropdown">
                                <option />
                              </select> */}
                            </div>
                          </div>
                          <div className="col-md-2 rating-form" style={{ display: 'flex', flexFlow: 'column', justifyContent: 'flex-end' }}>
                            {/* <div> <a href="#">Clear Filter</a></div> */}
                            <div style={{cursor:"pointer"}} onClick={removeFilter}> Reset Filter</div>
                            <br />
                            <div> <a className="btn btn-primary" onClick={(e) => openNavs('applyFilter',e)} style={{ marginBottom: '14px' }}>Apply Filter</a></div>
                          </div>
                        </div>
                      </div>
                    </div>
                  }
                </div>
              </div>
            </div>
          </div>

        </div>
      </div>
    </div>


  );
}

export default SubNavBar;