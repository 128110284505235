import React, { useCallback, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import exporting from 'highcharts/modules/exporting';
import exportData from 'highcharts/modules/export-data.js';
import heatmap from 'highcharts/modules/heatmap';


// heatmap(Highcharts);
exporting(Highcharts);
exportData(Highcharts);



  const CustomCalendar = (props) => {
    
    const getPointCategoryName=(point, dimension)=> {
      var series = point.series,
          isY = dimension === 'y',
          axis = series[isY ? 'yAxis' : 'xAxis'];
      return axis.categories[point[isY ? 'y' : 'x']];
  }
    

const options = {
  chart: {
    type: 'heatmap',
    marginTop: 40,
    marginBottom: 80,
    plotBorderWidth: 1
},


title: {
    text: 'MTD NPS SCORE'
},

xAxis: {
    categories: ['Week-01', 'Week-02', 'Week-03', 'Week-04', 'Week-05']
},

yAxis: {
    categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday','Satday','Sunday'],
    title: null,
    reversed: true
},

accessibility: {
    point: {
        descriptionFormatter: function (point) {
            var ix = point.index + 1,
                xName = getPointCategoryName(point, 'x'),
                yName = getPointCategoryName(point, 'y'),
                val = point.value;
            return ix + '. ' + xName + ' Nps Score ' + yName + ', ' + val + '.';
        }
    }
},

colorAxis: {
    min: 0,
    minColor: '#FFFFFF',
    maxColor: Highcharts.getOptions().colors[0]
},

legend: {
    align: 'right',
    layout: 'vertical',
    margin: 0,
    verticalAlign: 'top',
    y: 25,
    symbolHeight: 280
},

tooltip: {
    formatter: function () {
        return '<b>' + getPointCategoryName(this.point, 'x') + '</b> Day <br><b>' +
            this.point.value + '</b> Nps score <br><b>' + getPointCategoryName(this.point, 'y') + '</b>';
    }
},

series: [{
    name: 'Daily Nps Score',
    borderWidth: 1,
    data: [
      [0, 5, "Feb-01 48"],
      [0, 1, 19],
      [0, 2, 8],
      [0, 3, 24],
      [0, 4, 67],
      [1,5,20],
      [1,6,89],
      [1, 0, 92],
      [1, 1, 58],
      [1, 2, 78],
      [1, 3, 100],
      [1, 4, 48],
      [2, 1, 58],
      [2, 2, 78],
      [2, 3, 107], 
      [3, 2, 8],
      [3, 3, 24],
      [3, 4, 67],
      [4, 3, 117], 
      [4, 4, 115], 
     
    ],
    dataLabels: {
        enabled: true,
        color: '#000000'
    }
}],

responsive: {
    rules: [{
        condition: {
            maxWidth: 500
        },
        chartOptions: {
            yAxis: {
                labels: {
                    formatter: function () {
                        return this.value.charAt(0);
                    }
                }
            }
        }
    }]
  }
}


return ( 
  <div className="container">
  <HighchartsReact
      highcharts={Highcharts}
      options={options}
      
  />
  </div>
);
  
}

export default CustomCalendar;