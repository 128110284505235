import React, { Fragment, useEffect, useState } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import WordCloud from "highcharts/modules/wordcloud.src.js"
import Exporting from "highcharts/modules/exporting.js"
import ExportData from "highcharts/modules/export-data.js"
import CommentModel from '../../common/commentModel';
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { get } from 'lodash'


ExportData(Highcharts);
Exporting(Highcharts);
WordCloud(Highcharts);

const WordCloudChart = (props) => {
  const api = new API()
  const { Name } = props
  const [open, setOpen] = useState(false);


  // WordCloudChart //
  const [wordCloudChartResultData, setWordCloudChartResultData] = useState('')
 

  // commentDetail //
  const [commentDetailResultData, setCommentDetailResultData] = useState([])

  useEffect(() => {
    wordCloudChart()
  }, [])

  useEffect(() => {
    wordCloudChart()
  }, [Name])

  const wordCloudChart = async () => {
    let wordCloudChart = await api.get(config.dashboardWordCloud, Name === 'All' ? false : true, { type: Name });
    let wordCloudChartResultData = get(wordCloudChart, 'data', '')
    setWordCloudChartResultData(wordCloudChartResultData)
  }

  const commentDetail = async (comment, type) => {
    let commentDetail = await api.get(config.dashboardCommentDetail, true, { comment, type });
    let commentDetailResultData = get(commentDetail, 'data', [])
    setCommentDetailResultData(commentDetailResultData)
    if (commentDetailResultData.length > 0) {
      setOpen(!open)
    }
  }

  const handleModel = async (e) => {
    const { name } = get(e, 'point', 'options', {})
    let comment = name
    let type = Name
    await commentDetail(comment, type)

  }
  //const text ="Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean bibendum erat ac justo sollicitudin, quis lacinia ligula fringilla. Pellentesque hendrerit, nisi vitae posuere condimentum, lectus urna accumsan libero, rutrum commodo mi lacus pretium erat. Phasellus pretium ultrices mi sed semper. Praesent ut tristique magna. Donec nisl tellus, sagittis ut tempus sit amet, consectetur eget erat. Sed ornare gravida lacinia. Curabitur iaculis metus purus, eget pretium est laoreet ut. Quisque tristique augue ac eros malesuada, vitae facilisis mauris sollicitudin. Mauris ac molestie nulla, vitae facilisis quam. Curabitur placerat ornare sem, in mattis purus posuere eget. Praesent non condimentum odio. Nunc aliquet, odio nec auctor congue, sapien justo dictum massa, nec fermentum massa sapien non tellus. Praesent luctus eros et nunc pretium hendrerit. In consequat et eros nec interdum. Ut neque dui, maximus id elit ac, consequat pretium tellus. Nullam vel accumsan lorem.",
  const text = wordCloudChartResultData,
    lines = text.split(/[,\. ]+/g),

    data = Highcharts.reduce(
      lines,
      function (arr, word) {
        var obj = Highcharts.find(arr, function (obj) {
          return obj.name === word;
        });
        if (obj) {
          obj.weight += 1;
        } else {
          obj = {
            name: word,
            weight: Math.ceil(Math.random() * 50)
          };
          arr.push(obj);
        }
        return arr;
      },
      []
    );

  const options = {
    chart: {
      height: 550,
      width: 1300,
    },
    accessibility: {
      screenReaderSection: {
        beforeChartFormat: '<h5>{chartTitle}</h5>' +
          '<div>{chartSubtitle}</div>' +
          '<div>{chartLongdesc}</div>' +
          '<div>{viewTableButton}</div>'
      }
    },
    exporting: {
      enabled: false
    },
    series: [{
      type: 'wordcloud',
      data: data,
      name: Name,
      events: {
        click: function (event) {
          handleModel(event)
        }
      }
    }],
   
    
    title: {
      text: ''
    },
  }


  return (
    <Fragment>

      <HighchartsReact
        highcharts={Highcharts}
        options={options}
      />
      <div style={{ display: "none" }}><CommentModel handleModel={() => setOpen(!open)} commentDetailResultData={commentDetailResultData} open={open} />
      </div>
    </Fragment>
  );
}

export default WordCloudChart;