import React, { useState,useEffect } from 'react';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import Timeline from "highcharts/modules/timeline.js"
import API from '../../utils/apiCalling';
import { config } from '../../utils/apiUrl';
import { get, set } from 'lodash'
import CommentModel from '../../common/commentModel';
Timeline(Highcharts);

const MtdCommentChart = (props) => {

    const { Name, DailyCount,MonthDate,DateAndCount,MtdNpsScore,Count,NpsScore,Logo} = props
    const api = new API()
    const [ ratingWiseComments, setRatingWiseComments] = useState([])
    const [open, setOpen] = useState(false);
    const [ratingCommentPopupData, setRatingCommentPopupData] = useState([])


   
      
const options = {
    chart: {
        type: 'scatter',
        scrollablePlotArea: {
            minWidth: 700,
            scrollPositionX: 1,
           
          },
           
        //    renderTo: 'container',
        //   events: {  
        //     load: function () {
        //        this.renderer.image('img/upi.png', 520, 10, 30, 30)
                   
        //             .css({cursor:'Pointer'})

        //             .add();
        //             }
        //         }  
    },
   
  
    title: {
        text: '',
        style: {
            color: '#fff',
            font: 'bold 1px "Trebuchet MS", Verdana, sans-serif',
            
         },
         
    },
    
    credits: {
        enabled: false
    },
    subtitle: {
        // useHTML: true,
        text: '',
        // useHTML: true,
        // labelFormatter: function () {
        //     return '<img src="img/upi.png"/>' ;
        // }
    },
    yAxis: {
        
            ticks: {
                    display: false
            },  
            gridLineColor: 'transparent',  
        title: {
            text: ''
        },
        max:100,
  min: -100,
    },
    legend: {
        enabled: false
      },
      exporting: {
        enabled: false
      },
    xAxis:[ {
        title: {
            text: 'Date with daily counts'
        },
        
        type:'category',
        categories: MonthDate,
        scrollbar: {
            enabled: true,
          },
         
          labels: {
            overflow: 'justify'
        },
    }],

  
  
    plotOptions: {
        scatter: {
            marker: {
                states: {
                    hover: {
                        enabled: true,
                        lineColor: 'rgb(100,100,100)'
                    }
                }
            },
            states: {
                hover: {
                    marker: {
                        enabled: false
                    }
                }
            },
            tooltip: {
                pointFormat: '<b>Daily Nps Score</b>: {point.y}<br> '

            },
        },
  
    },
    

  
    series: [{ 
        colorByPoint: true,
        name: 'Daywise Nps score',
        data: NpsScore,
        dataLabels: {
            enabled: true,
            rotation: 0,
            color: 'black',
            align: 'right',
             format: '{point.y:.1f}', // one decimal
            y: 5, // 10 pixels down from the top
            style: {
                fontSize: '8px',
                fontFamily: 'Verdana, sans-serif'
            },
        cursor: 'pointer',
        // events: {
        //     click: function (e) {
        //         // alert('clicked');
        //         handleModel(e)
        //     }
        // }
    }
}]

            // keys: ['x', 'y', 'name'],
            // color: "#ea6f67",
            // name: [Name].toString(),
            // data: [
        //         [1, 4, 'Requirement for improving in customer service...it takes half an hour to contact customer care center ...'],
        //         [2.3, 6, 'Improve customer service and be ethical, else people will dump bhim'],
        //         [2.9, 2, 'Improve customer service and be ethical, else people will dump bhim'],
        //         [3.5, 3, 'Improve customer service and be ethical, else people will dump bhim'],
        //         [4.5, 1, 'Improve customer service and be ethical, else people will dump bhim']
                
        //     ]
        // }, {
        //     keys: ['x', 'y', 'name'],
        //     color: '#f7b952',
        //     name: "Passive",
        //     data: [
        //         [2.1, 7, 'Improve security'],
        //         [4, 8, 'Cashback offers can be improved'],

        //     ]
        // }, {
        //     keys: ['x', 'y', 'name'],
        //     color: '#52c37b',
        //     name: "Promoter",
        //     data: [
        //         [2.5, 9, 'All service is good and I like it cash back'],
        //         [7, 10, 'Good acceptance  across merchants only if it can learn from bhim  for shopping'],
        //         [3.1, 9, 'Good acceptance  across merchants'],
        //         [4.4, 9, 'All service is good and I like it cash back'],
        //         [5.8, 9, 'All service is good'],
        //         [6.5, 9, 'I like it cash back'],

            // ]
    //     },
        
    // ]
   
    
}

// options.setTitle({
//     useHTML: true,
//     text: "Testing" + " " + "<img src='img/upi.png' alt='' />"
//     }, {
//     text: "This is a test"
// });
    return ( 
        <div>
        <HighchartsReact
            highcharts={Highcharts}
            options={options}
        
            
        />
        {/* <div style={{display:"none"}}><CommentModel handleModel={() => setOpen(!open)} commentDetailResultData={ratingCommentPopupData} open={open} />
      </div> */}
      </div>
     );
}
 
export default MtdCommentChart;