import React, { Fragment, useState } from 'react';
import './q3.css';
import {final} from './finalSurvey';
const QuestionThree = () => {
   const [que, setQue] = useState("What do you think your RuPay card should do more ?")
   const [queChanged, setQueChanged] = useState(false) 
   const [option, setOption] = useState({option1 : "RuPay card should be more easy to get", option2: "No errors / problems while doing transactions", option3: "It was Easy and Fast to use the RuPay card", option4: "Performance of the App"})
   const [optionChanged, setOptionChanged] = useState({optionChanged1: false, optionChanged2: false, optionChanged3: false, optionChanged4: false}) 
   


   const changeQuestion = () => {
       setQueChanged(!queChanged)
    }
   const changeQuestionDone = (e) => {
    setQue(e.target.value)
    }
// _____________________________
    const changeOption = (name) => {
        console.log(name)
        setOptionChanged((prevState) => ({
            ...prevState, [name]: !prevState[name]
        }))
    }
    const changeOptionDone = (e) => {
        let value = e.target.value
        let name = e.target.name
        console.log(value, name)
        // setOption((prevState) =>({
        //     ...prevState, [name] : !prevState[value]
        // }))
        setOption({...option, [name]: value})
    }
    const submit=()=>{
        final.push({que, option, type: 3})
        console.log(final)
        alert("question added")
    }
// ___________________________



    return (
        
        <Fragment>
            <div style={{height:"700px"}}>
            <header className="fixed-top">
                <div className="header-wrap d-block text-center">
                    <img src="CreateSurvey/images/logo.png" alt="" className="" style={{height: "50px", paddingTop: "5px"}} />
                </div>
                <div className="line-bottom"></div>
            </header>



 
  <div className="min-height-body">
                <div className="">
                    <div className="position-relative card-header custom-header equal-spacing extra-margin">
                       {queChanged == false && <h6 onClick={changeQuestion} name="queChanged" className="f-16 mb-0 d-block mx-auto" > {que}</h6> }
                       {queChanged == true && <input onChange={changeQuestionDone} onMouseLeave={changeQuestion} name="setQue" style={{width:"30%", margin:"auto"}} className="form-control input-sm" value={que} />}
                    </div>



                    <div className="container">
                        <ul className="row px-2 mb-0 text-center">
                            <li className="col-lg-6 col-6 px-2 py-3">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="1" name="A"/>
                                         <div>
                                         <label for="op1" className="labelClass">
                                            <input id="op1" type="file" hidden/>
                                             <img src="CreateSurvey/images/UPI_1.svg" alt="" className="mx-auto  icon-img" />
                                            </label>
                                         <hr className="mt-1 mb-1" />
                            {optionChanged.optionChanged1 == false && <h6 onClick={()=>changeOption("optionChanged1")} className="f-14" style={{color:"black"}}>{option.option1}</h6> }
 
                            {optionChanged.optionChanged1 == true && <input onChange={changeOptionDone} onMouseLeave={()=>changeOption("optionChanged1")} name="option1" style={{ width:"100%", margin:"auto"}} className="form-control input-sm" value={option.option1} />}
                                          </div>
                                          {/* </input> */}
                                          </label>
                            </li>
                            <li className="col-lg-6 col-6 px-2 py-3">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="2" name="A"/> 
                                    <div>
                                    <label for="op1" className="labelClass">
                                    <input id="op1" type="file" hidden/>
                                        <img src="CreateSurvey/images/UPI_2.svg" alt="" className="mx-auto  icon-img" />
                                    </label>
                                        <hr className="mt-1 mb-1" /> 
                      {optionChanged.optionChanged2 == false && <h6 onClick={()=>changeOption("optionChanged2")} className="f-14" style={{color:"black"}}>{option.option2}</h6> }
 
                      {optionChanged.optionChanged2 == true && <input onChange={changeOptionDone} onMouseLeave={()=>changeOption("optionChanged2")} name="option2" style={{ width:"100%", margin:"auto"}} className="form-control input-sm" value={option.option2} />}
                                        </div>
                                        {/* </input> */}
                                        </label>
                            </li>
                            <li className="col-lg-6 col-6 px-2 ">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="3" name="A"/> 
                                    <div>
                                    <label for="op1" className="labelClass">
                                    <input id="op1" type="file" hidden/>
                                        <img src="CreateSurvey/images/UPI_3.svg" alt="" className="mx-auto  icon-img" /> 
                                    </label>
                                        <hr className="mt-1 mb-1" />
                            {optionChanged.optionChanged3 == false && <h6 onClick={()=>changeOption("optionChanged3")} className="f-14" style={{color:"black"}}>{option.option3}</h6> }
                            {optionChanged.optionChanged3 == true && <input onChange={changeOptionDone} onMouseLeave={()=>changeOption("optionChanged3")} name="option3" style={{ width:"100%", margin:"auto"}} className="form-control input-sm" value={option.option3} />}
                                        </div>
                                        {/* </input> */}
                                        </label>
                            </li>
                            <li className="col-lg-6 col-6 px-2 ">
                                <label className="full-width radio-label extra-margin">
                                    <input type="radio" value="4" name="A"/> 
                                    <div>
                                    <label for="op1" className="labelClass">
                                    <input id="op1" type="file" hidden/>
                                        <img src="CreateSurvey/images/UPI_5.svg" alt="" className="mx-auto  icon-img" />
                                    </label>
                                        <hr className="mt-1 mb-1" />
                            {optionChanged.optionChanged4 == false && <h6 onClick={()=>changeOption("optionChanged4")} className="f-14" style={{color:"black"}}>{option.option4}</h6> }
                            {optionChanged.optionChanged4 == true && <input onChange={changeOptionDone} onMouseLeave={()=>changeOption("optionChanged4")} name="option4" style={{ width:"100%", margin:"auto"}} className="form-control input-sm" value={option.option4} />}
                                        </div>
                                        {/* </input> */}
                                        </label>
                            </li>
                            <div className="col-lg-12 col-12 px-2 py-3 text-left">
                                <textarea className="form-control mb-2 extra-margin" placeholder="Free Text..." rows="1" data-toggle="modal" data-target="#myModal">

                                </textarea>


                            </div>
                            <button className="btn btn-primary" onClick={submit}>submit</button>
                        </ul>
                    </div>



                </div>
  </div>
<div  className="monument d-xl-none">
  <div className="d-flex mb-2 px-3" style={{justifyContent: "space-between"}}>
      <a className="text-secondary extra-margin">
        Back </a>
       <a className="text-secondary extra-margin"> 
       Next </a> 
       </div>

</div>
<div className="modal fade" id="myModal">
  <div className="modal-dialog">
    <div className="modal-content">
    
      <div className="modal-body">
          <textarea className="form-control mb-2" placeholder="Free Text..." rows="5"></textarea>
          <h6 className="text-danger">Minimum 10 words required*</h6>

      </div>
     
      
    </div>
  </div>

</div>
</div>

      
        </Fragment>
    )
}
export default QuestionThree