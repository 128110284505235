// import React, { Fragment, useEffect, useState } from 'react';
// import GoogleLogin from "react-google-login";
// import FacebookLogin from "react-facebook-login";
// import { FB_APP_ID, GOOGLE_CLIENT_ID } from './social-key';
// import API from '../utils/apiCalling';
// import { config } from '../utils/apiUrl';
// import { AvForm, AvField } from 'availity-reactstrap-validation';
// import { validation } from '../common/configCommon';
// import { setLocalDataAsObject } from '../utils/CoustomStorage';
// import { toast } from 'react-toastify';

// import { withCookies } from 'react-cookie';


// const Login = ({cookies}) => {

//   const api = new API()


//   const [loginForm, setLoginForm] = useState({
//     username: '',
//     password: '',
//   })

//   const [passwordShown, setPasswordShown] = useState(false);

//   const passwordVisiblity = () => {
//     setPasswordShown(passwordShown ? false : true);
//   };
//   const onChangeInput = (e) => {
//     const { value, name, checked } = e.target;
//     // if (name === 'remember') {
//     //   setLoginForm({
//     //     ...loginForm,
//     //     [name]: checked
//     //   })
//     // } else {
//       setLoginForm({
//         ...loginForm,
//         [name]: value
//       })
//     // }
//   }

//   const loginSubmit = async () => {
//     let result = await api.post(config.login, loginForm)
//     if (result.code === 200) {
//       await cookies.set('token', result.data.token)
//       // toast.info(result.message)
//       // props.history.push('/dashboard')
//     } else {
//       toast.error(result.message)
//     }
//   }



//   const responseFacebook = async (response) => {
//     console.log("triger facebook", response)
//     // await console.log("response from facebook", response);
//     // console.log("user data from facebook", response.email)
//     // const facebookUserData = {
//     //   email : response.email,
//     //   firstName : response.name,
//     //   fullName : response.name,
//     //   profileImage : response.picture.data.url
//     // }
//     // console.log("user data is", facebookUserData)
//     // setRegister({...registerForm, 
//     //   email : facebookUserData.email,
//     //   firstName : facebookUserData.firstName,
//     //   fullName : facebookUserData.fullName,
//     //   profileImage : facebookUserData.profileImage
//     //   })
//   }

  
  
//   return (
//     <Fragment>
//       <div className="login">
//       <div className="overlay"></div>
//       <div className="container container1">
//       <div className="row">
//         <div className="col-lg-7"> 
//         <img src="img/banner-1.png"  className="mx-auto d-block mb-4" />
//         </div>
//         <div className="col-lg-5">
//         <div className="wrap formquick">
//           <div className="logo">
//           {/* <h2>SurveyCRM</h2> */}
//             <img src="img/logocrm.png"  className="mx-auto d-block" />
//           </div>
//           <AvForm onValidSubmit={loginSubmit} className="mt-5">
//             <div className="form-group">
//               {/* <img src="img/user-name.png"  className="mx-auto d-block" /> */}
//               <AvField type="text" name="username" value={loginForm.username} className="form-control  py-4" 
//               onChange={onChangeInput} placeholder="Enter email" 
//               validate={{
//                 required: {
//                   value: true,
//                   errorMessage: "Email is required",
//                 }
//               }}
//               />
//             </div>
//             <div className="form-group">
              
//             {/* <img src="img/key-password.png"  className="mx-auto d-block" /> */}
//               <AvField type="password" name="password" value={loginForm.password} onChange={onChangeInput}
//                 className="form-control py-4" placeholder="Enter password"
//                 validate={{
//                   required: {
//                     value: true,
//                     errorMessage: "Password is required",
//                   }
//                 }}
//               />
//               {/* <input type="password" className="form-control py-3 rounded-0" placeholder="Enter password" id="pwd" /> */}
//             </div>
//             <div className="form-group form-check">
//               {/* <label className="form-check-label">
//                 <input className="form-check-input" type="checkbox" /> Remember me
//         </label> */}
//               {/* <span className="float-right"><a href="#">Forget Password?</a></span> */}
//             </div>
//             <button type="submit" className="btn btn-primary-fb bg-primary btn-block rounded-0 py-2 mb-4">LOG IN</button>
//             {/* <p className="text-center">Use single sign-on authentication</p> */}
//             {/* <div className="row">
//               <div className=" col-lg-6"> 
//               <GoogleLogin
//                   className="btn gbtn btn-block"
//                   clientId={GOOGLE_CLIENT_ID}
//                   icon={false}
//                   onSuccess={responseGoogle}
//                   onFailure={responseGoogle}
//                 >
//                   <i className="fa fa-google new text-danger  " aria-hidden="true" />  Google
//                     </GoogleLogin>
//               </div>
//               <div className=" col-lg-6">
//                  <FacebookLogin
//                   type="button"
//                   textButton=" Facebook"
//                   fields="name,email,picture"
//                   cssClass="btn btn-primary-fb btn-block"
//                   appId={FB_APP_ID}
//                   autoLoad={false}
//                   icon="fa-facebook text-primary new"
//                   callback={responseFacebook}
//                 >  </FacebookLogin>
//               </div>
//             </div>
//             <hr /> */}
//             {/* <p className="text-center "><a href="#">Create A New Account ?</a></p> */}
//           </AvForm>
//         </div>
//         </div>
//         </div>
//      </div>
//       </div>

//     </Fragment>
//   );
// }

// export default withCookies(Login);

import React, { useState } from 'react';
import { withCookies } from 'react-cookie';
import API from '../utils/apiCalling';
import { config } from '../utils/apiUrl';
import { toast } from 'react-toastify';
import { Form, Input, Button, Checkbox } from 'antd';




const Login = ({ cookies }) => {

  const api = new API()


  const [loginForm, setLoginForm] = useState({
    username: '',
    password: '',
  })
  const [passwordShown, setPasswordShown] = useState(false);

  const passwordVisiblity = () => {
    setPasswordShown(passwordShown ? false : true);
  };



  const loginSubmit = async (values) => {
    let result = await api.post(config.login, values)
    if (result.code === 200) {
      await cookies.set('token', result.data.token)
      toast.success(result.message)
    } else {
      toast.error(result.message === "username must be a valid email" ? "Email ID not valid" : result.message)
    }
  }
  return (
    <div className="login">
      <div className="overlay"></div>
      <div className="container-fluid reward d-flex align-items-center justify-content-center">
        <div className="col-lg-9 mx-auto shadw bg-white">
        <div className="card">
          <div className="row">
            
            <div className="col-lg-6 bg-card-login align-items-center d-flex px-0">
              <img className="nps-demo-img col-lg-12 " src="img/banner-1.png" />
            </div>
            <div className="position-relative col-lg-6 d-flex align-items-center justify-content-center">
              <div>
                <img src="img/logocrm.png" className="position-absolute" style={{ right: '25px', top: '25px' }} alt="SurveyCxm" /></div>
              <div className="col-lg-11 mx-auto mt-5">
                <div className="card-body p-6 mt-5">
                  <h4 className="clr-txt">Welcome Back !</h4>
                  <p className="clr-txt font-light">Please Login To Your Account ...</p>

                  <Form onFinish={loginSubmit}
                  >
                    <Form.Item
                    name="username"
                    value={loginForm.username}
                      rules={[
                        {
                          required: true,
                          message: 'Enter Email ID',
                        },
                      ]}
                    >
                      <div className="input-group ">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-transparent form-field"><i className="fa fa-envelope" aria-hidden="true"/></span>
                    </div>
                      <Input type="text"  autoComplete="off"
                      className="form-control py-3  rounded-0" 
                       placeholder="Email Address... " 
                      /> 
                    </div> 
                    </Form.Item>

                    <Form.Item
                      name="password"
                      value={loginForm.password} 
                      rules={[
                        {
                          required: true,
                          message: 'Enter password!',
                        },
                      ]}
                    >
                     <div className="input-group position-relative">
                    <div className="input-group-prepend">
                      <span className="input-group-text bg-transparent form-field"><i className="fa fa-key" aria-hidden="true"/></span>
                    </div>
                    <Input  autoComplete="off"  type={passwordShown ? "text" : "password"}
                    className="form-control py-3 form-field rounded-0"  
                      placeholder="Password..." 
                    />
                  
                    <span className="input-group-btn eye-style">
                    <button className="  btn bg-transparent reveal" type="button"><i onMouseEnter={passwordVisiblity} onMouseLeave={passwordVisiblity} className={passwordShown ?"fa fa-eye":"fa fa-eye-slash"}></i></button>
                  </span>
                  </div>
                    </Form.Item>

                    
                    <div className="form-footer d-flex justify-content-between align-items-center mt-5">
                      <button className="btn bg-card-login btn-primary col-lg-7 mb-0 text-white mx-auto btn-gradient py-2"  htmlType="submit">LOGIN</button>
                    </div>
                  </Form>
                 
                  <p className="text-center mt-5 mb-5">For Any Assistance Or Help, Kindly Contact Us On <br />
                    support@qdegrees.com</p>
                </div>

              </div>
            </div>
           
          </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default withCookies(Login);