import React, { useState, useEffect } from 'react';
import QuestionsAndResponsesChart from '../../charts/QuestionsAndResponsesChart';
import API from '../../../utils/apiCalling';
import { config } from '../../../utils/apiUrl';
import { get } from 'lodash'
import QuestionsAndResponsesChartSub from '../../charts/QuestionsAndResponsesChartSub';

function RupayQuestionAndResponse(props){  
  const api = new API()
  const {questionAndResponse,dpp,questionAndResUpi,parameterArrRupay,questionAndResRupays,chartsRupayMore,noMoreRupay} = props;

  const [questionShow,setQuestionShow] = useState(false)
  const [question,setQuestion] = useState([])

  const questionShowHide =()=>{
    setQuestionShow(!questionShow)
  }
  const clicked = (e) => {

    const {qid,option_id,value} = e.point.options
    questionShowHide()
   const rating_type = option_id
    questionAndResRupays(2,rating_type,qid, 'ratingType')
  }

  const clickedUpi = (e) => {
    
    const product_id = 1
      questionShowHide()
      const rating_type = e.point.options.value
    // questionAndResRupays(product_id,rating_type)
      questionAndResRupays(2,rating_type,0,'ratingType')
    }



    return (
       
        <div className="card">
           <div className="card-header">
              <h5 className="rupayQuesAndRes1">Product Name 2 || Questions & Responses</h5>
           </div>
           <div className="card-body">
           <div className="row">
           <div className="col-md-6">
              <div className="card-box">
              <QuestionsAndResponsesChart questionAndResponseChart={dpp} onClick={(e)=>clickedUpi(e)} title={"Product Name 2 Promoter, Passive, Detractor Rating"}/>
              </div>
            </div>
            {/* <div className="col-md-6">
              <div className="card-box">
              <QuestionsAndResponsesChart questionAndResponseChart={parameterArrRupay} onClick={(e)=>clicked(e)} title={"Parameter wise responses"}/>
              </div>
            </div> */}
          
          {Array.isArray(questionAndResponse) && questionAndResponse.length > 0 && questionAndResponse.map((Item,index)=>(
            <div key={index} className="col-md-6">
               <div  className="card-box">
              <QuestionsAndResponsesChartSub questionAndResponseChart={get(Item,"options",{})} onClick={(e)=>clicked(e)} title={Item.question}/>
              </div> 
            </div>))}
            {noMoreRupay &&<div  className="col-md-6">
               <div  className="card-box">
              <div>{noMoreRupay}</div>
               </div>
               </div>}
          </div>
          </div>
              </div>
 
    )
}
export default RupayQuestionAndResponse